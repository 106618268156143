import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import { connect } from 'react-redux';
import { lang } from './lang';
import LanguageSwitcher from '../../../../../components/uielements/LanguageSwitcher';
import FormInput from '../../../../../components/FormControls/FormInput';
import gamesActions from '../../../../../redux/casino/games/actions';
import { Wrapper } from './TabTitleStyle';
import FormTextarea from '../../../../../components/FormControls/FormTextarea';
import { BONUS_DESCRIPTION_MAX_LENGTH, BONUS_TITLE_MAX_LENGTH } from '../../../../../constants/common';
import FormSingleUploader from '../../../../../components/FormControls/FormSingleUploader';

class TabTitle extends Component {

	static propTypes = {
		readOnly   : PropTypes.bool,
		langID     : PropTypes.number.isRequired,
		dataRefresh: PropTypes.func.isRequired,
		uiRefresh  : PropTypes.func.isRequired,
		bonusData  : PropTypes.object.isRequired,
	};

	static defaultProps = {
		readOnly: false,
	};

	constructor(props) {
		super(props);
		this.onChangeField = this.onChangeField.bind(this);
		this.onChangeLanguage = this.onChangeLanguage.bind(this);
		this.onUploadSuccess = this.onUploadSuccess.bind(this);
		this.onClear = this.onClear.bind(this);

		this.state = {
			description: '',
			title      : '',
		};
	}

	componentDidMount() {
		this.titleDescriptionMakeState();
	}

	componentDidUpdate(prevProps) {
		const { langID } = this.props;

		if (prevProps.langID !== langID) {
			this.titleDescriptionMakeState();
		}
	}


	titleDescriptionMakeState() {
		const { bonusData, langID } = this.props;
		const { titles = [] } = bonusData;
		if (titles.length) {
			const mainData = titles.find(item => item.langID === langID) || {};
			const title = mainData.title || '';
			const description = mainData.description || '';
			this.setState({ description, title });
		}
	}

	onChangeField(event) {
		const { uiRefresh, langID, bonusData, dataRefresh } = this.props;
		const { name, value } = event.target;
		const { titles } = bonusData;
		const clonedTitles = cloneDeep(titles);


		const data = clonedTitles.find(item => item.langID === langID);
		if (data) {
			data[name] = value;
			this.setState({ [name]: value });
		} else {
			clonedTitles.push({
				[name]: value,
				langID,
			});
			this.setState({ [name]: value });
		}


		uiRefresh({ isChanged: true });
		dataRefresh({ titles: clonedTitles });
	}

	onChangeLanguage(langID) {
		const { uiRefresh, bonusData, dataRefresh } = this.props;
		const { titles } = bonusData;
		const clonedTitles = cloneDeep(titles);
		const data = clonedTitles.find(item => item.langID === langID);
		if (!data) {
			clonedTitles.push({
				title      : '',
				description: '',
				langID,
			});
		}
		dataRefresh({ titles: clonedTitles });
		uiRefresh({ langID });
	}

	onUploadSuccess(fileLink) {
		const { bonusData, dataRefresh, uiRefresh } = this.props;

		const resBaseData = cloneDeep(bonusData);

		resBaseData.logoURL = fileLink;

		dataRefresh(resBaseData, 'logoURL');
		uiRefresh({ isChanged: true });
	}

	onClear() {
		const { bonusData, dataRefresh, uiRefresh } = this.props;
		const resBaseData = cloneDeep(bonusData);
		resBaseData.logoURL = '';

		dataRefresh(resBaseData, 'logoURL');
		uiRefresh({ isChanged: true });
	}

	// Renders --------------------------------------------------------------------------------------
	render() {
		const { readOnly, bonusData, langID, withUploader } = this.props;
		const { logoURL } = bonusData;
		const { title, description } = this.state;

		return (
			<Wrapper>
				<Row className="langSwitcher">
					<Col span={24}>
						<LanguageSwitcher
							disabled={readOnly}
							label={lang.contentLanguage}
							langID={langID}
							onChangeLanguage={this.onChangeLanguage}
						/>
					</Col>
				</Row>
				<Row>
					<Col span={16}>
						{title && (
							<span className="title-length">
								{title.length > BONUS_TITLE_MAX_LENGTH
									?
									<span className="title-length-red">{BONUS_TITLE_MAX_LENGTH - title.length}</span>
									: title.length}
                                /{BONUS_TITLE_MAX_LENGTH}
							</span>
						)}
						<FormInput
							label={lang.title}
							value={title}
							disabled={readOnly}
							name="title"
							onChange={this.onChangeField}
						/>
					</Col>
				</Row>
				<Row>
					<Col span={16}>
						{description && (
							<span className="description-length">
								{description.length > BONUS_DESCRIPTION_MAX_LENGTH
									? <span
										className="title-length-red">{BONUS_DESCRIPTION_MAX_LENGTH - description.length}</span>
									: description.length}
                                /{BONUS_DESCRIPTION_MAX_LENGTH}
							</span>
						)}
						<FormTextarea
							id="#descriptionRef"
							label={lang.description}
							autoSize={{ minRows: 3, maxRows: 6 }}
							value={description}
							disabled={readOnly}
							name="description"
							onChange={this.onChangeField}
							styles={{ borderRadius: '6px' }}
						/>
					</Col>
				</Row>
				{withUploader && (
					<Row>
						<Col span={16}>
							<FormSingleUploader
								label={lang.logoURL}
								height="100%"
								minImageWidth="132"
								minImageHeight="132"
								id="casinoBonus-imageLgURL"
								defaultFileLink={logoURL}
								disabled={readOnly}
								onUploadSuccess={this.onUploadSuccess}
								onClear={this.onClear}
							/>
						</Col>
					</Row>
				)}
				{withUploader && logoURL && (
					<Row>
						<Col span={24}>
							<img src={logoURL} style={{ width: '100%', height: 'auto' }} alt="logo"/>
						</Col>
					</Row>
				)}
			</Wrapper>
		);
	}
}

function mapStateToProps(state, props) {
	const { bonusData, dataRefresh, uiRefresh } = props;
	return {
		dataRefresh,
		uiRefresh,
		bonusData,
	};
}

const mapDispatchToProps = {
	gamesSearchListRefresh: gamesActions.gamesSearchListRefresh,
	setSearchvalue        : gamesActions.setSearchvalue,
};

export default connect(mapStateToProps, mapDispatchToProps)(TabTitle);
