import React from 'react';
import difference from 'lodash/difference';
import { isNull, isUndefined } from 'lodash';
import { WebsiteCount } from './Components/WebsiteCount';
import { PartnerTitle } from './TreeWrapper.style';
import { PlatformAdminRoles } from '@ceo-betmakers/common-globals';


export const diffTreeValues = (oldData, newData, checked) => {

	const [checkedNode] = checked ? difference(newData, oldData) : difference(oldData, newData);
	const [nodeType, id, ,  parentID] = checkedNode.split('_');
	return {
		nodeType,
		id      : Number(id),
		nodeID  : checkedNode,
		parentID: Number(parentID) || null,
	};

};

const renderTreeRow = (props) => {
	const { atLeastOneWebsite, everyWebsite, partnerSelected, item, count } = props;
	return (
		<PartnerTitle
			atLeastOneWebsite={atLeastOneWebsite}
			everyWebsite={everyWebsite}
			partnerSelected={partnerSelected}
		>
			<span> {item.partnerName} </span> <WebsiteCount count={count} item={item} />
		</PartnerTitle>
	);
};

export const normalizePartnerWebsiteList = (isPartner, data = [], selectedIDs = [], partnerEntities = {}, baseData = {}) => {

	const { websiteIDs, count } = baseData;
	const partnerPrefix = 'partner';
	const websitePrefix = 'website';
	const parentPrefix  = 'parent';

	return data.map(item => {
		const partnerEntity = partnerEntities[item.id];
		const hasWebsites = partnerEntity && !!partnerEntity.websites.length;
		const atLeastOneWebsite = partnerEntity && partnerEntity.websites.some(item => websiteIDs.includes(item.id));
		const everyWebsite = hasWebsites && partnerEntity.websites.every(item => websiteIDs.includes(item.id));
		const partnerSelected = selectedIDs.includes(item.id);

		const treeRow = renderTreeRow({ item,  count, atLeastOneWebsite, everyWebsite, partnerSelected });
		const partner = {
			title     : treeRow,
			key       : `${partnerPrefix}_${item.id}`,
			value     : `${partnerPrefix}_${item.id}`,
			atLeastOneWebsite,
			everyWebsite,
			selectable: false,
			children  : [],
		};

		if (item.websites.length) {
			item.websites.forEach(website => {
				if (isPartner) {
					partner.children.push({
						title     : website.name,
						key       : `${websitePrefix}_${website.id}_${parentPrefix}_${item.id}`,
						value     : `${websitePrefix}_${website.id}_${parentPrefix}_${item.id}`,
						checkable : !selectedIDs.includes(item.id),
						selectable: false,
					});
				} else if (!isPartner) {
					partner.children.push({
						title     : website.name,
						key       : `${websitePrefix}_${website.id}_${parentPrefix}_${item.id}`,
						value     : `${websitePrefix}_${website.id}_${parentPrefix}_${item.id}`,
						checkable : !selectedIDs.includes(item.id),
						selectable: false,
					});
				}
			} );
		}


		return partner;
	});
};

export const partnerWebsiteCount = (checkedNodes = []) => {
	return  checkedNodes.reduce((acc, item) => {
		if (!item.includes('parent')) {
			const [, partnerID] = item.split('_');
			acc[partnerID] = null;
			return acc;
		}
		// eslint-disable-next-line no-unused-vars
		const [,,, parentID] = item.split('_');
		if (isUndefined(acc[parentID]) || isNull(acc[parentID])) {
			acc[parentID] = 1;
		} else {
			acc[parentID]++;
		}

		return  acc;
	}, {});
};


export const genTabTitle = (adminType, lang) => {
	switch (Number(adminType)) {
		case PlatformAdminRoles.PARTNER_OPERATOR:
		case PlatformAdminRoles.PARTNER_ADMIN: {
			return lang.websites;
		}
		default:
			return lang.partnerWebsite;
	}
};
