import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import toInteger from 'lodash/toInteger';
import Formatter from '../../../../helpers/formatter';
import { DiffMapper } from '../../../../helpers/diff';
import { HistoryEnums } from '../enums';

const fields = {
	id                : 'id',
	objectBefore      : 'object_before',
	objectAfter       : 'object_after',
	userID            : 'source_id',
	sourceTypeID      : 'source_type',
	actionTypeID      : 'action_type',
	date              : 'date',
	categoryID        : 'category_id',
	channelID         : 'channel_id',
	websiteID         : 'website_id',
	langID            : 'lang_id',
	leagueID          : 'league_id',
	// array fields
	notes             : 'notes',
	restrictedBetTypes: 'restricted_bet_types',
	liveMarketsID     : 'live_markets_id',
	prematchMarketsID : 'prematch_markets_id',
	restrictedLeagues : 'restricted_leagues',
};

// Adapting ---------------------------------------------------------------------------------------

export function adaptData(rawData = []) {
	if (!isArray(rawData) || isEmpty(rawData)) {
		return [];
	}

	const arrayFields = [fields.notes, fields.restrictedBetTypes];

	const diffOptions = {
		onlyChanged: true,
		excludeKeys: [fields.id].concat(arrayFields),
	};

	const dataList = adaptHistoryList(rawData, diffOptions, arrayFields);

	return dataList;
}

// Common -----------------------------------------------------------------------------------------

function adaptHistoryList(rawData, diffOptions, arrayFields = []) {
	const historyEnums = new HistoryEnums();

	const dataList = [];

	const mapper = new DiffMapper();
	mapper.init(diffOptions);

	rawData.forEach((historyItem, historyItemIndex) => {
		let itemDiffList = [];

		const objectBefore = historyItem[fields.objectBefore] || {};
		const objectAfter = historyItem[fields.objectAfter] || {};

		// main data
		const diffMap = mapper.map(objectBefore, objectAfter);
		const diffList = mapper.toList(diffMap);

		itemDiffList = itemDiffList.concat(diffList);

		// arrays
		arrayFields.forEach(fieldName => {
			const diffList = mapper.mapList(
				objectBefore,
				objectAfter,
				fieldName,
				true
			);
			itemDiffList = itemDiffList.concat(diffList);
		});

		if (isEmpty(itemDiffList)) {
			return;
		}

		// adding id for detail records
		itemDiffList.forEach((item, index) => {
			item.id = index;
		});

		// prepare result item
		const userID = toInteger(historyItem[fields.userID]);
		const sourceTypeID = toInteger(historyItem[fields.sourceTypeID]);
		const actionTypeID = toInteger(historyItem[fields.actionTypeID]);

		const dataItem = {
			id         : historyItemIndex,
			userID,
			date       : Formatter.fullDateTime(historyItem[fields.date]),
			sourceType : historyEnums.sourceType(sourceTypeID),
			actionType : historyEnums.actionType(actionTypeID),
			sourceTypeID,
			detailsList: itemDiffList,
		};

		dataList.push(dataItem);
	});

	return dataList;
}
