import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import toInteger from 'lodash/toInteger';
import { MARKET_LOG_MODELS } from '../../../../helpers/commonConstants';
import Formatter from '../../../../helpers/formatter';
import { DiffMapper } from '../../../../helpers/diff';
import { HistoryEnums } from '../../results/enums';

const fields = {
	id               : 'id',
	objectBefore     : 'object_before',
	objectAfter      : 'object_after',
	userID           : 'source_id',
	sourceTypeID     : 'source_type',
	sourceIp         : 'source_ip',
	actionTypeID     : 'action_type',
	date             : 'date',
	marketID         : 'market_id',
	channelID        : 'channel_id',
	websiteID        : 'website_id',
	langID           : 'lang_id',
	// array fields
	alert            : 'alert',
	displayType      : 'display_type',
	groups           : 'groups',
	notes            : 'notes',
	restrictedMarkets: 'restricted_markets',
	restrictionGroups: 'restriction_groups',
};

// Adapting ---------------------------------------------------------------------------------------

export function adaptData(rawData = [], logModel = MARKET_LOG_MODELS.market) {
	if (!isArray(rawData) || isEmpty(rawData)) {
		return [];
	}

	switch (logModel) {
		case MARKET_LOG_MODELS.market:
			return adaptMarket(rawData);

		case MARKET_LOG_MODELS.names:
			return adaptNames(rawData);

		case MARKET_LOG_MODELS.cms:
			return adaptCMS(rawData);

		case MARKET_LOG_MODELS.orders:
			return adaptOrders(rawData);

		case MARKET_LOG_MODELS.settings:
			return adaptSettings(rawData);

		case MARKET_LOG_MODELS.dynamicLimits:
			return adaptDynamicLimits(rawData);

		default:
			return [];
	}
}

export function adaptAllData(allData) {
	let result = [];

	result = result.concat(addSectionName(allData.market, 'Main'));
	result = result.concat(addSectionName(allData.settings, 'Settings'));
	result = result.concat(addSectionName(allData.cms, 'Descriptions'));
	result = result.concat(
		addSectionName(allData.dynamicLimits, 'Dynamic Limits')
	);
	result = result.concat(addSectionName(allData.names, 'Names'));
	result = result.concat(addSectionName(allData.orders, 'Orders'));

	result.sort((a, b) => {
		return a.date > b.date ? -1 : 1;
	});

	result.forEach((item, index) => {
		item.id = index;
	});

	return result;
}

// Adapters ---------------------------------------------------------------------------------------

function adaptMarket(rawData) {
	const arrayFields = [
		fields.alert,
		fields.displayType,
		fields.groups,
		fields.notes,
		fields.restrictedMarkets,
		fields.restrictionGroups,
	];

	const diffOptions = {
		onlyChanged: true,
		excludeKeys: [fields.id].concat(arrayFields),
	};

	const dataList = adaptHistoryList(rawData, diffOptions, arrayFields);

	return dataList;
}

function adaptSettings(rawData) {
	const diffOptions = {
		onlyChanged: true,
		excludeKeys: [fields.id, fields.marketID],
	};

	const dataList = adaptHistoryList(rawData, diffOptions);

	return dataList;
}

function adaptCMS(rawData) {
	const diffOptions = {
		onlyChanged: true,
		excludeKeys: [
			fields.id,
			fields.marketID,
			fields.channelID,
			fields.websiteID,
			fields.langID,
		],
	};

	const dataList = adaptHistoryList(rawData, diffOptions);

	return dataList;
}

function adaptDynamicLimits(rawData) {
	const diffOptions = {
		onlyChanged: true,
		excludeKeys: [fields.id, fields.marketID],
	};

	const dataList = adaptHistoryListOfArrays(rawData, diffOptions);

	return dataList;
}

function adaptNames(rawData) {
	const diffOptions = {
		onlyChanged: true,
		excludeKeys: [
			fields.id,
			fields.marketID,
			fields.channelID,
			fields.websiteID,
			fields.langID,
		],
	};

	const dataList = adaptHistoryList(rawData, diffOptions);

	return dataList;
}

function adaptOrders(rawData) {
	const diffOptions = {
		onlyChanged: true,
		excludeKeys: [
			fields.id,
			fields.marketID,
			fields.channelID,
			fields.websiteID,
			fields.langID,
		],
	};

	const dataList = adaptHistoryList(rawData, diffOptions);

	return dataList;
}

// Common -----------------------------------------------------------------------------------------

function adaptHistoryList(rawData, diffOptions, arrayFields = []) {
	const historyEnums = new HistoryEnums();

	const dataList = [];

	const mapper = new DiffMapper();
	mapper.init(diffOptions);

	rawData.forEach((historyItem, historyItemIndex) => {
		let itemDiffList = [];

		const objectBefore = historyItem[fields.objectBefore] || {};
		const objectAfter = historyItem[fields.objectAfter] || {};

		// main data
		const diffMap = mapper.map(objectBefore, objectAfter);
		const diffList = mapper.toList(diffMap);

		itemDiffList = itemDiffList.concat(diffList);

		// arrays
		arrayFields.forEach(fieldName => {
			const diffList = mapper.mapList(
				objectBefore,
				objectAfter,
				fieldName,
				true
			);
			itemDiffList = itemDiffList.concat(diffList);
		});

		if (isEmpty(itemDiffList)) {
			return;
		}

		// adding id for detail records
		itemDiffList.forEach((item, index) => {
			item.id = index;
		});

		// prepare result item
		const userID = toInteger(historyItem[fields.userID]);
		const sourceTypeID = toInteger(historyItem[fields.sourceTypeID]);
		const actionTypeID = toInteger(historyItem[fields.actionTypeID]);
		const sourceIp     = toInteger(historyItem[fields.sourceIp]);

		const dataItem = {
			id         : historyItemIndex,
			sourceIp,
			userID,
			date       : Formatter.fullDateTime(historyItem[fields.date]),
			sourceType : historyEnums.sourceType(sourceTypeID),
			actionType : historyEnums.actionType(actionTypeID),
			sourceTypeID,
			detailsList: itemDiffList,
		};

		dataList.push(dataItem);
	});

	return dataList;
}

function adaptHistoryListOfArrays(rawData, diffOptions) {
	const historyEnums = new HistoryEnums();
	const dataList = [];

	const mapper = new DiffMapper();
	mapper.init(diffOptions);

	rawData.forEach((historyItem, historyItemIndex) => {
		let itemDiffList = [];

		const objectBefore = historyItem[fields.objectBefore] || {};
		const objectAfter = historyItem[fields.objectAfter] || {};

		const tempObjectBefore = {
			data: objectBefore,
		};
		const tempObjectAfter = {
			data: objectAfter,
		};

		const diffList = mapper.mapList(
			tempObjectBefore,
			tempObjectAfter,
			'data',
			true
		);
		itemDiffList = itemDiffList.concat(diffList);

		if (isEmpty(itemDiffList)) {
			return;
		}

		// adding id for detail records
		itemDiffList.forEach((item, index) => {
			item.id = index;
		});

		// prepare result item
		const userID = toInteger(historyItem[fields.userID]);
		const sourceTypeID = toInteger(historyItem[fields.sourceTypeID]);
		const actionTypeID = toInteger(historyItem[fields.actionTypeID]);
		const sourceIp     = toInteger(historyItem[fields.sourceIp]);


		const dataItem = {
			id         : historyItemIndex,
			userID,
			sourceIp,
			date       : Formatter.fullDateTime(historyItem[fields.date]),
			sourceType : historyEnums.sourceType(sourceTypeID),
			actionType : historyEnums.actionType(actionTypeID),
			sourceTypeID,
			detailsList: itemDiffList,
		};

		dataList.push(dataItem);
	});

	return dataList;
}

function addSectionName(rawList, sectionName) {
	if (!isArray(rawList) || isEmpty(rawList)) {
		return [];
	}

	return rawList.map(item => {
		return {
			...item,
			section: sectionName,
		};
	});
}
