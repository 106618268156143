import { Component, Fragment } from 'react';
import { Divider } from 'antd';

import { connect } from 'react-redux';
import keys from 'lodash/keys';
import find from 'lodash/find';
import toNumber from 'lodash/toNumber';
import cloneDeep from 'lodash/cloneDeep';
import toInteger from 'lodash/toInteger';
import isArray from 'lodash/isArray';

import FormSelect from '../../../../../../components/FormControls/FormSelect';
import FormInputNumber from '../../../../../../components/FormControls/FormInputNumber';
import FormStatic from '../../../../../../components/FormControls/FormStatic';
import FormMultiSelectTags from '../../../../../../components/FormControls/FormMultiSelectTags';
import bonusActions from '../../../../../../redux/loyalty/bonuses/userBonus/actions';
import casinoGamesActions from '../../../../../../redux/casino/games/actions';
import bonusesActions from '../../../../../../redux/loyalty/bonuses/bonuses/actions';

import {
	deriveUserBonusData,
	deriveUserBonusUI,
	deriveUserBonusBets,
	deriveUserBonusDenominations,
} from '../../../../../../selectors/bonuses';
import { deriveCasinoGamesListFilter, deriveGamesListAlphabetical } from '../../../../../../selectors/casinoGames';
import FormSingleUploader from '../../../../../../components/FormControls/FormSingleUploader';
import Formatter from '../../../../../../helpers/formatter';
import { allowOnlyNumbers } from '../../../../../../helpers/utils';
import { BONUS_STATUS_NAMES } from '../../../../../../constants/bonuses';
import { casinoExtraSpinTypesList } from '../../../../../../constants/bonuses.enum';
import FormCheckbox from '../../../../../../components/FormControls/FormCheckbox';
import { deriveUserFilter } from '../../../../../../selectors/users';
import BetRadios from '../../components/BetRadios';
import DenominationRadios from '../../components/DenominationRadios';
import MaxPayout from '../../../../../../components/MaxPayout';
import BetsAndDenominationUser from '../../components/BetsAndDenominationUser/BetsAndDenominationUser';
import { lang } from '../../lang';
import GameListSelect from './GameList';
import IntlMessages from '../../../../../../components/utility/intlMessages';
import FormInput from '../../../../../../components/FormControls/FormInput';
import appTabsActions from '../../../../../../redux/appTabs/actions';
import { ButtonLink } from './TabMain.style';
import { LANGUAGES } from '../../../../../../helpers/commonConstants';
import FormTextarea from '../../../../../../components/FormControls/FormTextarea';
import { IconLabel } from '../../../../../LoyaltySystem/BonusingSystem/Bonuses/modals/Bonus/utils';
import { daysToExpireBonusInfo, daysToExpireWalletInfo } from '../../../../../../redux/loyalty/bonuses/bonuses/utils';
import { CasinoExtraSpinType } from '@ceo-betmakers/common-globals';


class FreeSpin extends Component {

	static defaultProps = {
		betID            : null,
		denominationKey  : null,
		denominationValue: null,
	};

	constructor(props) {
		super(props);

		this.onChangeField = this.onChangeField.bind(this);
		this.onChangeSpinType = this.onChangeSpinType.bind(this);
		this.onChangeGame = this.onChangeGame.bind(this);
		this.onChangeBet = this.onChangeBet.bind(this);

		this.createGamesListBySpinType = this.createGamesListBySpinType.bind(this);
		this.createSpinsList = this.createSpinsList.bind(this);
		this.calculateBetAmount = this.calculateBetAmount.bind(this);

		this.renderBetsRadios = this.renderBetsRadios.bind(this);
		this.renderDenominations = this.renderDenominations.bind(this);
		this.renderLineAndDenomination = this.renderLineAndDenomination.bind(this);
		this.renderTemplateName = this.renderTemplateName.bind(this);
		this.onUploadSuccess = this.onUploadSuccess.bind(this);
		this.onClear = this.onClear.bind(this);
		this.onChangeMultiField = this.onChangeMultiField.bind(this);
		this.onChangeFilterValue = this.onChangeFilterValue.bind(this);
	}

	componentDidMount () {
		const { bonusData, massBonusViewMode, gameLimitsReload, isMassBonus, currencyCode, gameEntityReload, websiteID, langID } = this.props;

		if (isMassBonus && bonusData.casinoGameID) {
			if (massBonusViewMode) {
				gameLimitsReload(bonusData.casinoGameID, bonusData.userID, currencyCode, isMassBonus);
			} else {
				gameEntityReload(bonusData.casinoGameID, null, websiteID, langID, true);
				this.createGamesListBySpinType(+bonusData.typeID);
			}
		}
	}
	componentWillUnmount() {
		const { gameEntitySet,filterReset } = this.props;
		gameEntitySet({});
		filterReset();
	}

	// Service --------------------------------------------------------------------------------------
	isChanged(bonusData) {
		const { betID, spinsTotal, denominations, casinoGameID } = bonusData;
		return (betID && spinsTotal && denominations && casinoGameID) || false;
	}

	createGamesListBySpinType(typeID) {
		const { gamesListReload, gamesListRefreshPagination, gameListReset } = this.props;

		if (typeID === CasinoExtraSpinType.FREE_SPIN) {
			this.onChangeFilterValue({ isFreeSpin: true, isBonusSpin: false, name: '' });
		}

		if (typeID === CasinoExtraSpinType.BONUS_SPIN) {
			this.onChangeFilterValue({ isFreeSpin: false, isBonusSpin: true, name: '' });
		}

		gamesListRefreshPagination({ currentPage: 1, totalCount: 0 });
		gameListReset();
		gamesListReload({ isUnlimit: false, byCurrency: 'byCurrency' });
	}

	createSpinsList() {
		const { bonusData, gamesList } = this.props;
		const { casinoGameID } = bonusData;

		const game = find(gamesList, { id: casinoGameID });
		if (!game) {
			return [];
		}

		const freeSpinsCount = game.freeSpinsCount || [];
		return freeSpinsCount.map(count => {
			return { id: count, name: String(count) };
		});
	}

	calculateBetAmount() {
		const { bets, betID, denominationKey, denominationValue } = this.props;
		const bet = find(bets, { betID });
		if (!bet) {
			return 0;
		}

		return toNumber(denominationKey) * toNumber(denominationValue) * bet.betPerLine * bet.lines;
	}

	calculateBetAmountWithLineAndDenomination() {
		const { bonusData: { betPerLine, lines } } = this.props;

		if (!betPerLine || !lines) {
			return 0;
		}

		return toNumber(betPerLine) * toNumber(lines);
	}

	betAmount() {
		const { showLineAndDenominationInputs } = this.props;

		let betAmount = 0;
		if (showLineAndDenominationInputs) {
			betAmount = this.calculateBetAmountWithLineAndDenomination();
		} else {
			betAmount = this.calculateBetAmount();
		}

		return betAmount;
	}

	// Events ---------------------------------------------------------------------------------------
	// onChangeField(value, dataName) {
	//   const { bonusData, dataRefresh, uiRefresh } = this.props;
	//   if (!dataName) {
	//     return;
	//   }
	//   const resData = cloneDeep(bonusData);
	//   resData[dataName] = value;


	// Events ---------------------------------------------------------------------------------------

	onChangeField(e, name) {
		const { bonusData, dataRefresh, uiRefresh } = this.props;
		const rawValue = name ? e : e.target.value || e.target.checked;
		const dataName = name || e.target.name;
		if (!dataName) {
			return;
		}
		const value = dataName === 'startDate' || dataName === 'endDate' ? Formatter.fullDateTime(rawValue) : rawValue;

		const resData = cloneDeep(bonusData);
		resData[dataName] = value;
		if (dataName === 'forced') {
			resData.daysToExpireBonus = null;
		}

		dataRefresh(resData);
		uiRefresh({ isChanged: true });
	}

	onUploadSuccess(fileLink) {
		const { bonusData, dataRefresh, uiRefresh } = this.props;

		const resBaseData = cloneDeep(bonusData);

		resBaseData.logoURL = fileLink;

		dataRefresh(resBaseData);
		uiRefresh({ isChanged: true });
	}

	onClear() {
		const { bonusData, dataRefresh, uiRefresh } = this.props;

		const resBaseData = cloneDeep(bonusData);

		resBaseData.logoURL = '';

		dataRefresh(resBaseData);
		uiRefresh({ isChanged: true });
	}

	onChangeSpinType(typeID) {
		const { bonusData, dataRefresh, uiRefresh, gameListReset, gamesListRefreshPagination } = this.props;
		gameListReset();
		gamesListRefreshPagination({ currentPage: 1 });
		const resData = cloneDeep(bonusData);

		resData.typeID = toInteger(typeID);
		resData.casinoGameID = null;
		resData.spinsTotal = null;

		dataRefresh(resData);
		uiRefresh({ pageReset: true, isChanged: this.isChanged(resData) });
		this.createGamesListBySpinType(+typeID);
	}

	onChangeGame(casinoGameID) {
		const { bonusData, dataRefresh, gameLimitsReload, uiRefresh, isMassBonus, currencyCode } = this.props;
		const resData = cloneDeep(bonusData);
		const { userID } = resData;

		resData.casinoGameID = toInteger(casinoGameID);
		resData.spinsTotal = null;
		resData.lines = null;
		resData.betPerLine = null;

		dataRefresh(resData);
		if (casinoGameID !== null) {

			if (isMassBonus) {
				gameLimitsReload(casinoGameID, userID, currencyCode);
			} else {
				gameLimitsReload(casinoGameID, userID);
			}
		}
		uiRefresh({ isChanged: this.isChanged(resData), isCheckedMinLineAndBet: false });
	}

	onChangeBet(betID) {

		const { uiRefresh, bonusData } = this.props;
		uiRefresh({
			betID,
			isChanged: !this.isChanged(bonusData),
		});
	}

	onChangeMultiField(value, dataName, selected) {
		const { bonusData, dataRefresh } = this.props;
		if (!dataName || !value.replace(/\s/g, '').length) {
			return;
		}
		const newBonusData = cloneDeep(bonusData);
		let valuesList = newBonusData[dataName];
		if (!isArray(valuesList)) {
			valuesList = [];
		}
		const intValue = value;
		if (selected) {
			valuesList.push(intValue);
		} else {
			valuesList = valuesList.filter(item => item !== intValue);
		}
		newBonusData[dataName] = valuesList;
		dataRefresh(newBonusData);
	}

	// Renders --------------------------------------------------------------------------------------
	renderBetsRadios() {
		const { bets, betID, readOnly, massBonusViewMode } = this.props;

		const radios = <BetRadios readOnly={readOnly || massBonusViewMode} bets={bets} betID={betID} onChange={this.onChangeBet}/>;

		return <FormStatic label={lang.bets} value={radios}/>;
	}

	renderDenominations() {
		const { denominations, readOnly, massBonusViewMode } = this.props;
		if (keys(denominations).length) {
			return keys(denominations).map(denominationKey => {
				const label = `Denomination: ${denominationKey}`;
				const control = <DenominationRadios readOnly={readOnly || massBonusViewMode} denominationKey={denominationKey}/>;

				return <FormStatic key={denominationKey} label={label} value={control}/>;
			});
		}

		return (
			<FormStatic
				label={lang.denomination}
				value={''}
			/>
		);
	}


	renderLineAndDenomination() {
		const { showLineAndDenominationInputs, bonusData: { casinoGameID },  readOnly, massBonusViewMode } = this.props;

		if (showLineAndDenominationInputs && casinoGameID) {
			return (
				<BetsAndDenominationUser
					disabled={readOnly || !casinoGameID || massBonusViewMode}
					onChangeField={this.onChangeField}
				/>
			);
		} else {
			const betsRadios = this.renderBetsRadios();
			const denominations = this.renderDenominations();
			return (
				<>
					{betsRadios}
					{denominations}
				</>
			);
		}
	}

	renderTemplateName () {
		const { bonusData: { templateID, templateName }, openTabBonusTemplateEdit } = this.props;

		if (templateID) {
			return (
				<ButtonLink onClick={() => openTabBonusTemplateEdit(templateID)} type='link'>
					{templateName}
				</ButtonLink>
			);
		} else {
			return (
				<div>-</div>
			);
		}
	}

	onChangeFilterValue(filterValue) {
		const { filterRefresh } = this.props;
		filterRefresh(filterValue);
	}

	render() {
		const { bonusData, loading, isMassBonus, filter, showLineAndDenominationInputs, denominationKey, denominationValue, readOnly, viewMode, massBonusViewMode, gameEntity, pageReset, uiRefresh } = this.props;

		const {
			typeID,
			name,
			casinoGameID,
			spinsTotal,
			daysToExpireBonus,
			daysToExpireWallet,
			wageringTurnover,
			forced,
			logoURL,
			affiliateReference,
			betPerLine,
			lines,
			givenDate,
			claimDate,
			expireDate,
			leftSpins,
			statusID,
			wonAmount,
			noteForAdmin,
		} = bonusData;

		const spinsList = this.createSpinsList();
		const showSpinsSelect = typeID === CasinoExtraSpinType.BONUS_SPIN;
		const showSpinsInput = typeID === CasinoExtraSpinType.FREE_SPIN;
		const lineAndDenominations = this.renderLineAndDenomination();
		const betAmount = this.betAmount();
		const showAmount = showLineAndDenominationInputs ? betPerLine && lines : denominationKey && denominationValue;

		return (
			<Fragment>
				{viewMode && <FormStatic
					label={lang.usedTemplate}
					value={this.renderTemplateName()}
				/>}
				{isMassBonus && (
					<FormInput
						label={lang.name}
						disabled={readOnly || massBonusViewMode || loading}
						value={name}
						onChange={({ target }) => this.onChangeField(target.value, 'name')}
					/>
				)}
				<FormSelect
					label={lang.spinType}
					disabled={readOnly || massBonusViewMode || loading}
					value={typeID}
					optionsList={casinoExtraSpinTypesList}
					onChange={this.onChangeSpinType}
				/>
				<GameListSelect
					filter={filter}
					onSelect={this.onChangeGame}
					disabled={!typeID || massBonusViewMode || loading || readOnly}
					isMassBonus={readOnly || isMassBonus}
					value={casinoGameID}
					gameEntity={gameEntity}
					label={lang.casinoGame}
					pageReset={pageReset}
					uiRefresh={uiRefresh}
				/>

				{viewMode &&
					<>
						<FormStatic label={lang.givenDate} value={Formatter.fullDateTime(givenDate)} />
						<FormStatic label={lang.claimDate} value={Formatter.fullDateTime(claimDate)} />
						<FormStatic label={lang.expireDate} value={Formatter.fullDateTime(expireDate)} />
						<FormStatic label={<IconLabel name={lang.daysToExpireWallet} text={daysToExpireWalletInfo} />} value={daysToExpireWallet} />
						<FormStatic label={<IconLabel name={lang.daysToExpireBonus} text={daysToExpireBonusInfo} />} value={daysToExpireBonus} />
						<FormStatic label={lang.wonAmount} value={wonAmount} />
						<FormStatic label={lang.leftSpins} value={leftSpins} />
						<FormStatic label={lang.statusID} value={statusID ? (
							<IntlMessages id={BONUS_STATUS_NAMES[statusID]} />
						) : ''} />
					</>
				}
				
				{showSpinsSelect && (
					<FormSelect
						label={lang.spinsTotal}
						disabled={massBonusViewMode || loading || readOnly}
						value={spinsTotal}
						optionsList={spinsList}
						onChange={value => this.onChangeField(value, 'spinsTotal')}
					/>
				)}
				{showSpinsInput && (
					<FormInputNumber
						label={lang.spinsTotal}
						disabled={massBonusViewMode || loading || readOnly}
						value={spinsTotal}
						min={0}
						parser={allowOnlyNumbers}
						onChange={value => this.onChangeField(value, 'spinsTotal')}
					/>
				)}
				{!viewMode && (
					<>
						<FormInputNumber
							label={<IconLabel name={lang.daysToExpireWallet} text={daysToExpireWalletInfo} />}
							disabled={massBonusViewMode || loading || readOnly}
							value={daysToExpireWallet}
							min={0}
							parser={allowOnlyNumbers}
							onChange={value => this.onChangeField(value, 'daysToExpireWallet')}
						/>
						{!forced && <FormInputNumber
							label={<IconLabel name={lang.daysToExpireBonus} text={daysToExpireBonusInfo} />}
							disabled={massBonusViewMode || loading || readOnly}
							value={daysToExpireBonus}
							min={0}
							parser={allowOnlyNumbers}
							onChange={value => this.onChangeField(value, 'daysToExpireBonus')}
						/>}
						
					</>
				)}

				<FormInputNumber
					label={lang.wageringTurnover}
					disabled={massBonusViewMode || loading || readOnly}
					value={wageringTurnover}
					min={0}
					parser={allowOnlyNumbers}
					onChange={value => this.onChangeField(value, 'wageringTurnover')}
				/>
				<MaxPayout
					disabled={massBonusViewMode || loading || readOnly}
					style={isMassBonus && { display: 'block' }}
					onChange={this.onChangeField}
					bonusData={bonusData}
				/>
				{!isMassBonus &&
					<FormTextarea
						label={lang.noteForAdmin}
						disabled={massBonusViewMode || loading || readOnly}
						value={noteForAdmin}
						onChange={({ target }) => this.onChangeField(target.value, 'noteForAdmin')}
					/>
				}
				{!viewMode && (
					<FormCheckbox
						label={lang.forced}
						checked={forced}
						disabled={massBonusViewMode || loading || readOnly}
						name="forced"
						onChange={this.onChangeField}
					/>
				)}
				{isMassBonus && (
					<FormMultiSelectTags
						showSearch
						label={lang.affiliateReference}
						value={affiliateReference}
						optionsList={affiliateReference}
						filterOption
						name="affiliateReference"
						mode="tags"
						disabled={massBonusViewMode || loading || readOnly}
						onSelect={value => this.onChangeMultiField(value, 'affiliateReference', true)}
						onDeselect={value => this.onChangeMultiField(value, 'affiliateReference', false)}
					/>
				)}
				<FormSingleUploader
					label={lang.logo}
					minImageWidth="132"
					minImageHeight="132"
					id="casinoBonus-imageLgURL"
					defaultFileLink={logoURL}
					disabled={massBonusViewMode || loading || readOnly}
					onUploadSuccess={this.onUploadSuccess}
					onClear={this.onClear}
				/>
				{logoURL && <img src={logoURL} style={{ width: '100%', height: 'auto' }} alt="logo"/>}

				<Divider/>
				{lineAndDenominations}
				{!isMassBonus && <Divider/>}

				{showAmount ? (
					<FormStatic
						bold
						label={lang.betAmount}
						value={Formatter.sum(betAmount)}
					/>) : null
				}
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	const UI = deriveUserBonusUI(state);
	const { massBonus } = UI;
	const currencyID = deriveUserFilter(state).currency;
	const { Settings } = state;
	let currencyCode;
	if (massBonus) {
		currencyCode = Settings.CurrencyModule.get('entities')?.[currencyID]?.code;
	} else {
		currencyCode = '';
	}
	const bonusData = deriveUserBonusData(state);

	const websiteID		= state.App.get('websiteID');
	const websiteData	= state.Partner.Websites.List.get('entities')[+websiteID];
	const langID		= websiteData ? websiteData.langID : LANGUAGES.en;
	return {
		loading                      : UI.loading,
		betID                        : UI.betID,
		denominationKey              : UI.denominationKey,
		denominationValue            : UI.denominationValue,
		showLineAndDenominationInputs: UI.showLineAndDenominationInputs,
		pageReset                    : UI.pageReset,
		isMassBonus                  : massBonus,
		massBonusViewMode            : state.Loyalty.Bonuses.MassBonus.MassBonusList.get('UI').editMode,
		bets                         : deriveUserBonusBets(state),
		denominations                : deriveUserBonusDenominations(state),
		gamesList                    : deriveGamesListAlphabetical(state),
		filter                       : deriveCasinoGamesListFilter(state),
		gameEntity                   : state.Loyalty.Bonuses.Bonuses.get('gameEntity'),
		bonusData,
		currencyCode,
		websiteID,
		langID,
	};
}

const mapDispatchToProps = {
	dataRefresh               : bonusActions.dataRefresh,
	bonusDataReset            : bonusActions.bonusDataReset,
	gameLimitsReload          : bonusActions.gameLimitsReload,
	uiRefresh                 : bonusActions.uiRefresh,
	gamesListReload           : casinoGamesActions.casinoGameListReload,
	filterRefresh             : casinoGamesActions.filterRefresh,
	filterReset               : casinoGamesActions.filterReset,
	gameListReset             : casinoGamesActions.gameListReset,
	gamesListRefreshPagination: casinoGamesActions.gamesListRefreshPagination,
	openTabBonusTemplateEdit  : appTabsActions.openTabBonusTemplateEdit,
	gameEntityReload          : bonusesActions.gameEntityReload,
	gameEntitySet             : bonusesActions.gameEntitySet,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(FreeSpin);
