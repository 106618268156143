import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import notifications from '../../../helpers/notifications';
import { usersAPI } from '../../../helpers/api/users';
import { logger } from '../../../helpers/logger';
import usersListActions from '../list/actions';
import { RootState } from '../../store';
import createUserActions from './actions';
import { userCreatePrepare } from './utils';
import { createUserReducers } from './slice';

const messages = {
	errorSaveUser  : 'userInfo.createUser.error.create',
	successSaveUser: 'userInfo.createUser.success.create',
};

function getStoreData(state: RootState) {
	const { Users, App } = state;
	const { CreateUser } = Users;

	return {
		baseData : CreateUser.baseData,
		websiteID: App.get('websiteID'),
	};
}

function* userCreate() {
	yield takeLatest(createUserActions.CREATE_USER, function* () {

		try {
			const { baseData, websiteID } = yield select(getStoreData);
			const params = {
				websiteID,
			};
			const { data, paramsData } = userCreatePrepare(baseData, params);
			const res = yield call(usersAPI.createUserSave, data, paramsData);

			if (res.status === 200) {
				yield put(createUserReducers.setCreateUserUI({ visibleModal: false }));
				yield put(createUserReducers.resetData());
				yield put(usersListActions.listReload());
				notifications.showSuccess(messages.successSaveUser);
			}
		} catch (error) {
			notifications.showError(messages.errorSaveUser, error);
			logger.log(error);
		}
	});
}

function* createUserSaga() {
	yield all([
		fork(userCreate),
	]);
}

export default createUserSaga;
