import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Checkbox, Collapse } from 'antd';
import { deriveGameCategoriesListGames } from '../../../../../../selectors/casinoGameCategories';
import categoryActions from '../../../../../../redux/casino/gameCategories/actions';
import CheckAll from '../Components/Checkbox';
import GamesList from '../Components/GamesList';
import { lang } from '../lang';
import { ExtraDiv, PanelAntd } from './TabCategories.style';
import { setAllCheckedCategories } from '../../../../../../helpers/utils';
import Spinner from '../../../../../../components/Spinner/Spinner';
import { cloneDeep } from 'lodash';

class TabProviders extends Component {

	static propTypes = {
		categoryList      : PropTypes.array.isRequired,
		categoryListReload: PropTypes.func.isRequired,
		uiRefresh         : PropTypes.func.isRequired,
		dataRefresh       : PropTypes.func.isRequired,
		checkedAllGames   : PropTypes.func.isRequired,
		setOldCheckedData : PropTypes.func.isRequired,
		bonusData         : PropTypes.object.isRequired,
		allChecked        : PropTypes.object.isRequired,
		gameIDs           : PropTypes.array.isRequired,
		loading           : PropTypes.array.isRequired,
	};

	constructor(props) {
		super(props);
		this.renderCategories  = this.renderCategories.bind(this);
		this.renderCheckAll  = this.renderCheckAll.bind(this);
	}

	componentDidMount() {
		const { bonusData, checkedAllGames, categoryList, gameIDs, setOldCheckedData, singleGameMode } = this.props;
		const { currencyID, typeID } = bonusData;
		const { categoryListReload } = this.props;
		const type = singleGameMode && typeID;
		categoryListReload(currencyID, type);
		if (bonusData.id) {
			setAllCheckedCategories({ bonusData, categoryList, gameIDs, checkedAllGames });
			setAllCheckedCategories({ bonusData, categoryList, gameIDs, checkedAllGames: setOldCheckedData });
		}
	}

	componentDidUpdate(prevProps) {
		const { bonusData, checkedAllGames, categoryList, gameIDs, setOldCheckedData } = this.props;
		if (prevProps.categoryList?.length !== categoryList.length  && bonusData.id) {
			setAllCheckedCategories({ bonusData, categoryList, gameIDs, checkedAllGames });
			setAllCheckedCategories({ bonusData, categoryList, gameIDs, checkedAllGames: setOldCheckedData });
		}
	}
	onSelectCurrent = (checked, games, categoryID) => {
		const { bonusData, singleGameMode, dataRefresh, checkedAllGames } = this.props;

		const clonedData = cloneDeep(bonusData);
		const categoryGameIDs = games.map(game => game.id);

		if (checked) {
			clonedData.gameIDs = clonedData.gameIDs.concat(categoryGameIDs);
			!singleGameMode && (clonedData.count.categories[categoryID] = games.length);
		} else {
			clonedData.gameIDs = clonedData.gameIDs.filter(gameID => !categoryGameIDs.includes(gameID));
			!singleGameMode && (clonedData.count.categories[categoryID] = 0);
		}

		clonedData.gameIDs = [...new Set(clonedData.gameIDs)];
		checkedAllGames({
			categoryID,
			games     : clonedData.gameIDs,
			checkedAll: null,
			into      : true,
		});
		dataRefresh(clonedData);
	};

	renderCheckAll (games, categoryID) {
		const { readOnly, bonusData } = this.props;
		const checked = games.length === bonusData.count?.categories?.[categoryID];
		return (
			<Checkbox
				onChange={e => this.onSelectCurrent(e.target.checked, games, categoryID)}
				checked={checked}
				disabled={readOnly}
			/>
		);
	}

	// Renders --------------------------------------------------------------------------------------

	renderCategories() {
		const { categoryList, uiRefresh, dataRefresh, bonusData, allChecked, loading, readOnly, singleGameMode } = this.props; // readOnly === viewMode
		if (loading) return <Spinner size='default' height='200px' />;

		return categoryList.map(category => {
			return (
				<PanelAntd
					header={category.name}
					key={category.id}
					extra={!singleGameMode && (
						<ExtraDiv>
							{/*Todo speak about showing Selected games 0 when there isn't games in category.*/}
							{!readOnly
								? ((allChecked[category.id] && !allChecked[category.id].allChecked) &&
								<span> {lang.gameCount} {allChecked[category.id].games.length}</span>
								)
								: (
									bonusData?.count?.categories?.[category.id] ? <span> {lang.gameCount} {bonusData.count.categories[category.id]}</span> : null
								)}

							{!readOnly ? (allChecked[category.id] && allChecked[category.id].allChecked) &&
								<span>{lang.selectedCategory}</span>
								: bonusData?.categoryIDs?.includes(category.id) ? <span>{lang.selectedCategory}</span> : null}

							<CheckAll
								disabled={readOnly}
								checkAllInto={readOnly} // TODO NOT true implementation
								data={category.games}
								uiRefresh={uiRefresh}
								dataRefresh={dataRefresh}
								bonusData={bonusData}
								sourceID={category.id}
							/>
						</ExtraDiv>
					)}
				>
					{category.games.length > 0 ? (
						<GamesList
							data={category}
							uiRefresh={uiRefresh}
							dataRefresh={dataRefresh}
							checkAll={this.renderCheckAll(category.games, category.id)}
							bonusData={bonusData}
							sourceID={category.id}
							disabled={readOnly}
							singleGameMode={singleGameMode}
							showCheckbox
						/>
					) : <div>{lang.noGame}</div>
					}
				</PanelAntd>
			);
		});
	}

	render() {
		const renderCategories = this.renderCategories();
		return (
			<Collapse>
				{renderCategories}
			</Collapse>
		);
	}
}

function mapStateToProps(state, props) {
	const { bonusData } = props;
	const { Casino } = state;
	return {
		categoryList: deriveGameCategoriesListGames(state),
		gameIDs     : bonusData.gameIDs,
		allChecked  : Casino.GameCategories.get('allCheckedCategories'),
		loading     : Casino.GameCategories.get('UI').loading,
	};
}
const mapDispatchToProps = {
	categoryListReload: categoryActions.listReloadGames,
	checkedAllGames   : categoryActions.checkedCategoryGamesActon,
	setOldCheckedData : categoryActions.oldCheckedDataList,
};

export default connect(mapStateToProps, mapDispatchToProps)(TabProviders);
