import isEmpty from 'lodash/isEmpty';
import toInteger from 'lodash/toInteger';
import Formatter from '../../../helpers/formatter';
import { HistoryEnums } from '../../sport/results/enums';
import { DiffMapper } from '../../../helpers/diff';

const fields = {
	objectBefore: 'object_before',
	objectAfter : 'object_after',
	userID      : 'source_id',
	sourceIp    : 'source_ip',
	sourceTypeID: 'source_type',
	actionTypeID: 'action_type',
	date        : 'date',
};

export function adaptHistoryList(rawData, diffOptions, arrayFields = []) {

	const historyEnums = new HistoryEnums();

	const dataList = [];

	const mapper = new DiffMapper();
	mapper.init(diffOptions);

	rawData.forEach((historyItem, historyItemIndex) => {
		let itemDiffList = [];

		const objectBefore = historyItem[fields.objectBefore] || {};
		const objectAfter  = historyItem[fields.objectAfter]  || {};

		// main data
		const diffMap  = mapper.map(objectBefore, objectAfter);
		const diffList = mapper.toList(diffMap);

		itemDiffList = itemDiffList.concat(diffList);

		// arrays
		arrayFields.forEach(fieldName => {
			const diffList = mapper.mapList(
				objectBefore,
				objectAfter,
				fieldName,
				false
			);
			itemDiffList = itemDiffList.concat(diffList);
		});

		if (isEmpty(itemDiffList)) {
			return;
		}

		// adding id for detail records
		itemDiffList.forEach((item, index) => {
			item.id = index;
		});

		// prepare result item
		const userID       = toInteger(historyItem[fields.userID]);
		const sourceIp     = historyItem[fields.sourceIp];
		const sourceTypeID = toInteger(historyItem[fields.sourceTypeID]);
		const actionTypeID = toInteger(historyItem[fields.actionTypeID]);

		const dataItem = {
			userID,
			sourceIp,
			id         : historyItemIndex,
			date       : Formatter.fullDateTime(historyItem[fields.date]),
			sourceType : historyEnums.sourceType(sourceTypeID),
			actionType : historyEnums.actionType(actionTypeID),
			sourceTypeID,
			detailsList: itemDiffList,
		};
		dataList.push(dataItem);
	});

	return dataList;
}
