import { CommunicationCodes as BaseCodes, SubjectType } from '@ceo-betmakers/common-globals';
// in accordance to /CommonJS/src/messaging/CommunicationCodes.ts
export const CommunicationCodes = {
	RTM_GET_BET_SLIPS: BaseCodes.RTM_GET_BET_SLIPS,
	RTM_GET_BET_SLIP : BaseCodes.RTM_GET_BET_SLIP,

	UPDATE_BET_SLIP  : BaseCodes.UPDATE_BET_SLIP,
	BET_REVIEW_UPDATE: BaseCodes.BET_REVIEW_UPDATE,
};


export const EVENT_TYPES = {
	betslip                   : SubjectType.BET,
	deposit                   : SubjectType.DEPOSIT,
	withdrawal                : SubjectType.WITHDRAWAL,
	casino                    : SubjectType.CASINO,
	userDocument              : 12,
	messages                  : SubjectType.MESSAGE,
	messageRequest            : SubjectType.REQUEST,
	eventMarketsStatistics    : 19,
	eventStatistic            : 20,
	document                  : SubjectType.DOCUMENT,
	riskManagement            : SubjectType.RISK_ALERT,
	riskAlertLow              : SubjectType.RISK_ALERT_LOW,
	riskAlertMedium           : SubjectType.RISK_ALERT_MEDIUM,
	riskAlertHigh             : SubjectType.RISK_ALERT_HIGH,
	notifBet                  : SubjectType.BET_NOTIFICATION,
	notifCasino               : SubjectType.CASINO_NOTIFICATION,
	notifWithdrawal           : SubjectType.WITHDRAW_NOTIFICATION,
	notifDeposit              : SubjectType.DEPOSIT_NOTIFICATION,
	riskManagementBets        : SubjectType.SPORT_BET_RISK,
	riskManagementMarketIDs   : SubjectType.RISK_EVENT_MARKET,
	riskManagementSelectionIDs: SubjectType.RISK_EVENT_SELECTION,
};
