import { createSelector } from 'reselect';
import { PlatformAdminRoles } from '@ceo-betmakers/common-globals';

const idToken = (state) => state.Auth.get('idToken');
const user    = (state) => state.Auth.get('user');

export const deriveToken = createSelector(
	[idToken],
	(token) => token
);

export const deriveUser = createSelector(
	[user],
	(user) => user
);

export const deriveIsLoggedIn = createSelector(
	[deriveToken],
	(token) => Boolean(token)
);

export const deriveUserIsPartnerAdminOrPartnerOperator = createSelector(
	[user],
	(user) => user.adminType > PlatformAdminRoles.SYSTEM_OPERATOR
);
