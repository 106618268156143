import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import cloneDeep from 'lodash/cloneDeep';
import toInteger from 'lodash/toInteger';
import isEmpty from 'lodash/isEmpty';
import actions from './actions';
import tableActions from '../../../tables/actions';
import { historyAPI } from '../../../../helpers/api/history';
import { showError, showSuccess } from '../../../../helpers/notifications';
import { bonusesAPI } from '../../../../helpers/api/bonuses';
import { getHeadersTotalCount } from '../../../../helpers/utils';
import { deriveTablePagination } from '../../../../selectors/tables';
import { CASHBACK_LOG_MODELS } from '../../../../helpers/commonConstants';

import {
	getListParams,
	adaptList,
	adaptSubList,
	prepareBonusData,
	adaptHistory,
	adaptCashback,
} from './utils';
import { TABLE_TYPES } from '../../../../constants/tableTypes';
import { logger } from '../../../../helpers/logger';
import { CashBackWalletTypes } from '@ceo-betmakers/common-globals';

const messages = {
	errorListLoad           : 'bonuses.cashback.list.load.fail',
	errorItemLoad           : 'bonuses.cashback.item.load.fail',
	errorSubcackbackListLoad: 'bonuses.cashback.subcashback.list.load.fail',
	errorDelete             : 'bonuses.cashback.delete.fail',
	errorSave               : 'bonuses.cashback.save.fail',
	successDelete           : 'bonuses.cashback.delete.success',
	successSave             : 'bonuses.cashback.save.success',
};

const tableType   = TABLE_TYPES.cashback;

function getStoreData(state) {
	const { Loyalty: { Bonuses }, Tables, App } = state;
	const { Cashback } = Bonuses;
	const UI = Cashback.get('UI');
	const { showGGRLimitTab, showMinGGRTab } = UI;

	return {
		filter    : Cashback.get('filter'),
		sorting   : Tables.get(tableType).sorting,
		pagination: deriveTablePagination(tableType, state),
		bonusData : Cashback.get('bonusData'),
		websiteID : App.get('websiteID'),
		showGGRLimitTab,
		showMinGGRTab,
	};
}

function* listReload() {

	yield takeEvery(actions.LIST_RELOAD, function* () {
		yield put(actions.uiRefresh({ loading: true }));

		const { filter, sorting, pagination } = yield select(getStoreData);
		const newFilter = cloneDeep(filter);

		newFilter.casinoGameIDs = !isEmpty(filter.casinoGameIDs) ? filter.casinoGameIDs.join(', ') : '';
		newFilter.casinoProviderIDs = !isEmpty(filter.casinoProviderIDs) ? filter.casinoProviderIDs.join(', ') : '';

		const params = getListParams(newFilter, sorting, pagination);

		let entities   = {};
		let totalCount = 0;
		try {
			const res = yield call(bonusesAPI.cashbackList, params);
			if (res && res.status === 200) {
				entities   = adaptList(res.data.data);
				totalCount = getHeadersTotalCount(res.headers);
				yield put(actions.listRefresh(entities));
			}
			yield put(tableActions.paginationRefresh(tableType, { totalCount }));

		} catch (error) {
			showError(messages.errorListLoad, error);
			logger.log(error);
		}

		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* getSubCashbacks() {

	yield takeEvery(actions.SUBCASHBACK_LIST_RELOAD, function* (action) {
		const { bonusData } = yield select(getStoreData);
		let list   = {};
		const { id } = action.data;
		const resData = cloneDeep(bonusData);
		try {
			const res = yield call(bonusesAPI.subCashbackList, id);
			if (res && res.status === 200) {
				list   = adaptSubList(res.data.data);
			}

		} catch (error) {
			showError(messages.errorSubcackbackListLoad, error);
			logger.log(error);
		}
		resData.subCashBacks = list;
		yield put(actions.subCashbacksRefresh(list));
		// yield put(actions.listReload()); TODO need CHECK
	});
}

function* getCashback() {

	yield takeEvery(actions.CASHBACK_ITEM_DATA_RELOAD, function* (action) {
		const { bonusData } = yield select(getStoreData);
		const { id } = action.data;
		try {
			const res = yield call(bonusesAPI.getCashbackItem, id);
			if (res && res.status === 200) {
				const resData = {
					...bonusData,
					...adaptCashback(res.data.data),
				};
				yield put(actions.dataRefresh(resData));
			}
		} catch (error) {
			showError(messages.errorItemLoad, error);
			logger.log(error);
		}

	});
}

function* filterApply() {
	yield takeEvery(actions.FILTER_APPLY, function* () {
		yield put(tableActions.paginationRefresh(tableType, { currentPage: 1 }));
		yield put(actions.listReload());
	});
}

function* bonusSave() {

	yield takeEvery(actions.BONUS_SAVE, function* () {

		yield put(actions.uiRefresh({ loading: true }));
		const { bonusData, websiteID, showGGRLimitTab, showMinGGRTab } = yield select(getStoreData);
		const resData      = cloneDeep({ ...bonusData, websiteID  });
		if (resData.subCashBacks) {
			resData.subCashBacks.forEach(item => {
				if (!showMinGGRTab) {
					item.minGGR = [];
				}
				if (!showGGRLimitTab) {
					item.ggrLimit = [];
				}
			});
		}
		if (!showMinGGRTab) {
			resData.minGGR = [];
		}
		if (!showGGRLimitTab) {
			resData.ggrLimit = [];
		}
		if (!resData.period || !resData.recurring) {
			resData.subCashBacks = [];
		}
		if (toInteger(resData.walletType) === CashBackWalletTypes.REAL) {
			resData.titles = [];
		}
		const preparedData = prepareBonusData(resData);

		let bonusID = resData.id;
		let isError = false;
		const params ={ 
			website_id: websiteID,
		};
		try {
			if (bonusID ) {
				yield call(bonusesAPI.cashbackUpdate, params, bonusID, preparedData);
			} else {
				const res = yield call(bonusesAPI.cashbackCreate, params, preparedData);
				bonusID = toInteger(res.data.data.id);
				resData.id = bonusID;
			}

			yield put(actions.getSubCashbacks(bonusID));

			showSuccess(messages.successSave);
			yield put(actions.uiRefresh({ isMainChanged: false, isSubChanged: false }));

		} catch (error) {
			isError = true;
			showError(messages.errorSave, error);
		}

		if (!isError) {
			yield put(actions.dataReset());
			yield put(actions.listReload());
		} else {
			yield put(actions.uiRefresh({ loading: false }));
		}
	});
}

function* bonusDelete() {

	yield takeEvery(actions.BONUS_DELETE, function* (action) {
		const { websiteID } = yield select(getStoreData);
		const { id } = action.data;
		const params = {
			website_id: websiteID,
		};
		try {
			yield call(bonusesAPI.cashbackDelete, params, id);
			showSuccess(messages.successDelete);
		} catch (error) {
			showError(messages.errorDelete, error);
			logger.log(error);
		}
		yield put(actions.listReload());
	});
}

function* historyDataReload() {

	yield takeEvery(actions.CASHBACK_HISTORY_DATA_RELOAD, function* (action) {

		yield put(actions.uiRefresh({ modalLoad: true }));
		const { websiteID } = yield select(getStoreData);
		const { cashbackID, logModel } = action.data;
		const params = {
			website_id: websiteID,
			model_id  : cashbackID,
		};

		try {
			let response;
			if (logModel === CASHBACK_LOG_MODELS.main) {
				response = yield call(historyAPI.historyCashbackLogs, params);
			} else {
				response = yield call(historyAPI.historyCashbackGGRlimitsLogs, params);
			}
			if (response && response.status === 200) {
				const data = adaptHistory(response.data.data);
				yield put(actions.historyDataRefrash(data));
			}
		} catch (error) {
			showError(messages.loadData, error);
			logger.log(error);
		}

		yield put(actions.uiRefresh({ modalLoad: false }));
	});
}

export default function* casinoCashbackSaga() {
	yield all([
		fork(listReload),
		fork(getSubCashbacks),
		fork(filterApply),
		fork(bonusSave),
		fork(bonusDelete),
		fork(historyDataReload),
		fork(getCashback),
	]);
}
