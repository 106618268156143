import { call, takeEvery, all, fork, put, select } from 'redux-saga/effects';
import { PARTNER_TABS } from '../../../../containers/Partner/Partners/utils';
import { integratorSelectAPI } from '../../../../helpers/api/integrator';
import { API_KEY } from '../../../../helpers/permissions/constants';
import { canRequest } from '../../../../helpers/permissions/utils';
import {
	restorePartnerID, restoreWebsite, storePartnerID,
	storeWebsiteID,
} from '../../../../helpers/utility';
import { getHeadersTotalCount } from '../../../../helpers/utils';
import appActions from '../../../app/actions';
import commonActions from '../../../common/actions';
import sidebarActions from '../../../sidebar/actions';
import tableActions from '../../../tables/actions';
import { actions as operatorActions } from '../modal/operator/actions';
import { adaptIntegratorsList } from '../utils';
import { actions as listActions } from './actions';
import { actions as paymentMethodActions } from '../modal/paymentMethods/actions';
import  personActions  from '../../../staff/person/actions';
import { partnerAPI } from '../../../../helpers/api/partner';
import {
	adaptGameIDs,
	adaptPartnerList,
	adaptProviderIDs,
	checkFetchedEntities,
	partnerAdapter,
	preparePartnerData,
	prepareIDs,
	prepareSettings, adaptPartnerWebsiteList, adaptPartner, updatePartnerList, mergePartnerList,
} from './utils';
import { actions, actions as partnerActions } from '../modal/partner/actions';
import notificationActions from '../../../notifications/actions';
import { getFilterParams } from '../filter/utils';
import { deriveTablePagination } from '../../../../selectors/tables';
import { TABLE_TYPES } from '../../../../constants/tableTypes';
import { actions as languageActions } from '../modal/language/actions';
import { actions as currencyActions } from '../modal/currency/actions';
import  currencySettingsActions  from '../../../settings/currency/actions';
import { actions as smsGatewaysActions } from '../modal/smsGateways/actions';
import { adaptLists } from '../modal/utils';
import notifications, { showError, showSuccess } from '../../../../helpers/notifications';
import { USER_STATUS } from '../../../../constants/users';
import { currencyAPI } from '../../../../helpers/api/currency';
import { websitesAPI } from '../../../../helpers/api/websites';
import { logger } from '../../../../helpers/logger';
import { convertToEntities } from '../../../settings/currency/utils';

const tableType = TABLE_TYPES.partners;
const prefix = 'partner.partners';

const errorMessages = {
	createPartnerErr   : `${prefix}.createPartnerErr`,
	getByIDErr         : `${prefix}.getByIDErr`,
	getPartnersErr     : `${prefix}.getPartnersErr`,
	reSendInvitation   : `${prefix}.reSendInvitation.error`,
	partnerWebsite     : `${prefix}.partnerWebsite`,
	errorDelete        : `${prefix}.errorDelete`,
	errorFetchLanguages: 'partner.websites.website.errorFetchLanguages',
	errorCurrencyLoad  : 'currency.errorCurrencyLoad',
};


const successMessages = {
	createPartner   : `${prefix}.createPartner`,
	reSendInvitation: `${prefix}.reSendInvitation`,
	partnerUpdate   : `${prefix}.partnerUpdate`,
	successDelete   : `${prefix}.successDelete`,
};


function getStoreData(state) {
	const { Partner, Settings, Auth } = state;
	const currencies = Settings.CurrencyModule.get('entities');

	const partnerID       = restorePartnerID();

	const userData = Auth.get('user') || {};

	const userPermissions = userData.userRoles || [];
	const { editMode } = Partner.Partner.List.get('UI');
	const partnerList = Partner.Partner.List.get('list');
	return {
		integratorsList   : Partner.Partner.Modal.Partner.get('integratorsWithProviders'),
		providersWithGames: Partner.Partner.Modal.Partner.get('providersWithGames'),
		smsGateWaysList   : Settings.SMS.SMSGateway.get('entity'),
		filter            : Partner.Partner.Filter.get('filter'),
		pagination        : deriveTablePagination(tableType, state),
		sorting           : {},
		partnerID,
		userPermissions,
		currencies,
		editMode,
		partnerList,
	};
}


function* partnerListReload() {
	yield takeEvery(listActions.LIST_RELOAD, function* () {
		yield put(listActions.uiRefresh({ loading: true }));

		const { filter, sorting, pagination } = yield select(getStoreData);
		const params = getFilterParams(filter, sorting, pagination);
		try {
			const res = yield call(partnerAPI.partnerList, params);
			if (res && res.status === 200) {
				const totalCount = getHeadersTotalCount(res.headers);
				const adapted = adaptPartnerList(res.data.data);
				yield put(tableActions.paginationRefresh(tableType, {  totalCount }));
				yield put(listActions.partnersListRefresh(adapted));
			}
		} catch (e) {
			showError(errorMessages.getPartnersErr);
		}
		yield put(listActions.uiRefresh({ loading: false }));
	});
}

function* partnersWithWebsiteReload() {
	yield takeEvery(listActions.PARTNERS_WEBSITE_LIST_RELOAD, function* () {
		yield put(personActions.uiRefresh({ partnerLoading: true }));
		let { partnerID } = yield select(getStoreData);

		try {
			const res = yield call(partnerAPI.partnerWithWebsiteList);

			if (res && res.status === 200) {
				const adapted = adaptPartnerWebsiteList(res.data.data);
				if (adapted.length) {
					const [firstPartner] = adapted;
					if (!partnerID) {
						partnerID = firstPartner.id;
					} else {
						const validPartner = adapted.some(partner => Number(partner.id) === Number(partnerID));
						if (!validPartner) {
							storePartnerID(firstPartner.id);
						}
					}


					const storedPartner = restorePartnerID();
					if (!storedPartner) {
						storePartnerID(firstPartner.id);
						yield put(appActions.partnerRefresh(firstPartner.id));
					} else {
						yield put(appActions.partnerRefresh(storedPartner));
					}

				}


				const partnerEntities = adapted.reduce((acc, partner) => {
					partner.name = partner.partnerName;
					acc[partner.id] = partner;
					return acc;
				}, {});
				const websiteEntities = partnerEntities[partnerID] ? partnerEntities[partnerID].websites.reduce((acc, website) => {
					acc[website.id] = website;
					return acc;
				}, {}) : {};

				yield put(appActions.partnerListRefresh(partnerEntities));
				yield put(appActions.websiteListRefresh(websiteEntities));
				const oldWebsiteID = restoreWebsite();

				let websiteID = 0;
				if (partnerEntities[partnerID]) {
					const { websites } = partnerEntities[partnerID];
					const hasWebsite = websites.some(website => Number(website.id) === Number(oldWebsiteID));

					if (hasWebsite) {
						yield put(appActions.websiteRefresh(oldWebsiteID));
						storeWebsiteID(oldWebsiteID);
					} else {
						websiteID = websites.length ? websites[0].id : '0';
						yield put(appActions.websiteRefresh(websiteID));
						storeWebsiteID(websiteID);
					}
				}

				if (!(partnerEntities[partnerID]
                  && partnerEntities[partnerID].websites
                  && partnerEntities[partnerID].websites.length)) {
					storeWebsiteID(websiteID);
				}

				if (!oldWebsiteID || Number(oldWebsiteID) === 0) {
					yield put(appActions.websiteRefresh(websiteID));
				}
				yield put(listActions.partnerWebsiteRefresh(adapted));
				yield put(listActions.commonListReload());
			}

			yield put(notificationActions.countReload());
		} catch (e) {
			yield put(personActions.uiRefresh({ partnerLoading: false }));
			showError(errorMessages.partnerWebsite);
		}
		yield put(personActions.uiRefresh({ partnerLoading: false }));

	});
}


function* partnerAttachedLanguages() {
	yield takeEvery(listActions.PARTNER_ATTACHED_LANGUAGES_LIST_RELOAD, function* () {
		yield call(makeRequestPartnerAttachedLanguages);
	});
}

export function* makeRequestPartnerAttachedLanguages() {
	const partnerID = restorePartnerID();
	try {
		const res = yield all([
			call(partnerAPI.getPartnerSettingsMainLanguages),
			call(partnerAPI.getPartnerSettingsLanguages, partnerID),
		]);

		const success = res.every(item => item && item.status === 200);
		const [languages, attachedLanguages] = res;
		if (success) {
			const partnerAttachedLanguages = languages.data.data.filter(langItem => {
				langItem.name = langItem.name.charAt(0).toUpperCase() + langItem.name.slice(1);
				return attachedLanguages.data.data.language_ids.some(item => item === langItem.id);
			});
			yield put(appActions.partnerAttachedLanguagesRefresh(partnerAttachedLanguages));
			return partnerAttachedLanguages;
		}
	} catch (error) {
		logger.log(error);
		showError(errorMessages.errorFetchLanguages);
	}
}


export function* makeRequestebsiteAttachedLanguages() {

	try {
		const websiteID = restoreWebsite();
		const res = yield all([
			call(websitesAPI.allLanguagesPartner, websiteID),
			call(websitesAPI.languageList, websiteID),
		]);

		const success = res.every(item => item && item.status === 200);
		const [languages, attachedLanguages] = res;
		if (success) {
			const websiteAttachedLanguages = languages.data.data.filter(langItem => {
				langItem.name = langItem.name.charAt(0).toUpperCase() + langItem.name.slice(1);
				return attachedLanguages.data.data.some(item => item.lang_id === langItem.id);
			});
			yield put(appActions.websiteAttachedLanguagesRefresh(websiteAttachedLanguages));
			return websiteAttachedLanguages;
		}

	} catch (error) {
		logger.log(error);
		showError(errorMessages.errorFetchLanguages);
	}
}

export function* makeRequestWebsiteCurrencyList() {

	try {
		let currencyList = [];
		let entities     = {};
		const params     = {
			unlimit            : true,
			default_currency_id: 1,
		};
		const res = yield call(currencyAPI.currencyList, params);

		if (res && res.status === 200) {
			currencyList = res.data.data;
			entities     = convertToEntities(currencyList);
			yield put(currencySettingsActions.currencyListRefresh(currencyList));
			yield put(currencySettingsActions.currencyRefreshEntities(entities));
			yield put(currencySettingsActions.changedIDsReset());
			return {
				currencyList,
				entities,
			};
		}

	} catch (error) {
		notifications.showError(errorMessages.errorCurrencyLoad, error);
		logger.log(error);
	}
}


function* websiteAttachedLanguages() {
	yield takeEvery(listActions.WEBSITE_ATTACHED_LANGUAGES_LIST_RELOAD, function* () {

		yield call(makeRequestebsiteAttachedLanguages);
	});
}

function* commonListReload() {
	yield takeEvery(listActions.COMMON_LIST_RELOAD, function* () {
		const { userPermissions } = yield select(getStoreData);
		yield put(currencySettingsActions.uiRefresh({ loading: true }));
		yield put(sidebarActions.uiRefresh({ loading: true }));

		yield call(makeRequestWebsiteCurrencyList);
		yield put(currencySettingsActions.uiRefresh({ loading: false }));


		const commonList = [
			{
				canReq: canRequest(userPermissions, API_KEY.admins),
				name  : 'admins',
				cb    : commonActions.commonAdminListReload,
			},
			{
				canReq: canRequest(userPermissions, API_KEY.riskGroups),
				name  : 'admins',
				cb    : commonActions.commonRiskGroupListReload,
			},
		];
		yield all([
			put(listActions.websiteAttachedLanguages()),
			put(appActions.websiteRestore()),
			put(appActions.partnerRestore()),
			put(sidebarActions.sidebarReload()),
			...commonList.filter(item => item.canReq ).map(item => put(item.cb())),
		]);
	});
}
// adaptLanguages

function* getPartnerById() {
	yield takeEvery(listActions.GET_PARTNER, function* (action) {
		const { data, closeModal } = action;
		yield put(partnerActions.uiRefresh({ loading: true }));
		const params= {
			unlimit            : true,
			default_currency_id: 1,
		};

		try {

			const [res, settings, currencies, currencyEntity, languages, mainLanguages, payments, mainPayments, providers, smsGateWays, integrators] = yield all([
				call(partnerAPI.getPartnerById,                 data),
				call(partnerAPI.getPartnerSettings,             data),
				call(partnerAPI.getPartnerSettingsCurrencies,   data),
				call(currencyAPI.currencyList, params),
				call(partnerAPI.getPartnerSettingsLanguages,    data),
				call(partnerAPI.getPartnerSettingsMainLanguages),
				call(partnerAPI.getPartnerSettingsPayments,     data),
				call(partnerAPI.getPatnerAvailablePayments),
				call(partnerAPI.getPartnerSettingsProviders,    data),
				call(partnerAPI.getPartnerSettingsSMSGateway,   data),
				call(integratorSelectAPI.integratorsWithProvidersNew),
			]);

			const combinedResponse = [
				res,
				settings,
				currencies,
				currencyEntity,
				languages,
				mainLanguages,
				payments,
				mainPayments,
				providers,
				smsGateWays,
				integrators,
			];
			const { providersWithGames, smsGateWaysList } = yield select(getStoreData);
			const successStatus             = combinedResponse.every(resp => resp && resp.status === 200);

			if (successStatus) {
				const adapted               = partnerAdapter(combinedResponse);
				const filteredIntegrators	= integrators.data.data.filter(integrator => integrator.providers && integrator.providers.length > 0);
				const adaptedIntegrators	= adaptIntegratorsList(filteredIntegrators);

				yield put(actions.integratorsWithProvidersRefresh(adaptedIntegrators));

				const { languageIDs, paymentIDs, currencyIDs, smsGateWayIDs } = adapted;
				const adaptedPaymentMethods = adaptLists(paymentIDs, mainPayments.data.data, 'paymentID');
				const adaptedCurrencies     = adaptLists(currencyIDs, currencyEntity.data.data, 'currencyID');
				const adaptedSmsGateways    = adaptLists(smsGateWayIDs, Object.values(smsGateWaysList || {}), 'smsGatewaysID');
				const adaptedLanguageIDs    = adaptLists(languageIDs, mainLanguages.data.data, 'langID').map(item => ({
					...item,
					name: item.name.charAt(0).toUpperCase() + item.name.slice(1),
				}));
				const {
					clonedIntegratorsData,
					clonedProvidersData,
				}                     = checkFetchedEntities(adaptedIntegrators, providersWithGames, adapted);
				yield put(partnerActions.providersWithGamesRefresh(clonedProvidersData));
				yield put(partnerActions.integratorsWithProvidersRefresh(clonedIntegratorsData));
				if (!closeModal) {
					yield put(partnerActions.baseDataRefresh(adapted));
				}
				yield put(languageActions.baseDataRefresh({ languageIDs: adaptedLanguageIDs }));
				yield put(currencyActions.baseDataRefresh({ currencyIDs: adaptedCurrencies }));
				yield put(smsGatewaysActions.baseDataRefresh({ smsGatewaysList: adaptedSmsGateways }));
				yield put(paymentMethodActions.baseDataRefresh({ paymentIDs: adaptedPaymentMethods }));
				yield put(partnerActions.uiRefresh({ loading: false }));

				yield put(listActions.uiRefresh({ loading: false, deletedPartner: (adapted.status === USER_STATUS.deleted) }));
			}
		} catch (e) {
			logger.log(e, 'error');
			yield put(listActions.uiRefresh({ loading: false }));
			yield put(partnerActions.uiRefresh({ loading: false }));
			showError(errorMessages.getByIDErr);
		}
	});
}

function* createPartner() {
	yield takeEvery(listActions.CREATE_PARTNER, function* (action) {
		yield put(partnerActions.uiRefresh({ loading: true }));
		const { data }                              = action;
		const { baseDataPartner, baseDataOperator, closeModal } = data;
		const prepared = preparePartnerData(baseDataPartner, baseDataOperator);
		const { partnerList } = yield select(getStoreData);
		try {
			const res = yield call(partnerAPI.createPartner, prepared);
			if (res && res.status === 200) {
				const adapted = adaptPartner(res.data.data);
				const updatedList = mergePartnerList(partnerList, adapted);
				yield put(listActions.partnersListRefresh(updatedList));
				yield put(listActions.partnersWithWebsitesReload());
				yield put(listActions.getPartnerById(res.data.data.id, closeModal));
				yield put(listActions.uiRefresh({
					partnerID: res.data.data.id,
					editMode : true,
					activeTab: PARTNER_TABS.partner,
					loading  : false,
				}));
			}
			showSuccess(successMessages.createPartner);
			yield put(listActions.uiRefresh({ visible: !closeModal }));
		} catch (e) {
			showError(errorMessages.createPartnerErr);
		} finally {
			yield put(partnerActions.uiRefresh({ loading: false }));
		}

		if (closeModal) {
			yield put(listActions.uiRefresh({ visible: false, activeTab: PARTNER_TABS.partner }));
			yield put(operatorActions.resetStore());
			yield put(partnerActions.resetStore());
		}
	});

}

function* updatePartner() {
	yield takeEvery(listActions.UPDATE_PARTNER, function* ({ data }) {
		yield put(partnerActions.uiRefresh({ loading: true }));

		const { baseDataPartner, baseDataOperator, baseDataLanguages, baseDataPaymentMethods, baseDataCurrency, baseDataSmsGateways, baseDataSCountries, closeModal } = data;
		const { integratorsList, providersWithGames, partnerList } = yield select(getStoreData);
		const { suspendLive, suspendPreMatch, id: partnerID } = baseDataPartner;

		const { integratorsIDs, providerIDs } = adaptProviderIDs(integratorsList);

		const gameIDs           = adaptGameIDs(providersWithGames, providerIDs);
		const languageIDs       = prepareIDs(baseDataLanguages, 'languageIDs', 'langID');
		const paymentMethodIDs  = prepareIDs(baseDataPaymentMethods, 'paymentIDs', 'paymentID');
		const currencyIDs       = prepareIDs(baseDataCurrency, 'currencyIDs', 'currencyID' );
		const smsGateWayIDs     = prepareIDs(baseDataSmsGateways, 'smsGatewaysList', 'smsGatewaysID');
		const countryIDs        = prepareIDs(baseDataSCountries, 'countriesList', 'countryID');
		const prepared          = preparePartnerData(baseDataPartner, baseDataOperator);
		const baseDataPartnerGameIDs = baseDataPartner.gameIDs;

		const settingsData      = {
			paymentMethodIDs,
			languageIDs,
			smsGateWayIDs,
			suspendLive,
			suspendPreMatch,
			currencyIDs,
			integratorsIDs,
			providerIDs,
			gameIDs,
			countryIDs,
			baseDataPartnerGameIDs,
		};
		const {
			preparedPaymentIDs,
			preparedLanguageIDs,
			preparedSMSGateWayIDs,
			preparedSettings,
			preparedCurrencyIDs,
			preparedProviderIDs,
			preparedCountryIDs,
		} = prepareSettings(settingsData);

		try {
			const res = yield all([
				call(partnerAPI.updatePartner, partnerID, prepared, null),
				call(partnerAPI.updatePartnerSettings, preparedSettings, partnerID),
				call(partnerAPI.updatePartnerSettingsCurrencies, preparedCurrencyIDs, partnerID),
				call(partnerAPI.updatePartnerSettingsLanguages, preparedLanguageIDs, partnerID),
				call(partnerAPI.updatePartnerSettingsProviders, preparedProviderIDs, partnerID),
				call(partnerAPI.updatePartnerSettingsPayments, preparedPaymentIDs, partnerID),
				call(partnerAPI.updatePartnerSettingsSMSGateway, preparedSMSGateWayIDs, partnerID),
				call(partnerAPI.updatePartnerSettingsCountry, preparedCountryIDs, partnerID),
			]);
			const successStatus = res.every(response => response && response.status === 200);

			if (successStatus) {
				const [partnerData] = res;
				const adaptedPartner = adaptPartner(partnerData.data.data);
				const updatedList = updatePartnerList(partnerList, adaptedPartner);
				yield put(listActions.partnersListRefresh(updatedList));
			}
			showSuccess(successMessages.partnerUpdate);

		} catch (e) {
			logger.log(e, 'error creating');
			showError(errorMessages.createPartnerErr);

		}

		yield put(listActions.uiRefresh({ loading: false, visible: !closeModal }));
		yield put(listActions.getPartnerById(partnerID));
		if (closeModal) {
			yield put(partnerActions.resetStore());
			yield put(listActions.uiRefresh({ visible: false, activeTab: PARTNER_TABS.partner }));
			yield put(operatorActions.resetStore());
		}
	});
}

function* deletePartner() {
	yield takeEvery(listActions.DELETE_PARTNER, function* ({ data : { partnerID, password } }) {
		yield put(listActions.uiRefresh({ loading: true }));
		const body = { password };
		try {
			const res = yield call(partnerAPI.updatePartner, partnerID, body, 'DELETE');
			if ( res && res.status === 200) {
				const restoredPartner = restorePartnerID();
				if (Number(restoredPartner) === Number(partnerID)) {
					localStorage.removeItem('partnerID');
					yield put(listActions.partnersWithWebsitesReload());
				}
				yield put(listActions.partnerListReload());

			}
			showSuccess(successMessages.successDelete);
		} catch (e) {
			showError(errorMessages.errorDelete, e);
			logger.log(e);
		}
		yield put(listActions.uiRefresh({ loading: false }));

	});
}

function* resendInvitationLink() {
	yield takeEvery(listActions.RESEND_INVITATION, function* ({ data : mainUserID }) {
		try {
			yield call(partnerAPI.reSendInvitation, null, mainUserID);
			showSuccess(successMessages.reSendInvitation);
		} catch (e) {
			showError(errorMessages.reSendInvitation);
		}
	});

}

export default function* partnerListSaga() {
	yield all([
		fork(partnerListReload),
		fork(getPartnerById),
		fork(createPartner),
		fork(updatePartner),
		fork(deletePartner),
		fork(resendInvitationLink),
		fork(partnersWithWebsiteReload),
		fork(commonListReload),
		fork(partnerAttachedLanguages),
		fork(websiteAttachedLanguages),
	]);
}
