import toInteger from 'lodash/toInteger';

import ParamsBuilder from '../../../../helpers/paramsBuilder';
import EntityAdapter from '../../../../helpers/entityAdapter';
import { VERIFIED_STATUS } from '../../../../constants/participants';
import { ParticipantType } from '@ceo-betmakers/common-globals';

const fields = {
	id          : 'id',
	sportID     : 'sport_id',
	genderID    : 'gender_id',
	roleID      : 'role_id',
	ageID       : 'age_id',
	birthday    : 'birthday',
	birthdayFrom: 'birthday_from',
	birthdayTo  : 'birthday_to',
	websiteID   : 'website_id',
	name        : 'name',
	surname     : 'surname',
	altName     : 'alt_name',
	altSurname  : 'alt_surname',
	altName1    : 'alt_name_1',
	altName2    : 'alt_name_2',
	countryID   : 'country_id',
	leagueID    : 'league_id',
	verified    : 'verified',
	// filter
	verifiedID  : 'verified',
};

const participantAdapter = createParticipantAdapter();
const memberAdapter = createMemberAdapter();

// Params -----------------------------------------------------------------------------------------
export function getListParams(filter, pagination, sorting) {
	const builder = new ParamsBuilder();
	const rules = builder.RULES;

	builder.addValue('sort_order', sorting.sortOrder);
	builder.addValue('sort_by', sorting.sortBy);

	builder.addValue('page', pagination.currentPage);
	builder.addValue('limit', pagination.itemsPerPage);

	if (filter.typeID === ParticipantType.TEAM) {
		builder.addField(rules.isID, 'sportID', fields.sportID);
		builder.addField(rules.isID, 'genderID', fields.genderID);
		builder.addField(rules.isID, 'ageID', fields.ageID);
		builder.addField(rules.isID, 'websiteID', fields.websiteID);

		builder.addField(rules.isString, 'teamName', fields.name);
		builder.addField(rules.isString, 'altTeamName1', fields.altName1);
		builder.addField(rules.isString, 'altTeamName2', fields.altName2);

		builder.addField(rules.isID, 'countryID', fields.countryID);
		builder.addField(rules.isID, 'leagueID', fields.leagueID);

	} else {
		builder.addField(rules.isString, 'name', fields.name);
		builder.addField(rules.isString, 'surname', fields.surname);
		builder.addField(rules.isString, 'altName', fields.altName);
		builder.addField(rules.isString, 'altSurname', fields.altSurname);
		builder.addField(rules.isID, 'roleID', fields.roleID);

		builder.addRangeField(rules.isDateTimeRange, filter.birthday, [
			fields.birthdayFrom,
			fields.birthdayTo,
		]);
	}

	const params = builder.biuldParams(filter);
	if (filter.typeID === ParticipantType.TEAM) {
		if (toInteger(filter.verifiedID) === VERIFIED_STATUS.verified) {
			params[fields.verifiedID] = true;
		}
		if (toInteger(filter.verifiedID) === VERIFIED_STATUS.notVerified) {
			params[fields.verifiedID] = false;
		}
	}

	return params;
}

// Adapt ------------------------------------------------------------------------------------------
export function adaptParticipantsList(rawData = []) {
	participantAdapter.clearExcludes();
	return participantAdapter.adaptList(rawData);
}

export function adaptMemberssList(rawData = []) {
	memberAdapter.clearExcludes();
	return memberAdapter.adaptList(rawData);
}

// Adapters ---------------------------------------------------------------------------------------
function createParticipantAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'sportID', fields.sportID);
	adapter.addField(rules.id, 'websiteID', fields.websiteID);
	adapter.addField(rules.string, 'name', fields.name);
	adapter.addField(rules.bool, 'verified', fields.verified);

	return adapter;
}

function createMemberAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'roleID', fields.roleID);
	adapter.addField(rules.id, 'websiteID', fields.websiteID);
	adapter.addField(rules.string, 'name', fields.name);

	return adapter;
}
