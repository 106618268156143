import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import userActions from '../../../../redux/users/user/actions';
import { FC, useEffect } from 'react';
import { IPropsUserLink } from './types';

const UserLink:FC<IPropsUserLink> = ({ userID, htmlId, children, tabName, tabValue }) => {
	const dispatch = useDispatch();

	useEffect(() => {
		if (tabName && tabValue) {
			dispatch(userActions.setValueUI(tabName, tabValue));
		}
	}, []);
	// console.log('useEffect ~ tabName && tabValue : ', tabName && tabValue); TODO AVET 100 animast rerender erb vor navigate es linum user֊ի inner

	return <NavLink
		to={htmlId ? `/dashboard/users/info/${userID}#${htmlId}` : `/dashboard/users/info/${userID}`}
	>
		{children}
	</NavLink>;
};


export default UserLink;
