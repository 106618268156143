import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import cloneDeep from 'lodash/cloneDeep';
import toInteger from 'lodash/toInteger';
import actions from './actions';
import tableActions from '../../../tables/actions';
import { historyAPI } from '../../../../helpers/api/history';
import { showError, showSuccess } from '../../../../helpers/notifications';
import { bonusesAPI } from '../../../../helpers/api/bonuses';
import { getHeadersTotalCount } from '../../../../helpers/utils';
import { deriveTablePagination } from '../../../../selectors/tables';

import {
	getListParams,
	adaptList,
	prepareBonusData,
	adaptHistory,
	adaptBonusItem,
} from './utils';
import { TABLE_TYPES } from '../../../../constants/tableTypes';
import { logger } from '../../../../helpers/logger';
import appTabsActions from '../../../appTabs/actions';

const messages = {
	errorListLoad    : 'welcomebonus.error.list.load',
	errorDelete      : 'welcomebonus.error.delete',
	errorSave        : 'welcomebonus.error.save',
	errorCurrencyLoad: 'welcomebonus.error.currency.load',
	successDelete    : 'welcomebonus.success.delete',
	successSave      : 'welcomebonus.success.save',
	errorGetTable    : 'welcomebonus.error.get.table',
	errorPostTable   : 'welcomebonus.error.post.table',
};

const tableType   = TABLE_TYPES.bonusesWelcomeDeposit;

function getStoreData(state) {
	const { Loyalty: { Bonuses }, Tables, App } = state;
	const { WelcomeBonus } = Bonuses;
	const UI = WelcomeBonus.get('UI');

	return {
		filter    : WelcomeBonus.get('filter'),
		sorting   : Tables.get(tableType).sorting,
		pagination: deriveTablePagination(tableType, state),
		bonusData : WelcomeBonus.get('bonusData'),
		editMode  : UI.editMode,
		closeModal: UI.closeModal,
		websiteID : App.get('websiteID'),
	};
}

function* listReload() {

	yield takeEvery(actions.LIST_RELOAD, function* () {
		yield put(actions.uiRefresh({ loading: true }));
		const { filter, sorting, pagination } = yield select(getStoreData);
		const params = getListParams(filter, sorting, pagination);
		if (params.affiliate_reference) {
			params.affiliate_reference = [params.affiliate_reference];
		}
		let list   = {};
		let totalCount = 0;
		try {
			const res = yield call(bonusesAPI.welcomeBonusList, params);
			if (res && res.status === 200) {
				list   = adaptList(res.data.data);
				totalCount = getHeadersTotalCount(res.headers);
				yield put(actions.listRefresh(list));
			}
			yield put(tableActions.paginationRefresh(tableType, { totalCount }));
		} catch (error) {
			showError(messages.errorListLoad);
			logger.log(error);
		}
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* filterApply() {
	yield takeEvery(actions.FILTER_APPLY, function* () {
		yield put(tableActions.paginationRefresh(tableType, { currentPage: 1 }));
		yield put(actions.listReload());
	});
}

function* bonusSave() {

	yield takeEvery(actions.BONUS_SAVE, function* () {

		yield put(actions.uiRefresh({ loading: true }));

		const { bonusData, closeModal, websiteID } = yield select(getStoreData);
		const resData      = cloneDeep(bonusData);
		resData.websiteID  = websiteID;
		const editMode		= !isNaN(bonusData.id);
		const preparedData = prepareBonusData(resData, editMode);
		let bonusID = resData.id;
		let isError = false;
		try {
			if (editMode) {
				const res =	yield call(bonusesAPI.welcomeBonusUpdate, bonusID, preparedData);
				resData.logoURL = res.data.data.logo_url;

			} else {
				const res = yield call(bonusesAPI.welcomeBonusCreate, preparedData);
				bonusID = toInteger(res.data.data.id);
				resData.id = bonusID;
				resData.logoURL = res.data.data.logo_url;

				yield put(actions.uiRefresh({ editMode: true }));
				yield put(appTabsActions.openTabWelcomeBonusesList());
			}
			yield put(actions.dataRefresh(resData));

			showSuccess(messages.successSave);
			yield put(actions.uiRefresh({ isChanged: false }));

		} catch (error) {
			isError = true;
			showError(messages.errorSave);
		}

		if (!isError && closeModal) {
			yield put(actions.dataReset());
			yield put(actions.listReload());

		} else if (!isError || !closeModal) {
			yield put(actions.uiRefresh({ loading: false }));
			yield put(actions.listReload());

		} else {
			yield put(actions.uiRefresh({ loading: false }));
		}
	});
}
function* getBonusByID() {
	yield takeEvery(actions.GET_BONUS_BY_ID, function* ({ data }) {
		yield put(actions.uiRefresh({ loading: true }));
		try {
			const res = yield call(bonusesAPI.getByID, data);
			if (res && res.status === 200) {
				const bonus = adaptBonusItem(res.data.data);
				yield put(actions.dataRefresh(bonus));
			}
		} catch (error) {
			showError(messages.errorListLoad);
			logger.log(error);
		}
		yield put(actions.uiRefresh({ loading: false }));
	});
}


function* historyLogsReload() {

	yield takeEvery(actions.HISTORY_LOGS_RELOAD, function* (action) {

		const { bonusID } = action.data;
		let historyLogs = [];
		try {
			const res = yield call(historyAPI.historyWelcomeBonusLogs, bonusID);
			if (res && res.status === 200) {
				historyLogs = adaptHistory(res.data.data);
				yield put(actions.historyLogsRefresh(historyLogs));
			}
		} catch (error) {
			showError(messages.loadData);
			logger.log(error);
		}

		yield put(actions.historyLogsRefresh(historyLogs));
	});
}

export default function* registrationFreeSpinsSaga() {
	yield all([
		fork(getBonusByID),
		fork(listReload),
		fork(filterApply),
		fork(bonusSave),
		fork(historyLogsReload),
	]);
}
