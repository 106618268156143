import { IGlobalCurrencies } from '../../settings/currency/types';
import { IPaginationTables, ISortingTables } from '../../tables/types';

export interface IBonusTemplatesInitState {
	list      : IBonusTemplate[];
	filterData: IFilterData;
	baseData  : ITemplateBonusMoney | ITemplateFreeSpins | null;

	UI: IBonusTemplateUI;
}

export interface IBonusTemplateResponse {
	id                   : number;
	name                 : string;
	status_id            : EBonusTemplateStatus;
	website_id           : number;
	template_type        : number;
	logo_url             : string;
	template_start_date  : string;
	template_expire_date : string;
	forced               : boolean;
	affiliate_reference  : string[] | null;
	days_to_expire_bonus : number;
	days_to_expire_wallet: number;
	wagering_turnover    : number;
	type_id              : number | null;
	casino_game_id       : number | null;
	denomination_value   : number | null;	// free spins type
	bet_per_line         : number | null; // free spins type
	lines                : number | null; // free spins type
	spins_total?         : number | null; // free spins type
	spins_from?          : number | null; // free spins type
	spins_to?            : number | null; // free spins type
	game_ids             : number[];
	allow                : boolean;
	integrator_ids       : number[];
	provider_ids         : number[];
	category_ids         : number[];
	tag_ids              : number[];
	country_ids?         : number[];
	country_allow?       : boolean;
	titles               : ITemplateTitleResponse[];
	currencies           : ICurrencyResponse[];
	createDate           : string;
	overlap              : boolean;
	weight               : number;
	used_total_freespins?: number;
	count								: {
		providers : Record<number, number>;
		categories: Record<number, number>;
	};
}

export interface IBonusTemplateBase {
	id                : number;
	name              : string;
	statusID          : EBonusTemplateStatus;
	websiteID         : number;
	templateType      : EBonusTemplateTypeIDs;
	logoURL           : string;
	templateStartDate : string;
	templateExpireDate: string;
	forced            : boolean;
	affiliateReference: string[] | null;
	daysToExpireBonus : number;
	daysToExpireWallet: number;
	wageringTurnover  : number;
	typeID            : number;

	gameIDs       : number[];
	allow         : boolean;
	countriesAllow: boolean;
	integratorIDs : number[];
	providerIDs   : number[];
	categoryIDs   : number[];
	tagIDs        : number[];
	currencyID    : number[];
	// countryIDs   : number[];
	// countryAllow : boolean;
	titles        : ITemplateTitle[];
	currencies    : ICurrency[];
	count				: {
		providers : Record<number, number>;
		categories: Record<number, number>;

	};

}


export interface ITemplateBonusMoney extends IBonusTemplateBase {

}

export interface ITemplateFreeSpins extends IBonusTemplateBase {
	spinsTotal             : number | null;
	spinsFrom              : number | null;
	spinsTo                : number | null;
	casinoGameID           : number | null;
	casinoGameName         : string;
	denominationKey?       : string | null;
	denominationValue      : number | null;
	betPerLine             : number | null;
	lines                  : number | null;
	allow                  : boolean;
	remainingSpinsTotal?   : number;
	usedTotalFreespins?    : number;
	showRemainingSpinsTotal: boolean;
}

export interface ITemplateEligibility {
	providerIDs  : number[];
	integratorIDs: number[];
	gameIDs      : number[];
	allow        : boolean;
	count        : {
		providers: {
			[key: string]: number;
		};
	};
}
export interface IBonusTemplate extends ITemplateBonusMoney, ITemplateFreeSpins {

}
export interface IWebsiteLanguages {
	id        : number;
	code      : string;
	name      : string;
	is_default: boolean;
  }

export interface IBonusTemplateUI {
	loading       : boolean;
	isChanged     : boolean;
	langID        : number;
	saveSuccess   : boolean;
	betID         : number | null;
	gameName      : string | null;
	betCurrencyTab: number | null;
	uiChanged     : boolean;
}

export enum EBonusTemplateTypeIDs {
	MANUAL_BONUS_MONEY = 1,
	MANUAL_FREE_SPINS = 2,
}

export enum EBonusTemplateStatus {
	ACTIVE = 1,
	INACTIVE = 2,
	ARCHIVED = 3,
}

export interface ITemplateTitleResponse {
	lang_id    : number;
	title      : string | null;
	description: string | null;
}

export interface ITemplateTitle {
	langID     : number;
	title      : string | null;
	description: string | null;
}

export interface ICurrencyResponse {
	currency_id       : number;
	amount_to         : number;
	amount_from       : number;
	amount_total      : number;
	max_payout_amount : number;
	max_payout_percent: number;
	denomination_value: number | null;
	bet_per_line      : number | null;
	lines             : number | null;
	used_total_amount?: number;
}

export interface ICurrency {
	currencyID               : number;
	amountTo                 : number;
	amountFrom               : number;
	amountTotal              : number;
	maxPayoutAmount          : number;
	maxPayoutPercent         : number;
	denominationKey          : number | string;
	denominationValue        : number | null;
	betPerLine               : number | null;
	lines                    : number | null;
	denom?                   : string;
	remainingTotalAmount?    : number;
	usedTotalAmount?         : number;
	showRemainingTotalAmount?: boolean;
	denomination             : number;
}
export interface ISaveAction {
	type    : string;
	typeID  : EBonusTemplateTypeIDs;
	editMode: boolean;
}

export interface IFilterData {
	name              : string;
	statusID          : string[];
	templateType      : string;
	currencyID        : string;
	createDate        : string;
	templateExpireDate: string;
	wageringTurnover  : string;
	affiliateReference: string[];
	casinoGameID      : string;
}


export interface IGetStoreTemplates {
	baseData         : IBonusTemplate;
	filterData       : IFilterData;
	denominationValue: number | null;
	sorting          : ISortingTables;
	pagination       : IPaginationTables;
	websiteID        : number;
	websiteLanguages : IWebsiteLanguages[];
	currencies       : IGlobalCurrencies;
}
