/**
 * ATTENTION!
 * For everyone who will attempt to change this constant or whichever else:
 * DO NOT REMOVE OR COMMENT members of constant! Never!
 * If you need not full constant, create your own based on original:
 *
 * const MY_COOL_CONSTANT = {
 *   active: EVENT_STATUS.active,
 *   inactive: EVENT_STATUS.inactive,
 * }
 *
 * If you need not full emum based on this constant, create you own via helper
 * cutEnum() from '/helpers/utils':
 *
 * const myCoolList = cutEnum(eventStatusList, [
 *  EVENT_STATUS.partlyInactive,
 *  EVENT_STATUS.partlySettled,
 *  EVENT_STATUS.void,
 * ]);
 */
export const EVENT_STATUS = {
	active        : 1,
	inactive      : 2,
	archived      : 3,
	suspended     : 4,
	partlyInactive: 5,
	partlySettled : 6,
	settled       : 7,
	fullyUnsettled: 8,
	void          : 9,
};

export const ResolutionStatus = {
	AWAITING_RESULTING: 1,
	RESULTED          : 2,
	RESULTED_PARTLY   : 3,
	SETTLED           : 4,
	SETTLED_PARTLY    : 5,
	UNSETTLED         : 6,
	MULTI_RESULTED    : 7,
};

export const EVENT_CATEGORY_TYPES = {
	matchFight: 1,
	outright  : 2,
	race      : 3,
	special   : 4,
	rankEvent : 5,
};

export const EVENT_RESOLUTION_STATUS = {
	awaitingResulting: 1,
	resulted         : 2,
	resultedPartly   : 3,
	unsettled        : 4,
	multiresulted    : 5,
};

/* export const EVENT_RESOLUTION_STATUS = {
  awaitingResulting : 1,
  resulted          : 2,
  resultedPartly    : 3,
  unsettled         : 4,
  settled           : 5,
  settledPartly     : 6,
  multiresulted     : 8,
}; */


export const EVENT_MARKETS_STATISTICS_TABLE_COLUMNS = [
	{
		title: 'default',
		id   : '1',
	}, {
		title: 'currentPrice',
		id   : '2',
	}, {
		title: 'averagePrice',
		id   : '3',

	}, {
		title: 'openingPrice',
		id   : '4',
	}, {
		title: 'marketMargin',
		id   : '5',
	}, {
		title: 'numberOfAllBets',
		id   : '6',
	}, {
		title: 'numberOfBets',
		id   : '7',
	}, {
		title: 'numberOfNonSingle',
		id   : '8',
	}, {
		title: 'numberOfOpenBets',
		id   : '9',
	}, {
		title: 'numberOfSettledBets',
		id   : '10',
	}, {
		title: 'numberOfBetsLost',
		id   : '11',
	}, {
		title: 'amountOfSingle',
		id   : '12',
	}, {
		title: 'amountOfNonSingle',
		id   : '13',
	}, {
		title: 'amountOfAllStakes',
		id   : '14',
	}, {
		title: 'potentialPayout',
		id   : '15',
	}, {
		title: 'liability',
		id   : '16',
	}, {
		title: 'totalTurnover',
		id   : '17',
	}, {
		title: 'marketLimits',
		id   : '18',
	}];

export const EVENT_PROVIDERS = {
	1  : 'LSports',
	2  : 'Unibet',
	3  : 'Expekt',
	4  : 'Bet-At-Home',
	5  : 'Pinnacle',
	6  : 'BetClick',
	7  : 'Interwetten',
	8  : 'Tipico',
	9  : 'Bet365',
	10 : 'Noxwin',
	11 : 'PartyBets',
	12 : 'Gamebookers',
	13 : '24hPoker',
	14 : 'BWin',
	15 : 'Betoto',
	16 : 'CircleBet',
	17 : 'ToroPoker',
	18 : 'IrishEyesPoker',
	19 : 'Pro-Factory',
	20 : 'KasinoPotti',
	21 : 'BetRedKings',
	22 : '188Bet',
	23 : 'JetBull',
	24 : 'BestBet',
	25 : 'BetMotion',
	26 : 'ArtemisBet',
	27 : 'BetCrib',
	28 : 'TopSport.lt',
	29 : 'TowerGaming',
	30 : 'TitanBet',
	31 : 'Bet770',
	32 : 'William Hill',
	33 : '5Dimes',
	34 : 'SportBet.com',
	35 : 'Stan James',
	36 : 'YouWin',
	37 : 'SBOBet',
	38 : 'BlueSquare',
	39 : '888',
	40 : 'RedBet',
	41 : '10Bet',
	42 : 'BetFair',
	43 : 'BetVictor',
	44 : 'NordicBet',
	45 : 'TrioBet',
	46 : 'Paf',
	47 : 'Betsson',
	48 : 'Interapostas',
	49 : 'Unibet.it',
	50 : 'BetClic.it',
	51 : 'Mybet',
	52 : 'BoyleSports',
	53 : 'BetFred',
	54 : 'DoBet',
	55 : 'Whitebet',
	56 : 'HeyPoker',
	57 : 'DafaBet',
	58 : '12Bet',
	59 : 'BetGun',
	60 : 'WagerWeb',
	61 : 'BetCRIS',
	62 : 'Bookmaker.eu',
	63 : 'Uwin',
	64 : 'Comeon',
	65 : 'BetDaq',
	66 : 'JustBet',
	67 : 'BetGuardian',
	68 : 'UCABet',
	69 : 'BetWay',
	70 : 'PaddyPower',
	71 : 'RuBet',
	72 : 'MermaidBet.dk',
	73 : 'MarathonBet',
	74 : 'Winner.com',
	75 : 'Bet3000',
	76 : 'CoralBet',
	77 : 'SingBet',
	78 : 'Tipico.it',
	79 : 'Ladbrokes',
	80 : 'OneBall',
	81 : 'IBC',
	82 : 'WBX',
	83 : 'BetRally',
	84 : 'Bodog',
	85 : 'BetCity',
	86 : 'Foobet',
	87 : 'PlanetWin365',
	88 : 'PMU',
	89 : 'OddsRing',
	90 : 'm88',
	91 : 'SkyBet',
	92 : 'RaceBets',
	93 : 'SportsBetAu',
	94 : 'LuxBet',
	95 : 'TabAu',
	96 : 'DigiBet',
	97 : 'MatchPoint',
	98 : 'HKJockeyClub',
	99 : 'SportingBet AU',
	100: 'CentreBet',
	101: '18Bet',
	102: 'MatchBook',
	103: 'DashBet',
	104: 'Vernons',
	105: 'PariMatch',
	106: 'Ball2Win',
	107: 'LadbrokesAu',
	108: 'Caribsports',
	109: 'NowGoal',
	110: 'Bet2Be',
	111: 'The Greek',
	112: 'BetISN',
	113: 'TabNz',
	114: 'CrownBet',
	115: 'Tatts',
	116: 'TopSport',
	117: 'William Hill AU',
	118: 'SportingSolutions',
	119: 'UBET',
	120: 'BetFairSportsbook',
	121: 'JazzSports',
	122: 'PokerStars',
	123: '138',
	124: 'PremierBet',
	125: 'ToteSport',
	126: 'LigaStavok',
	127: '32Red',
	128: 'Betolot',
	129: '1XBet',
	130: 'NextBet',
	131: 'KeibaGOJP',
	132: 'CashPoint',
	133: 'YouWager',
	134: 'HeritageSports',
	135: 'SportingBetEu',
	136: 'Drf',
	137: 'NorskTipping',
	138: 'VBet',
	139: 'Sunbets',
	140: 'NetBet',
	141: 'FonBet',
	142: 'Fun88',
	143: 'MetricGaming',
	144: 'TlcBet',
	145: '10BetUK',
	146: 'MaxBet',
	147: 'Betaland',
	148: 'MeridianBet',
	149: 'BetEasy',
	150: 'Hkjc',
	151: 'VanillaBet',
};
