import { isArray, isEmpty, toInteger } from 'lodash';
import dayjs from 'dayjs';
import ParamsBuilder from '../../../../helpers/paramsBuilder';
import Formatter from '../../../../helpers/formatter';

import EntityAdapter from '../../../../helpers/entityAdapter';
import { isID } from '../../../../helpers/utils';
import { adaptHistoryList } from '../utils';
import QueryToFilterValidaion from '../../../../helpers/queryToFilterValidaion';
import { createEntities } from '../../../utility';
import { CashBackWalletTypes } from '@ceo-betmakers/common-globals';

const { isValidID, isValidArray, isValidDateRange, isValidBool } = QueryToFilterValidaion;
export const initFilterTypes = {
	// primary
	recurring: isValidBool,
	
	id      : isValidID,
	statusID: isValidID,

	ggrPercent       : isValidArray,
	casinoGameIDs    : isValidArray,
	casinoProviderIDs: isValidArray,

	startDate     : isValidDateRange,
	endDate       : isValidDateRange,
	settlementDate: isValidDateRange,
};

export const fields = {
	name              : 'name',
	id                : 'id',
	ggrLimit          : 'ggr_limit',            // array of obj;
	minGGR            : 'min_ggr',              // array of obj;
	startDate         : 'start_date',           // Date;
	endDate           : 'end_date',             // Date;
	settlementDate    : 'settlement_date',      // Date;
	statusID          : 'status_id',            // number;
	ggrPercent        : 'ggr_percent',          // number
	casinoGameIDs     : 'casino_game_ids',      // array of numbers;
	casinoProviderIDs : 'casino_provider_ids',  // array of numbers;
	parentID          : 'parent_id',            // number;
	recurring         : 'recurring',            // boolean;
	period            : 'period',               // number;
	subCashBacks      : 'sub_cash_backs',       // number;
	wageringTurnover  : 'wagering_turnover',    // number;
	daysToExpire      : 'days_to_expire',       // number;
	walletType        : 'wallet_type',          // number;
	websiteID         : 'website_id',           // number;
	daysToExpireWallet: 'days_to_expire_wallet',
	daysToExpireBonus : 'days_to_expire_bonus',
	forced            : 'forced',
	//ggrLimit and minGGR
	currencyID        : 'currency_id',          // number;
	limit             : 'limit',                // number;
	settlementDelay   : 'settlement_delay', 		// number
	//filter
	startDateFrom     : 'start_date_from',      // Date;
	startDateTo       : 'start_date_to',        // Date;
	endDateFrom       : 'end_date_from',        // Date;
	endDateTo         : 'end_date_to',          // Date;
	settlementDateFrom: 'settlement_date_from', // Date;
	settlementDateTo  : 'settlement_date_to',   // Date;
	ggrPercentFrom    : 'ggr_percent_from',     // number;
	ggrPercentTo      : 'ggr_percent_to',       // number;
	affiliateReference: 'affiliate_reference',  // array;
	casinoGameIds     : 'casino_game_ids',  // array;
	provider          : 'provider',  // array;

	// Eligability
	allow        : 'allow',
	gameIDs      : 'game_ids',
	integratorIDs: 'integrator_ids',
	providerIDs  : 'provider_ids',
	categoryIDs  : 'category_ids',
	tagIDs       : 'tag_ids',
	count        : 'count',

	// Titles
	titles     : 'titles',
	title      : 'title',
	description: 'description',
	langID     : 'lang_id',
	
	logoURL: 'logo_url',
};

const prepareAdapter	= prepareListAdapter();
const limitAdapter		= createLimitAdapter();
const titlesAdapter		= createTitleAdapter();

export function getListParams(filter, sorting, pagination) {

	const builder = new ParamsBuilder();
	const rules = builder.RULES;

	builder.addValue('sort_by', fields[sorting.sortBy]);
	builder.addValue('sort_order', sorting.sortOrder);
	builder.addValue('page', pagination.currentPage);
	builder.addValue('limit', pagination.itemsPerPage);

	builder.addField(rules.isID, 'id', fields.id);
	builder.addField(rules.isID, 'statusID', fields.statusID);

	builder.addField(rules.isBoolean, 'recurring', fields.recurring);

	builder.addField(rules.isString, 'casinoGameIDs', fields.casinoGameIDs);
	builder.addField(rules.isString, 'casinoProviderIDs', fields.casinoProviderIDs);

	builder.addRangeField(rules.isDateTimeRange, filter.startDate, [
		fields.startDateFrom,
		fields.startDateTo,
	]);
	builder.addRangeField(rules.isDateTimeRange, filter.endDate, [
		fields.endDateFrom,
		fields.endDateTo,
	]);
	builder.addRangeField(rules.isDateTimeRange, filter.settlementDate, [
		fields.settlementDateFrom,
		fields.settlementDateTo,
	]);
	builder.addRangeField(rules.isNumberRange, filter.ggrPercent, [
		fields.ggrPercentFrom,
		fields.ggrPercentTo,
	]);

	const params = builder.biuldParams(filter);

	return params;
}

// Adapt ------------------------------------------------------------------------------------------
export function adaptList(rawData = []) {
	if (!isArray(rawData)) {
		return {};
	}

	prepareAdapter.clearExcludes();
	const adaptedData = prepareAdapter.adaptList(rawData);
	adaptedData.forEach(item => {
		item.settlementDelay = item.settlementDate && ((dayjs(item.settlementDate) - dayjs(item.endDate) ) / 3600000);
		item.titles = titlesAdapter.adaptList(item.titles);
		item.ggrLimit = limitAdapter.adaptList(item.ggrLimit);
		item.minGGR = limitAdapter.adaptList(item.minGGR);
	});
	
	return createEntities(adaptedData);
}

export function adaptSubList(rawData = []) {
	if (!isArray(rawData)) {
		return {};
	}
	rawData.forEach(data => {
		data.recurring = false;
		data.period = null;
	});
	rawData.sort((itemA, itemB) => {
		return itemA.start_date > itemB.start_date ? 1 : -1;
	});

	prepareAdapter.clearExcludes();
	const adaptedData = prepareAdapter.adaptList(rawData);
	
	adaptedData.forEach(item => {
		item.ggrLimit = limitAdapter.adaptList(item.ggrLimit);
		item.minGGR = limitAdapter.adaptList(item.minGGR);
		item.titles = titlesAdapter.adaptList(item.titles);
		if (!item.count || !Object.keys(item.count).length) {
			item.count = {
				providers : {},
				categories: {},
			};
		}
		if (item.settlementDelay) {
			item.settlementDelay = (item.settlementDelay - dayjs(item.endDate).valueOf()) / 3600000;
		}
	});

	return adaptedData;
}
export function adaptCashback(rawData = []) {
	if (!isArray(rawData) && isEmpty(rawData)) {
		return {};
	}

	prepareAdapter.clearExcludes();
	const adaptedData = prepareAdapter.adapt(rawData[0]);

	adaptedData.ggrLimit = limitAdapter.adaptList(adaptedData.ggrLimit);
	adaptedData.minGGR = limitAdapter.adaptList(adaptedData.minGGR);
	adaptedData.titles = titlesAdapter.adaptList(adaptedData.titles);

	if (!adaptedData.count || !Object.keys(adaptedData.count).length) {
		adaptedData.count = {
			providers : {},
			categories: {},
		};
	}
	
	if (adaptedData.settlementDelay) {
		adaptedData.settlementDelay = (adaptedData.settlementDelay - dayjs(adaptedData.endDate).valueOf()) / 3600000;
	}
	return adaptedData;
}


// Prepare ----------------------------------------------------------------------------------------
export function prepareBonusData(rawData = {}) {

	prepareAdapter.clearExcludes();
	prepareAdapter.addExcludeField('id');

	const walletType = toInteger(rawData.walletType);
	if (walletType === CashBackWalletTypes.REAL) {
		prepareAdapter.addExcludeField('titles');
		prepareAdapter.addExcludeField('integratorIDs');
		prepareAdapter.addExcludeField('providerIDs');
		prepareAdapter.addExcludeField('categoryIDs');
		prepareAdapter.addExcludeField('gameIDs');
		prepareAdapter.addExcludeField('tagIDs');
		prepareAdapter.addExcludeField('allow');
		prepareAdapter.addExcludeField('logoURL');
	} 

	if (rawData.subCashBacks && rawData.subCashBacks.length) {

		const preparedSubCashbacks = rawData.subCashBacks.map(sub => {
			const walletType = toInteger(sub.walletType);
			
			if (walletType === CashBackWalletTypes.REAL) {
				prepareAdapter.addExcludeField('titles');
				prepareAdapter.addExcludeField('integratorIDs');
				prepareAdapter.addExcludeField('providerIDs');
				prepareAdapter.addExcludeField('categoryIDs');
				prepareAdapter.addExcludeField('gameIDs');
				prepareAdapter.addExcludeField('tagIDs');
				prepareAdapter.addExcludeField('allow');
				prepareAdapter.addExcludeField('logoURL');
			} 
		
			sub.ggrLimit = limitAdapter.prepareList(sub.ggrLimit);
			sub.minGGR = limitAdapter.prepareList(sub.minGGR);

			const preparedSub = prepareAdapter.prepare(sub);
			if (isID(sub.id)) {
				preparedSub.id = sub.id;
			}
			const subSettlementDate = dayjs(sub.endDate).add(sub.settlementDelay || 0, 'hour');
			const subSettlementFullDate = Formatter.fullDateTime(subSettlementDate);

			preparedSub.settlement_date = Formatter.isoString(subSettlementFullDate);

			if (walletType === CashBackWalletTypes.BONUS) {
				preparedSub.titles = titlesAdapter.prepareList(preparedSub.titles);
			}
			
			return preparedSub;
		});
		rawData.subCashBacks = preparedSubCashbacks;
	}
	if (walletType === CashBackWalletTypes.BONUS) {
		rawData.titles = titlesAdapter.prepareList(rawData.titles);
	}

	rawData.ggrLimit	= limitAdapter.prepareList(rawData.ggrLimit);
	rawData.minGGR		= limitAdapter.prepareList(rawData.minGGR);
	const preparedData	= prepareAdapter.prepare(rawData);

	const x = Formatter.fullDateTime(dayjs(preparedData.end_date) + (rawData.settlementDelay * 3600000));
	preparedData.settlement_date = Formatter.isoString(x);

	if (preparedData.sub_cash_backs && preparedData.sub_cash_backs.length) {
		delete preparedData.settlement_date;
		preparedData.ggr_limit = null;
		preparedData.min_ggr = null;
		preparedData.ggr_percent = null;
	}
	if (isID(rawData.id)) {
		preparedData.id = rawData.id;
	}

	return preparedData;
}

// Adapters ---------------------------------------------------------------------------------------
export function prepareListAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.intOrNull, 'id', fields.id);
	
	adapter.addField(rules.intOrNull, 'statusID', fields.statusID);
	adapter.addField(rules.intOrNull, 'parentID', fields.parentID);
	adapter.addField(rules.intOrNull, 'wageringTurnover', fields.wageringTurnover);
	// adapter.addField(rules.intOrNull, 'daysToExpire', fields.daysToExpire);
	adapter.addField(rules.intOrNull, 'daysToExpireWallet', fields.daysToExpireWallet);
	adapter.addField(rules.intOrNull, 'daysToExpireBonus', fields.daysToExpireBonus);
	adapter.addField(rules.bool, 'forced', fields.forced);
	adapter.addField(rules.intOrNull, 'walletType', fields.walletType);
	adapter.addField(rules.intOrNull, 'websiteID', fields.websiteID);
	adapter.addField(rules.bool, 'recurring', fields.recurring);
	adapter.addField(rules.bool, 'allow', fields.allow);
	adapter.addField(rules.string, 'name', fields.name);
	adapter.addField(rules.string, 'logoURL', fields.logoURL);

	adapter.addField(rules.arrayObject, 'ggrLimit', fields.ggrLimit);
	adapter.addField(rules.arrayObject, 'minGGR', fields.minGGR);
	adapter.addField(rules.numberOrNull, 'ggrPercent', fields.ggrPercent);
	adapter.addField(rules.intOrNull, 'period', fields.period);

	adapter.addField(rules.arrayID, 'casinoProviderIDs', fields.casinoProviderIDs);
	adapter.addField(rules.arrayID, 'casinoGameIDs', fields.casinoGameIDs);
	adapter.addField(rules.arrayObject, 'subCashBacks', fields.subCashBacks);

	adapter.addField(rules.fullDate, 'startDate', fields.startDate);
	adapter.addField(rules.fullDate, 'endDate', fields.endDate);
	// adapter.addField(rules.fullDate, 'settlementDelay', fields.settlementDate);
	adapter.addField(rules.dateToMS, 'settlementDelay', fields.settlementDate);
	adapter.addField(rules.fullDate, 'endDate', fields.endDate);
	adapter.addField(rules.arrayString, 'affiliateReference', fields.affiliateReference);

	adapter.addField(rules.arrayID,		'integratorIDs', fields.integratorIDs);
	adapter.addField(rules.arrayID,		'providerIDs', fields.providerIDs);
	adapter.addField(rules.arrayID,		'categoryIDs', fields.categoryIDs);
	adapter.addField(rules.arrayID,		'tagIDs', fields.tagIDs);
	adapter.addField(rules.arrayID,		'gameIDs', fields.gameIDs);
	adapter.addField(rules.arrayObject,     'titles', fields.titles);
	adapter.addField(rules.object,     'count', fields.count);

	return adapter;
}

export function createLimitAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.intOrNull, 'currencyID', fields.currencyID);

	adapter.addField(rules.number, 'limit', fields.limit);

	return adapter;
}

export function adaptHistory(rawData) {

	const arrayFields = [
		fields.casinoGameIds,
		fields.casinoProviderIDs,
		fields.affiliateReference,
	];

	const diffOptions = {
		onlyChanged: true,
		excludeKeys: [fields.id].concat(arrayFields),
	};

	const dataList = adaptHistoryList(rawData, diffOptions, arrayFields);

	return dataList;
}

export function createTitleAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.string, 'title', fields.title);
	adapter.addField(rules.string, 'description', fields.description);
	adapter.addField(rules.id,     'langID', fields.langID);

	return adapter;
}
export const titlesEligibilityDefault = {
	titles       : [],
	integratorIDs: [],
	providerIDs  : [],
	categoryIDs  : [],
	gameIDs      : [],
	tagIDs       : [],
	
	logoURL: null,

	count: {
		providers : {},
		categories: {},
	},
};

