import { Checkbox, Collapse, Spin } from 'antd';
import { cloneDeep, isNull, isUndefined, toInteger } from 'lodash';
import PropTypes, { number } from 'prop-types';
import React, { useEffect, useState } from 'react';

import IntlMessages from '../../../../../../components/utility/intlMessages';
import { RowContainer } from '../../../../../Partner/Partners/Components/modal/TabIntegrator/Components/Provider.style';

import { CheckProvider } from '../Components/CheckProvider';
import { Label } from '../Components/components';
import { lang as language } from '../lang';

import GamesList from '../Components/GamesList';
import { bulkCheckGames } from './utils';
import { LoadingOutlined } from '@ant-design/icons';


const { Panel } = Collapse;
const lang = {
	noProvider: <IntlMessages id = "bonuses.eligibility.integrators.noProvider" />,
	noGame    : <IntlMessages id = "bonuses.eligibility.integrators.noGame" />,
};

export default function CreateGamesList(props) {

	const {
		integratorID,
		providerCount,
		providers,
		bonusData,
		dataRefresh,
		uiRefresh,
		listReloadGames,
		gamesListbyIntegratorWithProvider,
		categoryGameCheck,
		checkedProvidersByIntegrator,
		providersRefresh,
		categoryGameChecked,
		integratorChecked,
		deletedIntegrator,
		bonusDataLading,
		fromLoyaltySystemBonusTab,
		readOnly,
		singleGameMode,
	} = props;
	const { gameIDs, games, currencyID } = bonusData;
	const [selectCurrent, setSelectCurrent] = useState({ [number]: false });
	const [changedIntegratorCheckbox, setChangedIntegratorCheckbox] = useState(false);

	if (fromLoyaltySystemBonusTab) {
		useEffect(() => {
			if (bonusDataLading) {
				const clonedData = cloneDeep(bonusData);

				if (!gameIDs?.length) {
					clonedData.games = [];
				} else {
					clonedData.games = clonedData.games.map(game => ({ ...game, isChanged: false }));
				}

				dataRefresh(clonedData);
				setChangedIntegratorCheckbox(false);
			}
			// eslint-disable-next-line
		}, [bonusDataLading]);

		useEffect(() => {
			const clonedData = cloneDeep(bonusData);

			if (!clonedData.games?.length && clonedData.gameIDs?.length > 0) {
				clonedData.games = clonedData.gameIDs.map(id => ({ id, isChanged: false }));
				dataRefresh(clonedData);
			}
		}, [bonusData, dataRefresh, gameIDs]);
	}

	const onCheck = (checked, id, providerID, dataCount, categoryID, gameName) => {
		const clonedData = cloneDeep(bonusData);
		if (fromLoyaltySystemBonusTab && gameIDs.length > 0) {
			clonedData.games = clonedData.games?.map(game => {
				if (game.id === id) {
					game.isChanged = true;
				}

				return game;
			});
		}

		if (checked) {
			if (!singleGameMode) {
				clonedData.gameIDs.push(id);
				clonedData.count.providers[providerID] = toInteger(clonedData.count.providers[providerID]);
				clonedData.count.providers[providerID]++;
			} else {
				clonedData.gameName = gameName;
				clonedData.casinoGameID = id;
			}

		} else {
			if (!singleGameMode) {
				clonedData.gameIDs = gameIDs.filter(item => item !== id);
				clonedData.count.providers[providerID] = toInteger(clonedData.count.providers[providerID]);
				clonedData.count.providers[providerID]--;
			} else {
				clonedData.gameName = null;
				clonedData.casinoGameID = null;
			}

		}

		const currentGameCount =  !singleGameMode && clonedData.count.providers[providerID];
		setSelectCurrent(prev => ({
			...prev,
			[providerID]: dataCount === currentGameCount,
		}));

		let categoryGames = categoryGameChecked[categoryID] ? categoryGameChecked[categoryID].games : [];

		if (checked) {
			categoryGames.push(id);
		} else {
			categoryGames = categoryGames.filter(item => item.id !== id);
		}

		let categoryData = {};

		if (categoryID) {
			categoryData = {
				[categoryID]: {
					allChecked: false, // TODO change to dynamic value.
					games     : categoryGames,
				},
			};
		}
		dataRefresh(clonedData, gameName);
		uiRefresh({ isChanged: true });
		categoryGameCheck({ ...categoryGameChecked, ...categoryData });
	};

	const onSelectCurrent = (checked, games, providerID) => {
		setSelectCurrent(prev => ({
			...prev,
			[providerID]: checked,
		}));

		const clonedData = cloneDeep(bonusData);
		const providerGameIds = games.map(game => game.id);

		if (checked) {
			clonedData.gameIDs = clonedData.gameIDs.concat(providerGameIds);
			!singleGameMode && (clonedData.count.providers[providerID] = games.length);
		} else {
			clonedData.gameIDs = clonedData.gameIDs.filter(gameId => !providerGameIds.includes(gameId));
			!singleGameMode && (clonedData.count.providers[providerID] = 0);
		}

		clonedData.gameIDs = [...new Set(clonedData.gameIDs)];

		const categoryData = bulkCheckGames(categoryGameChecked, games, checked);
		categoryGameCheck({ ...categoryGameChecked, ...categoryData });
		dataRefresh(clonedData);
	};

	const onCheckProvider = (providerID, checkedProviders, checked) => {
		setChangedIntegratorCheckbox(true);

		const providersCheckData = { [integratorID]: {
			...checkedProviders,
			[providerID]: checked,
		} };

		const clonedDAta = cloneDeep(bonusData);

		if (checked) {
			clonedDAta.providerIDs.push(providerID);
		} else {
			clonedDAta.providerIDs = clonedDAta.providerIDs.filter(item => item !== providerID);
		}

		dataRefresh(clonedDAta);
		uiRefresh({ isChanged: true });
		providersRefresh(providersCheckData);
	};

	const renderRow = (providerID, checkedProvider, { props: rowProps, list }) => {
		const { index, key, style, disabled } = rowProps;
		const { id, name, categoryID } = list[index];
		const dataCount = list.length;
		const checked = gameIDs?.includes(id);
		const game = games?.find(game => game.id === id);
		const withCheckbox = !checkedProvider && !integratorChecked;
		return (
			<Label style={style} key={key}>
				{withCheckbox && (
					<Checkbox
						value={id}
						onChange={(e) => onCheck(e.target.checked, id, providerID, dataCount, categoryID, name)}
						checked={checked}
						disabled={disabled || (deletedIntegrator && !checked && !game?.isChanged && fromLoyaltySystemBonusTab)}
					/>
				)}
				<span>{name}</span>
			</Label>
		);
	};

	const renderCheckAll = (games, providerID, checkedProviders, bonusGameIDs) => {
		const disabled = checkedProviders[providerID];
		const checked = games?.every(item => bonusGameIDs?.includes(item.id));
		return (
			<Checkbox
				onChange={e => onSelectCurrent(e.target.checked, games, providerID)}
				checked={checked}
				disabled={readOnly || disabled || (deletedIntegrator && !selectCurrent && fromLoyaltySystemBonusTab)}
			/>
		);
	};

	if (providers.length > 0) {
		return (
			<Collapse
				onChange={(key) => {
					key.forEach( item => {
						if (isUndefined(gamesListbyIntegratorWithProvider[item])) {
							listReloadGames({ providerID: Number(item), allActive: true, currencyID });
						}
					});
				}}
			>
				{providers.map( provider => {
					const found = gamesListbyIntegratorWithProvider[provider.id];
					const providerGamesCount = !singleGameMode && providerCount[provider.id];
					const checkedProviders = checkedProvidersByIntegrator[integratorID] ? checkedProvidersByIntegrator[integratorID] : {};
					const providerChecked = bonusData.providerIDs.includes(provider.id);

					if (found && !isNull(found?.games)) {
						return (
							<Panel
								key={provider.id}
								header={provider.name}
								extra={!singleGameMode && !integratorChecked && (
									<RowContainer>
										{providerChecked ? language.selectedProviders :
											<span>{language.selected} {providerGamesCount || 0}</span>}
										<CheckProvider
											checked={providerChecked}
											onChange={(...args) => onCheckProvider(provider.id, checkedProviders, ...args)}
											disabled={readOnly || (deletedIntegrator && fromLoyaltySystemBonusTab && !providerChecked && !changedIntegratorCheckbox)}
										/>
									</RowContainer>
								)}
							>
								<GamesList
									disabled={readOnly} // TODO NOT full implementation (deletedIntegrator && fromLoyaltySystemBonusTab && !providerChecked && !changedIntegratorCheckbox)
									checkAllInto={readOnly} // TODO NOT true implementation
									data={found}
									bonusData={bonusData}
									dataRefresh={dataRefresh}
									uiRefresh={uiRefresh}
									sourceID={found.providerID}
									renderRow={(...args) => renderRow(found.providerID, providerChecked, ...args)}
									checkAll={renderCheckAll(found.games, found.providerID, checkedProviders, bonusData.gameIDs)}
									deletedIntegrator={deletedIntegrator}
									fromLoyaltySystemBonusTab={fromLoyaltySystemBonusTab}
									singleGameMode={singleGameMode}
								/>
							</Panel>
						);
					} if (!isUndefined(found) && isNull(found?.games) && !found?.loading) {
						return (
							<Panel
								key={provider.id}
								header={provider.name}
								extra = {!singleGameMode && !integratorChecked && (
									<RowContainer>
										{providerChecked ? language.selectedProviders : <>{language.selected}{providerGamesCount || 0} </>}
										<CheckProvider
											checked={providerChecked}
											onChange={(...args) => onCheckProvider(provider.id, checkedProviders, ...args)}
											disabled={readOnly || (deletedIntegrator && !providerChecked && fromLoyaltySystemBonusTab)}
										/>
									</RowContainer>
								)}
							>
								<div>{lang.noGame}</div>
							</Panel>
						);
					}

					return (
						<Panel
							key={provider.id}
							header={provider.name}
							extra = {!singleGameMode && !integratorChecked && (
								<RowContainer>
									{providerChecked ? language.selectedProviders : <>{language.selected}{providerGamesCount || 0} </>}
									<CheckProvider
										checked={providerChecked}
										onChange={(...args) => onCheckProvider(provider.id, checkedProviders, ...args)}
										disabled={readOnly || (deletedIntegrator && !providerChecked && fromLoyaltySystemBonusTab)} // TODO separate check for disabled 'fromLoyaltySystemBonusTab'
									/>
								</RowContainer>
							)}
						>
							<span style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
								<Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
							</span>
						</Panel>
					);

				} )}
			</Collapse>
		);
	}
	return lang.noProvider;
}

CreateGamesList.propTypes = {
	providers                        : PropTypes.array.isRequired,
	bonusData                        : PropTypes.object.isRequired,
	dataRefresh                      : PropTypes.func.isRequired,
	providersRefresh                 : PropTypes.func.isRequired,
	uiRefresh                        : PropTypes.func.isRequired,
	listReloadGames                  : PropTypes.func.isRequired,
	categoryGameCheck                : PropTypes.func.isRequired,
	integratorID                     : PropTypes.number.isRequired,
	providerCount                    : PropTypes.number.isRequired,
	checkedProvidersByIntegrator     : PropTypes.object.isRequired,
	gamesListbyIntegratorWithProvider: PropTypes.object.isRequired,
	categoryGameChecked              : PropTypes.shape(
		{ games: PropTypes.array.isRequired }
	).isRequired,
	integratorChecked        : PropTypes.bool.isRequired,
	deletedIntegrator        : PropTypes.bool.isRequired,
	bonusDataLading          : PropTypes.bool.isRequired,
	fromLoyaltySystemBonusTab: PropTypes.bool.isRequired,
};
