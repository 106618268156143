import QueryToFilterValidaion from '../../../../helpers/queryToFilterValidaion';
import { ParticipantType } from '@ceo-betmakers/common-globals';

const { isValidID, isValidString, isValidDateRange } = QueryToFilterValidaion;
export const initFilterTypes = {
	// primary
	typeID      : isValidID,
	sportID     : isValidID, // Number
	countryID   : isValidID, // Number
	leagueID    : isValidID, // Number
	teamName    : isValidString, // String
	name        : isValidString, // String
	surname     : isValidString, // String
	genderID    : isValidID, // Number
	ageID       : isValidID, // Number
	verifiedID  : isValidID, // Number
	// advanced
	altTeamName1: isValidString, // String
	altTeamName2: isValidString, // String
	altName     : isValidString, // String
	altSurname  : isValidString, // String
	roleID      : isValidID, // Number
	birthday    : isValidDateRange, // Date
};

export const defaultValuesParticipantsFilter = {
	typeID      : ParticipantType.TEAM,
	sportID     : null, // Number
	countryID   : null, // Number
	leagueID    : null, // Number
	teamName    : null, // String
	name        : null, // String
	surname     : null, // String
	genderID    : null, // Number
	ageID       : null, // Number
	verifiedID  : null, // Number
	// advanced
	altTeamName1: null, // String
	altTeamName2: null, // String
	altName     : null, // String
	altSurname  : null, // String
	roleID      : null, // Number
	birthday    : null, // Date
};