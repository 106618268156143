import { PlatformAdminRoles } from '@ceo-betmakers/common-globals';

export const ADMIN_GROUP_OPTIONS = [
	{
		id  : PlatformAdminRoles.SUPER_ADMIN,
		name: 'Super Administrator',
	},
	{
		id  : PlatformAdminRoles.SYSTEM_OPERATOR,
		name: 'System Operator',
	},

];


export const showRaw = (adminType) => {
	return adminType !== String(PlatformAdminRoles.SUPER_ADMIN);
};


export const normalizePartnerWebsiteList = (data = []) => {

	const partnerPrefix = 'partner';
	const websitePrefix = 'website';

	return data.map(item => {
		const partner = {
			title   : item.partnerName,
			key     : `${partnerPrefix}_${item.id}`,
			value   : `${partnerPrefix}_${item.id}`,
			children: [],
		};

		if (item.websites.length) {
			partner.children = item.websites.map(website => ({
				title: website.name,
				key  : `${websitePrefix}_${website.id}`,
				value: `${websitePrefix}_${website.id}`,
			}));
		}


		return partner;
	});
};

export const normalizeWebsiteList = (data = []) => {

	const websitePrefix = 'website_';

	return data.map(item => {
		return {
			id   : item.id,
			title: item.name,
			key  : item.id,
			value: `${websitePrefix}${item.id}`,
		};
	});
};


export const allowSelectAdminType = (loggedInAdmin) => {
	return Number(loggedInAdmin) === PlatformAdminRoles.PLATFORM_ADMIN;
};


export const defaultRoles = {
	4: 'user',
	5: 'admin',
	6: 'super_admin',
	7: 'system_operator',
	8: 'partner',
};

export const rolesEnum = {
	user          : 4,
	admin         : 5,
	superAdmin    : 6,
	systemOperator: 7,
	partner       : 8,
};


export const roleIDs = {
	user          : rolesEnum.user,
	admin         : rolesEnum.admin,
	superAdmin    : rolesEnum.superAdmin,
	systemOperator: rolesEnum.systemOperator,
	partner       : rolesEnum.partner,
};


export const defaultRolesList = {
	user: {
		id       : roleIDs.user,
		name     : defaultRoles[roleIDs.user],
		canChange: false,
	},
	admin: {
		id       : roleIDs.admin,
		name     : defaultRoles[roleIDs.admin],
		canChange: false,
	},
	super_admin: {
		id       : roleIDs.superAdmin,
		name     : defaultRoles[roleIDs.superAdmin],
		canChange: false,
	},
	system_operator: {
		id       : roleIDs.systemOperator,
		name     : defaultRoles[roleIDs.systemOperator],
		canChange: false,
	},
	partner: {
		id       : roleIDs.partner,
		name     : defaultRoles[roleIDs.partner],
		canChange: false,
	},

};

export const checkRole = (roleIDs) => {
	return roleIDs.map(item => defaultRolesList[defaultRoles[item]]);
};

export const isPartnerAdmin = (adminType) => {
	return Number(adminType) >= PlatformAdminRoles.PARTNER_ADMIN;
};