import { EBonusTypes } from '../containers/LoyaltySystem/BonusingSystem/Bonuses/BonusDetails/types';
import { BonusStatus, BonusType } from '@ceo-betmakers/common-globals';

export const BONUS_TYPES = {
	all                 : 0,
	depositBonus        : BonusType.DEPOSIT_BONUS,
	sportsBookBonus     : BonusType.SPORTSBOOK_BONUS,
	welcome             : BonusType.WELCOME,
	deposit             : BonusType.DEPOSIT,
	freeSpin            : BonusType.FREE_SPIN,
	birthday            : BonusType.BIRTHDAY,
	bonusSpin           : BonusType.BONUS_SPIN,
	registrationFreeSpin: BonusType.REGISTRATION_FREE_SPIN,
	balanceAdjustment   : BonusType.BALANCE_ADJUSTMENT,
	cashback            : BonusType.CASHBACK,
	freeBet             : BonusType.FREEBET,
	depositFreeSpin     : BonusType.DEPOSIT_FREE_SPIN,
	pack                : BonusType.PACK,
	nextDepositFreeSpin : BonusType.NEXT_DEPOSIT_FREE_SPIN,
	nextDepositBonus    : BonusType.NEXT_DEPOSIT_BONUS,
	packNext            : BonusType.PACK_NEXT,
	manualBonus         : BonusType.MANUAL_BONUS,
	massFreeSpin        : BonusType.MASS_FREE_SPIN,
};
export const FORCED = {
	all      : -1,
	notForced: 0,
	forced   : 1,
};

export const BONUS_TYPE_NAMES = {
	[BONUS_TYPES.depositBonus]        : 'bonus.depositBonus',
	[BONUS_TYPES.sportsBookBonus]     : 'bonus.sportsbookBonus',
	[BONUS_TYPES.welcome]             : 'bonus.welcome',
	[BONUS_TYPES.deposit]             : 'bonus.firstDeposit',
	[BONUS_TYPES.freeSpin]            : 'bonus.freeSpin',
	[BONUS_TYPES.birthday]            : 'bonus.birthday',
	[BONUS_TYPES.bonusSpin]           : 'bonus.bonusSpin',
	[BONUS_TYPES.registrationFreeSpin]: 'bonus.registrationFreeSpin',
	[BONUS_TYPES.balanceAdjustment]   : 'bonus.balanceAdjustment',
	[BONUS_TYPES.cashback]            : 'bonus.cashback',
	[BONUS_TYPES.freeBet]             : 'bonus.freeBet',
	[BONUS_TYPES.depositFreeSpin]     : 'bonus.depositFreeSpin',
	[BONUS_TYPES.pack]                : 'bonus.pack',
	[BONUS_TYPES.nextDepositFreeSpin] : 'bonus.nextDepositFreeSpin',
	[BONUS_TYPES.nextDepositBonus]    : 'bonus.nextDepositBonus',
	[BONUS_TYPES.packNext]            : 'bonus.packNext',
	[BONUS_TYPES.manualBonus]         : 'bonus.manual',
	[BONUS_TYPES.massFreeSpin]        : 'bonus.massFreeSpin',
};
export const BONUS_TYPE_ROUTER_NAMES = {
	[BONUS_TYPES.registrationFreeSpin]: 'registration_freeSpin',
	[BONUS_TYPES.nextDepositFreeSpin] : 'next_deposit_freeSpin',
	[BONUS_TYPES.depositFreeSpin]     : 'deposit_freeSpin',
	[BONUS_TYPES.deposit]             : 'deposit_bonus',
	[BONUS_TYPES.nextDepositBonus]    : 'next_deposit_bonus',
	[BONUS_TYPES.pack]                : 'pack',
	[BONUS_TYPES.welcome]             : 'welcome',
};
export const BONUS_TYPE_ROUTER_TO_TYPE = {
	[EBonusTypes.REGISTRATION_FREESPIN]: BONUS_TYPES.registrationFreeSpin,
	[EBonusTypes.NEXT_DEPOSIT_FREESPIN]: BONUS_TYPES.nextDepositFreeSpin,
	[EBonusTypes.DEPOSIT_FREESPIN]     : BONUS_TYPES.depositFreeSpin,
	[EBonusTypes.DEPOSIT_BONUS]        : BONUS_TYPES.deposit,
	[EBonusTypes.NEXT_DEPOSIT_BONUS]   : BONUS_TYPES.nextDepositBonus,
	[EBonusTypes.PACK]                 : BONUS_TYPES.pack,
	[EBonusTypes.NEXT_PACK]            : BONUS_TYPES.packNext,
	[EBonusTypes.WELCOME]              : BONUS_TYPES.welcome,
	[BONUS_TYPES.registrationFreeSpin] : EBonusTypes.REGISTRATION_FREESPIN,
	[BONUS_TYPES.nextDepositFreeSpin]  : EBonusTypes.NEXT_DEPOSIT_FREESPIN,
	[BONUS_TYPES.depositFreeSpin]      : EBonusTypes.DEPOSIT_FREESPIN,
	[BONUS_TYPES.deposit]              : EBonusTypes.DEPOSIT_BONUS,
	[BONUS_TYPES.nextDepositBonus]     : EBonusTypes.NEXT_DEPOSIT_BONUS,
	[BONUS_TYPES.pack]                 : EBonusTypes.PACK,
	[BONUS_TYPES.packNext]             : EBonusTypes.NEXT_PACK,
	[BONUS_TYPES.welcome]              : EBonusTypes.WELCOME,
};

export const BONUS_STATUS = {
	active  : 1,
	inactive: 2,
};

export const BONUS_STATUS_NAMES = {
	[BonusStatus.ACTIVE]   : 'bonus.status.active',
	[BonusStatus.INACTIVE] : 'bonus.status.inactive',
	[BonusStatus.GRANTED]  : 'bonus.status.granted',
	[BonusStatus.CLAIMED]  : 'bonus.status.claimed',
	[BonusStatus.CONVERTED]: 'bonus.status.converted',
	[BonusStatus.FINISHED] : 'bonus.status.finished',
	[BonusStatus.EXPIRED]  : 'bonus.status.expired',
	[BonusStatus.DECLINED] : 'bonus.status.declined',
	[BonusStatus.CANCELED] : 'bonus.status.canceled',
};

export const CASHBACK_STATUSES = {
	ACTIVE     : 1,
	DEACTIVATED: 2,
	FINISHED   : 5,
};

export const CASHBACK_STATUS_NAMES = {
	[CASHBACK_STATUSES.ACTIVE]     : 'cashback.status.active',
	[CASHBACK_STATUSES.DEACTIVATED]: 'cashback.status.deactivated',
	[CASHBACK_STATUSES.FINISHED]   : 'cashback.status.finished',
};

export const SETTLEMENT_STATUSES = {
	SETTLED  : 1,
	UNSETTLED: 2,
	REJECTED : 3,
};

export const adjustmentTypes = {
	BALANCE : 1,
	DEPOSIT : 2,
	WITHDRAW: 3,
};
