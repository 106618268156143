import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import cloneDeep from 'lodash/cloneDeep';

import actions from './actions';
import { showError, showSuccess } from '../../../../helpers/notifications';
import { bonusesAPI } from '../../../../helpers/api/bonuses';
import { casinoAPI } from '../../../../helpers/api/casino';
import { BONUS_TYPES } from '../../../../constants/bonuses';
import {
	adaptBets,
	prepareFreeSpin,
	prepareFreeBet,
	adaptFotSend,
	prepareManualBonus,
	BONUS_TABS,
	adaptLineAndDenomination,
	adaptBonusMoney,
	adaptFreeSpins,
	getUserBonusTemplateParams,
	adaptTemplatesList,
	getUserBonusFreeSpinSaveTemplatePrepare,
} from './utils';
import { getListParams } from '../../../users/list/utils';
import balanceActions from '../../../users/userBalance/balanceAdjustment/actions';
import bonusesActions from '../bonuses/actions';

import userCasinoFreeSpinsActions from '../userCasinoFreeSpins/actions';
import userCasinoBonusesActions from '../userCasinoBonuses/actions';
import { logger } from '../../../../helpers/logger';
import { LANGUAGES, USER_INFO_VIEW_TABS } from '../../../../helpers/commonConstants';
import { EBonusTemplateStatus, EBonusTemplateTypeIDs } from '../../templates/types';
import { ErrorCodes } from '@ceo-betmakers/common-globals';

const messages = {
	errorDataSave                       : 'bonus.data.save.fail',
	errorGameLimitLoad                  : 'game.bet.limits.load.fail',
	successDataSave                     : 'bonus.data.save.success',
	successManualFreeSpinSaveTemplate   : 'userBonus.chooseMode.freeSpinSaveSuccess',
	errorManualFreeSpinSaveTemplate     : 'userBonus.chooseMode.freeSpinSaveError',
	errorLimitManualFreeSpinSaveTemplate: 'userBonus.chooseMode.freeSpinSaveErrorLimit',
	errorGetByID                        : 'bonuses.bonus.item.load.failed',

};

function getStoreData(state) {
	const { Loyalty: { Bonuses }, Users, App, Casino, Partner: { Websites } } = state;


	const { UserBonus, UserFreeBets, UserMassBonus } = Bonuses;
	const { User } = Users;

	const providerIDs = Bonuses.UserBonus.get('checkedProvidersByIntegrator');
	const { spinCount: withTemplateSpinsTotal, noteForAdmin } = Bonuses.UserBonus.get('userBonusTemplatesData');
	const integratorsList = Casino.Integrators.get('entities');
	const UI = UserBonus.get('UI');

	const websiteID		= App.get('websiteID');
	const websiteData	= Websites.List.get('entities')[+websiteID];
	const langID = websiteData ? websiteData.langID : LANGUAGES.en;
	return {
		bonusData  : cloneDeep(UserBonus.get('bonusData')),
		balanceData: cloneDeep(Users.Adjustment.UserBalance.get('balanceData')),

		baseData                     : User.get('baseData'),
		innerMainCurrentTab          : User.get('UI').currentTab,
		innerBonusCuttentTab         : UI.currentTab,
		bonusTypeID                  : UI.bonusTypeID,
		betID                        : UI.betID,
		denominationKey              : UI.denominationKey,
		denominationValue            : UI.denominationValue,
		massBonus                    : UI.massBonus,
		showLineAndDenominationInputs: UI.showLineAndDenominationInputs,
		selectedTemplateID           : UI.selectedTemplateID,

		bets           : UserBonus.get('bets'),
		filter         : UserMassBonus.UserMassBonusFilter.get('filter'),
		freeBets       : UserFreeBets.get('entities'),
		casinoCamesList: Casino.Games.get('gamesList'),
		websiteID,
		langID,

		providerIDs,
		integratorsList,
		withTemplateSpinsTotal,
		noteForAdmin,
	};
}

function* gameLimitsReload() {

	yield takeEvery(actions.GAME_LIMITS_RELOAD, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));

		const { casinoGameID, userID, currencyCode = null, isMassBonus = null } = action.data;

		let params = { user_id: userID };
		if (currencyCode) {
			params = { currency_code: currencyCode };
		} else {
			params = { user_id: userID };
		}
		let bets = [];
		let denominations = {};

		try {
			const res = yield call(casinoAPI.gameBetLimits, casinoGameID, params);
			if (res && res.status === 200) {
				const { data } = res.data;
				bets = adaptBets(data.bets);
				const lineAndDenominationReq = adaptLineAndDenomination(data);

				denominations = data?.denominations;
				const isBetsOrDenominations = Boolean(data.denominations || data.bets);
				yield put(actions.uiRefresh({ showLineAndDenominationInputs: !isBetsOrDenominations }));
				if (!isBetsOrDenominations) {
					yield put(actions.uiRefresh({ showLineAndDenominationCheckbox: !(lineAndDenominationReq.requiredBetPerLine || lineAndDenominationReq.requiredLines) }));
				}

				yield put(actions.denominationsAndLineRefresh(lineAndDenominationReq));
			}

		} catch (error) {
			showError(messages.errorGameLimitLoad, error);
		}

		yield put(actions.betsRefresh(bets));
		yield put(actions.denominationsRefresh(denominations));
		if (!isMassBonus) {

			yield put(actions.uiRefresh({
				betID            : null,
				denominationKey  : null,
				denominationValue: null,
				loading          : false,
			}));
		} else {
			yield put(actions.uiRefresh({ loading: false }));
		}
	});
}

function* dataSave() {

	yield takeEvery(actions.DATA_SAVE, function* ({ data }) {

		yield put(actions.uiRefresh({ loading: true }));

		const {
			bonusData,
			baseData,
			bonusTypeID,
			bets,
			betID,
			denominationKey,
			denominationValue,
			massBonus,
			balanceData,
			showLineAndDenominationInputs,
		} = yield select(getStoreData);
		bonusData.bonusTypeID = bonusTypeID;
		bonusData.currencyID = baseData.currencyID;
		let success = false;

		switch (bonusTypeID) {
			case BONUS_TYPES.freeSpin: {
				const limits = {
					bets,
					betID,
					denominationKey,
					denominationValue,
				};
				success = yield call(makeBonusFreeSpin, bonusData, limits, data.isMassBonusPath, showLineAndDenominationInputs);
				break;
			}
			case BONUS_TYPES.freeBet: {
				success = yield call(makeBonusFreeBet, bonusData);
				break;
			}
			case BONUS_TYPES.balanceAdjustment: {
				const sendData = Object.assign(bonusData, balanceData);
				success = yield call(makeBonusManualBonus, sendData);
				break;
			}
			default: {
				logger.log('Unknown bonus type: ', bonusTypeID);
			}
		}

		if (success) {
			if (massBonus && data.isMassBonusPath) {
				yield put(actions.cancelUserBonusData());
				yield put(actions.uiRefresh({ saveSuccess: true }));
			} else {
				yield put(actions.dataReset());
			}
		} else {
			yield put(actions.uiRefresh({ loading: false }));
		}
	});
}

function* makeBonusFreeSpin(bonusData, limits, isMassBonusPath, showLineAndDenominationInputs) {

	const { userID } = bonusData;
	const {
		filter,
		massBonus,
		websiteID,
		providerIDs,
		integratorsList,
		innerMainCurrentTab,
		innerBonusCuttentTab,
	} = yield select(getStoreData);
	const preparedData = yield call(prepareFreeSpin, bonusData, limits, showLineAndDenominationInputs);
	let res;

	const { providerIDsToArray, integratorIDs } = adaptFotSend(providerIDs, integratorsList);
	const massBonusType = isMassBonusPath && massBonus;
	try {
		if (massBonusType) {
			const userFilter = getListParams({ ...filter, affiliateReference: bonusData.affiliateReference }); // fix affiliate reference to get from filter
			preparedData.website_id = websiteID;
			userFilter.currency_ids = filter.currency;
			userFilter.website_id = websiteID;
			delete userFilter.currency;

			if (userFilter.affiliate_reference) {
				userFilter.affiliate_reference = [...userFilter.affiliate_reference]; // fix affiliate reference to get from filter
			}
			if (integratorIDs) {
				preparedData.integrator_ids = integratorIDs;
			}
			if (providerIDsToArray) {
				preparedData.provider_ids = providerIDsToArray;
			}
			delete preparedData.comment;
			const data = {
				data  : preparedData,
				filter: userFilter,
			};
			res = yield call(bonusesAPI.massFreeSpinCreate, data);
		} else {
			res = yield call(bonusesAPI.freeSpinCreate, userID, preparedData);
		}
		if (res && res.status === 200) {
			showSuccess(messages.successDataSave);
			if (!massBonusType) {
				if ((innerMainCurrentTab === USER_INFO_VIEW_TABS.bonus) && (innerBonusCuttentTab === BONUS_TABS.freeSpins)) {
					yield put(userCasinoFreeSpinsActions.listReload(userID));
				}
			}
			return true;
		}

	} catch (error) {
		showError(messages.errorDataSave, error);
	}

	return false;
}

function* makeBonusFreeBet(bonusData) {
	const { userID } = bonusData;
	const preparedData = yield call(prepareFreeBet, bonusData);
	try {
		const res = yield call(bonusesAPI.freeBetCreate, userID, preparedData);
		if (res && res.status === 200) {
			showSuccess(messages.successDataSave);
			// yield put(freeBetsActions.listRefresh({ data: [...freeBets, res.data.data] }));
			return true;
		}
	} catch (error) {
		showError(messages.errorDataSave, error);
	}

	return false;
}

function* makeBonusManualBonus(bonusData) {
	const { userID } = bonusData;
	const preparedData = yield call(prepareManualBonus, bonusData);
	const { innerMainCurrentTab, innerBonusCuttentTab } = yield select(getStoreData);

	try {
		const res = yield call(bonusesAPI.manualBonusCreate, userID, preparedData);
		if (res && res.status === 200) {
			showSuccess(messages.successDataSave);
			yield put(balanceActions.dataReset());
			if ((innerMainCurrentTab === USER_INFO_VIEW_TABS.bonus) && (innerBonusCuttentTab === BONUS_TABS.bonuses)) {
				yield put(userCasinoBonusesActions.listReload(userID));
			}

			return true;
		}
	} catch (error) {
		showError(messages.errorDataSave, error);
	}

	return false;
}

function* getByID() {
	yield takeEvery(actions.GET_BY_ID, function* ({ data }) {
		const { casinoCamesList, websiteID, langID } = yield select(getStoreData);
		const { userID, bonusID, typeID } = data;
		try {
			switch (typeID) {
				case BONUS_TYPES.balanceAdjustment: {
					const res = yield call(bonusesAPI.manualBonusGetByID, userID, bonusID);
					if (res && res.status === 200) {
						const adaptedData = adaptBonusMoney(res.data.data);

						yield put(balanceActions.dataRefresh(adaptedData));
					}
					break;
				}

				case BONUS_TYPES.freeSpin: {
					const res = yield call(bonusesAPI.freeSpinsGetByID, userID, bonusID);
					if (res && res.status === 200) {
						const { casino_game_id, bet_id, denomination, denomination_value } = res.data.data;
						yield put(actions.gameLimitsReload(casino_game_id, userID, null, true));
						if (!casinoCamesList.length) {
							yield put(bonusesActions.gameEntityReload(casino_game_id, null, websiteID, langID, true));
						}
						const adaptedData = adaptFreeSpins(res.data.data);
						yield put(actions.uiRefresh({
							betID            : bet_id,
							denominationKey  : denomination,
							denominationValue: denomination_value,
						}));
						yield put(actions.dataRefresh(adaptedData));
					}
					break;
				}
				default:
					break;
			}

		} catch (error) {
			showError(messages.errorGetByID, error);
		}
	});
}
function* cancelUserBonusData() {
	yield takeEvery(actions.CANCEL_USER_BONUS_DATA, function* () {
		yield put(actions.bonusDataReset());
		yield put(actions.betsRefresh([]));
		yield put(actions.denominationsRefresh({}));
		yield put(actions.uiRefresh({
			bonusTypeID      : 0,
			visible          : false,
			loading          : false,
			isChanged        : false,
			betID            : null,
			denominationKey  : null,
			denominationValue: null,
			massBonus        : true,
			viewMode         : false,
			withTemplates    : false,
		}));
	});
}

function* templateListUserBonusData() {
	yield takeEvery(actions.TEMPLATE_DATA_RELOAD, function* () {
		yield put(actions.uiRefresh({ loading: true }));
		const { baseData, bonusTypeID } = yield select(getStoreData);
		const { userCountryID, currencyID } = baseData;

		const templateType = bonusTypeID === BONUS_TYPES.freeSpin ? EBonusTemplateTypeIDs.MANUAL_FREE_SPINS : EBonusTemplateTypeIDs.MANUAL_BONUS_MONEY;

		const dataParams = {
			templateType,
			statusIDs: [EBonusTemplateStatus.ACTIVE],
			countryId: userCountryID,
			currencyID,
		};
		const params = getUserBonusTemplateParams(dataParams);

		try {
			const res = yield call(bonusesAPI.userBonusTemplate, params);
			if (res.status === 200) {
				const { data } = res.data;
				const adaptedData = adaptTemplatesList(data);
				yield put(actions.userBonusTemplatesDataList(adaptedData));
				yield put(actions.uiRefresh({ selectedTemplateID: adaptedData[0]?.id }));
			}
		} catch (e) {
			logger.log(e);
		} finally {
			yield put(actions.uiRefresh({ loading: false }));
		}
	});
}

function* userBonusFreeSpinSaveWithTemplate() {
	yield takeEvery(actions.FREE_SPIN_SAVE_WITH_TEMPLATE, function* () {
		yield put(actions.uiRefresh({ loading: true }));
		const { baseData, selectedTemplateID, withTemplateSpinsTotal, noteForAdmin, innerMainCurrentTab, innerBonusCuttentTab } = yield select(getStoreData);
		const { id: userID } = baseData;

		const dataReq = {
			templateID: selectedTemplateID,
			spinsTotal: withTemplateSpinsTotal,
			noteForAdmin,
		};

		const dataBody = getUserBonusFreeSpinSaveTemplatePrepare(dataReq);

		try {
			const res = yield call(bonusesAPI.userBonusFreeSpinWithTemplateSave, userID, dataBody);
			if (res.status === 200) {
				yield put(actions.uiRefresh({ visible: false }));
				if ((innerMainCurrentTab === USER_INFO_VIEW_TABS.bonus) && (innerBonusCuttentTab === BONUS_TABS.freeSpins)) {
					yield put(userCasinoFreeSpinsActions.listReload(userID));
				}
				showSuccess(messages.successManualFreeSpinSaveTemplate);
				yield put(actions.templateDataReset());
			}
		} catch (e) {
			logger.log(e);
			if (e.response.data.text_code === ErrorCodes.TOTAL_SPINS_COUNT_LIMIT) {
				showError(messages.errorLimitManualFreeSpinSaveTemplate);
			} else {
				showError(messages.errorManualFreeSpinSaveTemplate, e);
			}
		} finally {
			yield put(actions.uiRefresh({ loading: false }));
		}
	});
}

export default function* userBonusSaga() {
	yield all([
		fork(dataSave),
		fork(gameLimitsReload),
		fork(cancelUserBonusData),
		fork(getByID),
		fork(templateListUserBonusData),
		fork(userBonusFreeSpinSaveWithTemplate),
	]);
}
