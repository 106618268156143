import { DashboardIcon } from './dashboard';
import { RtmIcon } from './rtm';
import { StaffIcon } from './staff';
import { PartnersIcon } from './partners';
import { CustomerIcon } from './customers';
import { BonusIcon } from './bonus';
import { CasinoIcon } from './casino';
import { SportIcon } from './sport';
import { SettingsIcon } from './settings';
import { CmsIcon } from './cms';
import { ReportsIcon } from './reports';
import { NotificationCenterIcon } from './notificationCenter';
import { AffiliatesIcon } from './affiliates';
import { RiskManagementIcon } from './riskManagement';
import { ArchiveIcon } from './archive';

export const Icons = {
	DashboardIcon,
	RtmIcon,
	RiskManagementIcon,
	StaffIcon,
	PartnersIcon,
	CustomerIcon,
	BonusIcon,
	CasinoIcon,
	SportIcon,
	SettingsIcon,
	CmsIcon,
	ReportsIcon,
	NotificationCenterIcon,
	AffiliatesIcon,
	ArchiveIcon,
};
