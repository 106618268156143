import isArray from 'lodash/isArray';
import cloneDeep from 'lodash/cloneDeep';
import toInteger from 'lodash/toInteger';
import EntityAdapter from '../../../../helpers/entityAdapter';
import { SOURCE_TYPES } from '../../../../helpers/commonConstants';
import { isID } from '../../../../helpers/utils';

import { fields } from '../list/utils';
import { createEntities } from '../../../utility';
import { ResultType } from '@ceo-betmakers/common-globals';

const eventAdapter                = createEventAdapter();
const eventMarketAdapter          = createEventMarketAdapter();
const eventSelectionAdapter       = createEventSelectionAdapter();
const resultsListAdapter          = createResultsListAdapter();
const resultedEventMarketsAdapter = createResultedEventMarketsAdapter();
const resultedMarketsItemsAdapter = createResultedMarketsItemsAdapter();
const displayTypesAdapter         = createDisplayTypesAdapter();

// Adapt ------------------------------------------------------------------------------------------

export function adaptEvent(rawData = {}) {
	eventAdapter.clearExcludes();

	const adaptedData = eventAdapter.adapt(rawData);

	return adaptedData;
}

export function adaptEventMarkets(rawData = []) {
	if (!isArray(rawData)) {
		return [];
	}

	eventMarketAdapter.clearExcludes();
	eventSelectionAdapter.clearExcludes();

	const adaptedData = eventMarketAdapter.adaptList(rawData);
	adaptedData.forEach(item => {
		if (!item.name) {
			item.name = `No Market name (market ID: ${item.marketID})`;
		}

		item.isMarket = true;

		item.selections = eventSelectionAdapter.adaptList(item.selections);
		item.selections.sort((a, b) => {
			if (a.rowIndex === b.rowIndex) {
				return a.colIndex > b.colIndex ? 1 : -1;
			}

			return a.rowIndex > b.rowIndex ? 1 : -1;
		});
	});

	adaptedData.sort((a, b) => {
		if (a.orderID === b.orderID) {
			return a.name > b.name ? 1 : -1;
		}

		return a.orderID > b.orderID ? 1 : -1;
	});

	const marketData = adaptedData.map(item => ({
		...item,
		selections: item.selections.map(selection => selection.id),
	}));

	const markets = createEntities(marketData);
	const marketsIDs = Object.keys(markets);

	const selections = adaptedData.reduce((acc, item) => {
		item.selections.forEach(selection => {
			acc[selection.id] = selection;
		});
		return acc;
	}, {});
	
	return {
		marketsIDs,
		markets,
		selections,
	};

}

export function adaptSelectionsWithResults(rawSelections, rawData = []) {
	resultsListAdapter.clearExcludes();
	const resultsList = resultsListAdapter.adaptList(rawData);

	const selections = cloneDeep(rawSelections);
	resultsList.forEach(item => {
		const selectionID = toInteger(item.id);
		const selection = selections[selectionID];
		if (!selection) {
			return;
		}

		selection.won = item.resultTypeID === ResultType.WIN;
		selection.void = item.resultTypeID === ResultType.VOID;
		selection.lost = item.resultTypeID === ResultType.LOST;
	});

	return selections;
}

export function adaptResultedEventMarketsAdapter(rawData = {}) {
	resultedEventMarketsAdapter.clearExcludes();
	resultedMarketsItemsAdapter.clearExcludes();

	const resultedEventMarkets = resultedEventMarketsAdapter.adapt(rawData);
	const { eventMarkets } = resultedEventMarkets;
	const adaptedMarkets = resultedMarketsItemsAdapter.adaptList(eventMarkets);

	return adaptedMarkets;
}

export function adaptDisplayTypes(rawData = []) {
	displayTypesAdapter.clearExcludes();
	const adaptedData = displayTypesAdapter.adaptList(rawData);
	const entities = createEntities(adaptedData);

	entities[0] = {
		id     : 0,
		sportID: 0,
		orderID: 0,
		name   : 'All',
	};

	return entities;
}

// Prepare ----------------------------------------------------------------------------------------
export function prepareResultsList(selections, userID, marketID = null) {

	resultsListAdapter.clearExcludes();

	const selectionsList = Object.keys(selections).map(ID => selections[ID]);
	const sourceSelections = isID(marketID)
		? selectionsList.filter(selection => selection.marketID === marketID)
		: selectionsList;

	const preparedData = sourceSelections.map(selection => {

		const item = resultsListAdapter.prepare(selection);
		item[fields.resultTypeID] = null;

		if (selection.won) {
			item[fields.resultTypeID] = ResultType.WIN;
		} else if (selection.void) {
			item[fields.resultTypeID] = ResultType.VOID;
		} else if (selection.lost) {
			item[fields.resultTypeID] = ResultType.LOST;
		}

		item[fields.sourceID] = userID;
		item[fields.sourceTypeID] = SOURCE_TYPES.manual;

		return item;
	});

	return preparedData;
}

// Adapters ---------------------------------------------------------------------------------------
function createEventAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'sportID', fields.sportID);
	adapter.addField(rules.id, 'statusID', fields.statusID);

	adapter.addField(rules.fullDate, 'date', fields.date);

	adapter.addField(rules.string, 'name', fields.name);
	adapter.addField(rules.string, 'sportName', fields.sportName);
	adapter.addField(rules.string, 'countryName', fields.countryName);
	adapter.addField(rules.string, 'leagueName', fields.leagueName);

	return adapter;
}

function createEventMarketAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'eventID', fields.eventID);
	adapter.addField(rules.id, 'marketID', fields.marketID);
	adapter.addField(rules.id, 'orderID', fields.orderID);
	adapter.addField(rules.id, 'originID', fields.id);
	adapter.addField(rules.id, 'resolutionStatusID', fields.resolutionStatusID);
	adapter.addField(rules.id, 'winningCount', fields.winningCount);

	adapter.addField(rules.string, 'name', fields.name);
	adapter.addField(rules.bool, 'isMarket', fields.isMarket);


	adapter.addField(rules.arrayID, 'displayTypeIDs', fields.displayTypeIDs);
	adapter.addField(rules.arrayObject, 'selections', fields.selections);
	adapter.addField(rules.string, 'argument', fields.argument);

	return adapter;
}

function createEventSelectionAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'eventID', fields.eventID);
	adapter.addField(rules.id, 'eventMarketID', fields.eventMarketID);
	adapter.addField(rules.id, 'selectionID', fields.selectionID);
	adapter.addField(rules.id, 'resultTypeID', fields.resultTypeID);
	adapter.addField(rules.id, 'rowIndex', fields.rowIndex);
	adapter.addField(rules.id, 'colIndex', fields.colIndex);
	adapter.addField(rules.id, 'originID', fields.id);
	adapter.addField(rules.string, 'argument', fields.argument);

	adapter.addField(rules.string, 'name', fields.name);

	adapter.addField(rules.bool, 'won', fields.won);
	adapter.addField(rules.bool, 'void', fields.void);
	adapter.addField(rules.bool, 'lost', fields.lost);
	adapter.addField(rules.bool, 'isMarket', fields.isMarket);

	return adapter;
}

function createResultsListAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'eventID', fields.eventID);
	adapter.addField(rules.id, 'eventMarketID', fields.eventMarketID);
	adapter.addField(rules.id, 'selectionID', fields.selectionID);
	adapter.addField(rules.id, 'resultTypeID', fields.resultTypeID);

	return adapter;
}

function createResultedEventMarketsAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.array, 'eventMarkets', fields.eventMarkets);

	return adapter;
}

function createResultedMarketsItemsAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'resolutionStatusID', fields.resolutionStatusID);

	return adapter;
}

function createDisplayTypesAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'sportID', fields.sportID);
	adapter.addField(rules.id, 'orderID', fields.orderID);
	adapter.addField(rules.string, 'name', fields.name);

	return adapter;
}
