
import { BONUS_TYPES, BONUS_STATUS, CASHBACK_STATUSES, SETTLEMENT_STATUSES, FORCED } from './bonuses';
import { BonusStatus, CashBackWalletTypes, CasinoExtraSpinType, NextDepositWay } from '@ceo-betmakers/common-globals';

export const bonusTypesList = [
	{ id: BONUS_TYPES.all,                  name: 'All' },
	{ id: BONUS_TYPES.depositBonus,         name: 'Deposit Bonus' },
	{ id: BONUS_TYPES.sportsBookBonus,      name: 'SportsBook Bonus' },
	{ id: BONUS_TYPES.welcome,              name: 'Welcome' },
	{ id: BONUS_TYPES.deposit,              name: 'Deposit' },
	{ id: BONUS_TYPES.freeSpin,             name: 'Manual Free Spin' },
	{ id: BONUS_TYPES.birthday,             name: 'Birthday' },
	{ id: BONUS_TYPES.registrationFreeSpin, name: 'Registration Free Spin' },
	{ id: BONUS_TYPES.nextDepositFreeSpin,  name: 'Next Deposit Free Spin' },
	{ id: BONUS_TYPES.balanceAdjustment,    name: 'Balance Adjustment' },
	{ id: BONUS_TYPES.depositFreeSpin,      name: 'Deposit Free Spin' },
	{ id: BONUS_TYPES.pack,                 name: 'Pack' },
	{ id: BONUS_TYPES.bonusSpin,            name: 'Bonus Spin' },
	{ id: BONUS_TYPES.cashback,             name: 'Cashback' },
	{ id: BONUS_TYPES.nextDepositBonus,     name: 'Next Deposit Bonus' },
	{ id: BONUS_TYPES.packNext,             name: 'Next Pack' },
	{ id: BONUS_TYPES.massFreeSpin,         name: 'Mass Free Spin' },
];
export const conditionalBonusesTypesList = [
	{ id: BONUS_TYPES.registrationFreeSpin, name: 'Registration Free Spin' },
	{ id: BONUS_TYPES.nextDepositFreeSpin,  name: 'Next Deposit Free Spin' },
	{ id: BONUS_TYPES.depositFreeSpin,      name: 'Deposit Free Spin' },
	{ id: BONUS_TYPES.deposit,              name: 'Deposit' },
	{ id: BONUS_TYPES.nextDepositBonus,     name: 'Next Deposit Bonus' },
	{ id: BONUS_TYPES.pack,                 name: 'Pack' },
];
export const bonusesPermissions = {
	[BONUS_TYPES.birthday]            : 'admin_loyalty_bonus-engine_birthday',
	[BONUS_TYPES.cashback]            : 'admin_loyalty_bonus-engine_cashback',
	[BONUS_TYPES.depositFreeSpin]     : 'admin_loyalty_bonus-engine_deposit-free-spin',
	[BONUS_TYPES.deposit]             : 'admin_loyalty_bonus-engine_deposit',
	[BONUS_TYPES.freeSpin]            : 'admin_loyalty_bonus-engine_free-spins',
	[BONUS_TYPES.manualBonus]         : 'admin_loyalty_bonus-engine_manual-bonus',
	[BONUS_TYPES.nextDepositFreeSpin] : 'admin_loyalty_bonus-engine_next-deposit_free-spins',
	[BONUS_TYPES.nextDepositBonus]    : 'admin_loyalty_bonus-engine_next-deposit_next-deposit',
	[BONUS_TYPES.packNext]            : 'admin_loyalty_bonus-engine_next-deposit_pack',
	[BONUS_TYPES.pack]                : 'admin_loyalty_bonus-engine_pack',
	[BONUS_TYPES.registrationFreeSpin]: 'admin_loyalty_bonus-engine_registration',
	[BONUS_TYPES.welcome]             : 'admin_loyalty_bonus-engine_welcome',
};
export const forcedList = [
	{ id: FORCED.all,  name: 'All' },
	{ id: FORCED.forced,  name: 'Forced' },
	{ id: FORCED.notForced,  name: 'Not Forced' },
];

export const casinoExtraSpinTypesList = [
	{ id: CasinoExtraSpinType.BONUS_SPIN,  name: 'Bonus Round' },
	{ id: CasinoExtraSpinType.FREE_SPIN, name: 'Free Spins' },
];

export const bonusStatusesList = [
	// { id: BONUS_STATUSES.ACTIVE,  name: 'Active' },
	// { id: BONUS_STATUSES.INACTIVE,  name: 'Inactive' },
	{ id: BonusStatus.EXPIRED,  name: 'Expired' },
	{ id: BonusStatus.FINISHED,  name: 'Finished' },
	{ id: BonusStatus.GRANTED,  name: 'Granted' },
	{ id: BonusStatus.CLAIMED,  name: 'Claimed' },
	{ id: BonusStatus.CONVERTED,  name: 'Converted' },
	{ id: BonusStatus.DECLINED,  name: 'Declined' },
	{ id: BonusStatus.CANCELED,  name: 'Canceled' },
];


export const bonusStatusList = [
	{ id: BONUS_STATUS.active,   name: 'Active' },
	{ id: BONUS_STATUS.inactive, name: 'Inactive' },
];
export const wayList = [
	{ id: NextDepositWay.FIRST_WAY,   name: 'First Way' },
	{ id: NextDepositWay.SECOND_WAY,  name: 'Second Way' },
];

export const cashbackStatusList = [
	{ id: CASHBACK_STATUSES.ACTIVE,      name: 'Active' },
	{ id: CASHBACK_STATUSES.DEACTIVATED, name: 'Deactivated' },
	{ id: CASHBACK_STATUSES.FINISHED,    name: 'Finished' },
];

export const periodList = [
	{ id: 6,       name: '6 hours' },
	{ id: 12,      name: '12 hours' },
	{ id: 24,      name: '24 hours' },
];

export const cashbackWalletTypes = [
	{ id: CashBackWalletTypes.REAL,      name: 'Real' },
	{ id: CashBackWalletTypes.BONUS,      name: 'Bonus' },
];

export const settlementStatusList = [
	{ id: SETTLEMENT_STATUSES.SETTLED,      name: 'Settled' },
	{ id: SETTLEMENT_STATUSES.UNSETTLED,    name: 'Unsettled' },
	{ id: SETTLEMENT_STATUSES.REJECTED,     name: 'Rejected' },
];
