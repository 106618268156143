import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import { cloneDeep } from 'lodash';
import actions from './actions';
import bonusActions from './../../loyalty/bonuses/bonuses/actions';
import { casinoAPI } from '../../../helpers/api/casino';
import { showError, showSuccess } from '../../../helpers/notifications';

import { adaptBaseData, adaptEmptyIntegrators, adaptIntegratorsList, adaptPushIntegrator, statusCode } from './utils';
import { deriveBonusesBonusData } from '../../../selectors/bonuses';
import { logger } from '../../../helpers/logger';
import { CasinoExtraSpinType } from '@ceo-betmakers/common-globals';


const messages = {
	successSave  : 'integrators.success.save',
	successUpdate: 'integrators.success.update',
	errorListLoad: 'integrators.error.list.load',
	successDelete: 'integrators.success.delete',
	errorDelete  : 'integrators.error.delete',
};

// getStore ------------------------------------------------------------------------------------------

function getStore(state) {

	const { LanguageSwitcher, Casino, Loyalty: { Bonuses } } = state;
	const langID     = Casino.Integrators.get('UI').langID || LanguageSwitcher.get('language').dataBaseLangID;
	const integrator = Casino.Integrators.get('baseData').newNameLogoURL;
	const bonusData  = Bonuses.Bonuses.get('bonusData');
	return {
		langID,
		integrator,
		bonusData,
	};
}

function getStoreForUpdate(state) {

	const { App, Casino }  = state;
	const baseData         = Casino.Integrators.get('baseData');
	const entities         = Casino.Integrators.get('entities');
	const tempEntities     = Casino.Integrators.get('tempEntities');
	const { integratorID, showOnlyDeleted } = Casino.Integrators.get('UI');
	const websiteID        = App.get('websiteID');
	const bonusData = deriveBonusesBonusData(state);

	return {
		baseData,
		integratorID,
		websiteID,
		entities,
		tempEntities,
		bonusData,
		showOnlyDeleted,
	};
}

function* setEntitiesList(actions, showOnlyDeleted, list) {
	if (showOnlyDeleted) {
		yield put(actions.tempListRefresh(list));
	} else {
		yield put(actions.listRefresh(list));
	}
}

// Reload functions ------------------------------------------------------------------------------------------

function* listReload() {

	yield takeEvery(actions.CASINO_INTEGRATORS_LIST_RELOAD, function* (action) {
		yield put(actions.uiRefresh({ loading: true }));
		const { bonusData: { integratorIDs } } = yield select(getStore);
		const withProvider = action.data?.withProvider;
		const fromCasinoTab = action.data?.fromCasinoTab;
		const typeID = action.data?.type;
		const url = withProvider ? casinoAPI.integratorsListWithProviders : casinoAPI.integratorsList;

		let entities = {};
		const params = {};
		if (typeID) {
			if (typeID === CasinoExtraSpinType.FREE_SPIN) {
				params.free_spins = true;
			}
			if (typeID === CasinoExtraSpinType.BONUS_SPIN) {
				params.bonus_spins = true;
			}
			
		}
		try {
			const res = yield call(url, params);
			if (res && res.status === 200) {
				entities = adaptIntegratorsList(res.data.data, withProvider, integratorIDs);
				const { bonusData } = yield select(getStoreForUpdate);
				if (!fromCasinoTab) {
					const filteredEntities = adaptEmptyIntegrators(entities, bonusData);
					yield put(actions.setFilteredEmptyIntegrators(filteredEntities));
				}
				yield put(bonusActions.dataRefresh(bonusData));
			}
		} catch (error) {
			showError(messages.errorListLoad, error);
			logger.log(error);
		}

		yield put(actions.listRefresh(entities));

		if (fromCasinoTab) {
			yield put(actions.setDeletedIntegrators());
		}
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* integratorUpdate() {

	yield takeEvery(actions.CASINO_INTEGRATORS_CREATE_UPDATE_SAVE, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));

		const { baseData, entities:oldEntities, integratorID, websiteID, tempEntities, showOnlyDeleted } = yield select(getStoreForUpdate);
		const body = { name: baseData.name, website_id: websiteID };

		const { update } = action.data;

		let entities = {};

		entities = adaptPushIntegrator(showOnlyDeleted ? tempEntities : oldEntities, { id: integratorID }, update, true);
		yield* setEntitiesList(actions, showOnlyDeleted, entities.list);

		try {
			const res = yield call(update ? casinoAPI.integratorUpdateAPI : casinoAPI.newIntegratorAPI, { body, integratorID } );
			if (res && res.status === 200) {
				update ? showSuccess(messages.successUpdate) : showSuccess(messages.successSave);
				entities = adaptPushIntegrator(showOnlyDeleted ? tempEntities : oldEntities, res.data.data, update, false);
				const editedBaseData = adaptBaseData(res.data.data);
				yield put(actions.baseDataRefresh(editedBaseData));
				yield* setEntitiesList(actions, showOnlyDeleted, entities.list);
			}
		} catch (error) {
			entities = adaptPushIntegrator(showOnlyDeleted ? tempEntities : oldEntities, { id: integratorID }, update, false);
			yield* setEntitiesList(actions, showOnlyDeleted, entities.list);
			showError(messages.errorListLoad);
			logger.log(error);
		}

		if (!update) {
			yield put(actions.baseDataRefresh( entities.new ));
		}

		yield put(actions.uiRefresh({ loading: false, editMode: true, integratorID: entities.new ? entities.new.id : integratorID }));
	});
}

function* deleteIntegrator() {
	yield takeEvery(actions.CASINO_INTEGRATORS_DELETE_ITEM, function* ({ data : { integratorID } }) {
		yield put(actions.uiRefresh({ loading: true }));

		try {
			const res = yield call(casinoAPI.deleteIntegrator, integratorID);
			if (res && res.status === 200) {
				const { entities: oldEntities, tempEntities } = yield select(getStoreForUpdate);
				const oldEntitiesClone = cloneDeep(oldEntities);
				const oldTempEntitiesClone = cloneDeep(tempEntities);
				const index = oldEntitiesClone.findIndex(integrator => integrator.id === integratorID);

				if (index > -1) {
					const item = oldEntitiesClone[index];
					item.status = statusCode.deleted;
					oldTempEntitiesClone.push(item);
					oldEntitiesClone.splice(index, 1);
				}

				yield put(actions.listRefresh(oldEntitiesClone));
				yield put(actions.tempListRefresh(oldTempEntitiesClone));

				showSuccess(messages.successDelete);
			}
		} catch (error) {
			showError(messages.errorDelete);
		}

		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* showDeletedIntegrators() {
	yield takeEvery(actions.CASINO_INTEGRATORS_LIST_SET_DELETED_ITEMS, function* () {
		const { entities } = yield select(getStoreForUpdate);
		const oldEntitiesClone = cloneDeep(entities);

		const { filteredEntities, filteredDeletedEntities } = oldEntitiesClone.reduce(
			(result, integrator) => {
				integrator.status !== statusCode.deleted
					? result.filteredEntities.push(integrator)
					: result.filteredDeletedEntities.push(integrator);
				return result;
			},
			{ filteredEntities: [], filteredDeletedEntities: [] }
		);

		yield put(actions.listRefresh(filteredEntities));
		yield put(actions.tempListRefresh(filteredDeletedEntities));
	});
}

export default function* casinoListSaga() {
	yield all([
		fork(listReload),
		fork(integratorUpdate),
		fork(deleteIntegrator),
		fork(showDeletedIntegrators),
	]);
}
