import { Map } from 'immutable';
import { fill } from '../../../../../helpers/utils';
import actions from './actions';
import { LANGUAGES } from '../../../../../helpers/commonConstants';
import { ChannelType, PromotionStatus } from '@ceo-betmakers/common-globals';

const initState = new Map({

	baseData: {
		statusID: PromotionStatus.INACTIVE,
	},
	revisionsEntities: {},

	UI: {
		promotionID  : null,
		promotionName: null,
		editMode     : false,
		loading      : false,
		langID       : LANGUAGES.en,
		channelID    : ChannelType.WEB,
		isDataChanged: false,
		revisionID   : null,
	},
});

export default function promotionReducer(state = initState, action) {

	switch (action.type) {

		case actions.PROMOTION_BASE_DATA_REFRESH: {
			const target = state.get('baseData');
			const result = fill(action.data, target, true);
			const tempGames = [];
			result?.gameIDs?.length && result.gameIDs.forEach(item => {
				typeof item === 'object' ? tempGames.push(item.id) : tempGames.push(item);
			});

			result.gameIDs = tempGames;
			return state.set('baseData', result);
		}

		case actions.PROMOTION_UI_REFRESH: {
			const target = state.get('UI');
			const result = fill(action.data, target, true);
			return state.set('UI', result);
		}

		case actions.PROMOTIONS_REVISIONS_LIST_REFRESH: {
			return state.set('revisionsEntities', action.data);
		}

		case actions.PROMOTION_BASE_DATA_RESET: {
			return state.set('baseData', initState.get('baseData'));
		}

		case actions.PROMOTION_DATA_RESET: {
			return initState;
		}

		default:
			return state;
	}
}
