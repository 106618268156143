import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';

import { usersAPI } from '../../../../helpers/api/users';
import { showError, showSuccess } from '../../../../helpers/notifications';
import { logger } from '../../../../helpers/logger';
import manualDepositActions from './actions';
import userBalanceActions from '../balanceAdjustment/actions';
import { actions as manualDepositReducers } from './slice';
import { adaptWebsiteDepositList, getData } from './utils';
import { restoreWebsite } from '../../../../helpers/utility';
import { ISagaActionType } from '../../../types';
import { IDataSaveDeposit } from './types';

const prefix = 'userBalance.deposit';

const messages = {
	currencyMissed        : `${prefix}.currencyMissed`,
	errorDepositListReload: `${prefix}.errorDepositListReload`,
	successDepositSave    : `${prefix}.successDepositSave`,
	errorDepositSave      : `${prefix}.errorDepositSave`,
};

function getStoreData({ Users }) {
	return {
		manualBonusData: Users.Adjustment.ManualDeposit.manualBonusData,
		currencyID     : Users.User.get('baseData').currencyID,
		userID         : Users.User.get('baseData').id,
	};
}

function* saveManualDeposit() {
	yield takeEvery(manualDepositActions.MANUAL_DEPOSIT_BALANCE_SAVE, function* (action:ISagaActionType<IDataSaveDeposit>) {
		yield put(userBalanceActions.uiRefresh({ loading: true }));
		const { manualBonusData, userID } = yield select(getStoreData);
		const { data: { closeModal } } = action;

		const data = getData(manualBonusData);
		const params = {
			website_id: restoreWebsite(),
		};

		try {
			const res = yield call(usersAPI.userManualDepositSave, userID, manualBonusData.paymentId, data, params);
			if (res && res.status === 200) {
				showSuccess(messages.successDepositSave);
				if (closeModal) {
					yield put(userBalanceActions.uiRefresh({ visible: false }));
					yield put(manualDepositReducers.resetManualBonusData());
				}
			}
		} catch (error) {
			showError(messages.errorDepositSave, error);
			logger.log(error);
		}

		yield put(userBalanceActions.uiRefresh({ loading: false, isChanged: false }));
	});
}

function* getDepositMethods() {
	yield takeEvery(manualDepositActions.RELOAD_DEPOSIT_METHODS, function* () {
		const { currencyID } = yield select(getStoreData);
		yield put(manualDepositReducers.uiRefresh({ loading: true }));
		const preparedData = {
			currency_id    : currencyID,
			deposit_allowed: true,
		};

		try {
			if (currencyID) {
				const res = yield call(usersAPI.getWebsiteDepositList, preparedData);
				if (res && res.status === 200) {
					const { data } = res.data;
					const depositEntities = adaptWebsiteDepositList(data);
					yield put(manualDepositReducers.setDepositMethodsEntities(depositEntities));
				}
			} else {
				showError(messages.currencyMissed);
			}
		} catch (error) {
			showError(messages.errorDepositListReload, error);
			logger.log(error);
		}

		yield put(manualDepositReducers.uiRefresh({ loading: false })
		);
	});
}

export default function* userManualDepositSaga() {
	yield all([
		fork(saveManualDeposit),
		fork(getDepositMethods),
	]);
}
