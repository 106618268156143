import uniqBy from 'lodash/uniqBy';
import { Map } from 'immutable';
import { fill } from '../../helpers/utils';
import actions from './actions';
import { detectSectionNames, getNotificationTypeName, getRiskTypeName } from './utils';
import { RiskManagementPriority } from '@ceo-betmakers/common-globals';

const initState = new Map({
	entities: {},
	notifIDs: [],

	newNotifEntities: {},
	newNotifIDs     : [],

	count: {
		bets           : 0,
		casino         : 0,
		deposits       : 0,
		withdrawal     : 0,
		documents      : 0,
		messages       : 0,
		riskManagement : 0,
		riskAlertLow   : 0,
		riskAlertMedium: 0,
		riskAlertHigh  : 0,
	},

	bets           : [],
	casino         : [],
	deposits       : [],
	withdrawal     : [],
	documents      : [],
	messages       : [],
	riskAlertLow   : [],
	riskAlertMedium: [],
	riskAlertHigh  : [],

	page: {
		bets           : 1,
		casino         : 1,
		deposits       : 1,
		withdrawal     : 1,
		documents      : 1,
		messages       : 1,
		riskAlertHigh  : 1,
		riskAlertMedium: 1,
		riskAlertLow   : 1,
	},
	hasMore: {
		bets           : true,
		casino         : true,
		deposits       : true,
		withdrawal     : true,
		documents      : true,
		messages       : true,
		riskAlertHigh  : true,
		riskAlertMedium: true,
		riskAlertLow   : true,
	},
	UI: {
		loading     : false,
		visible     : false,
		pageCount   : null,
		riskTabIndex: RiskManagementPriority.HIGH,
		tabName     : null,
		messagesPage: 1,
		totalCount  : 0,
	},

});

export default function notificationReducer(state = initState, action) {

	switch (action.type) {
		case actions.DATA_REFRESH: {
			const { typeID, notificationsList, id, byApi } = action.data;
			const sectionName     = getNotificationTypeName(typeID);
			const sectionData     = state.get(sectionName);
			const newSectionData  = sectionData.filter(item => +item.id !== +id);
			const result          = byApi
				? uniqBy(newSectionData.concat(notificationsList), 'id')
				: uniqBy(notificationsList.concat(newSectionData), 'id');
			return state.set(sectionName, result);
		}
		case actions.DATA_REFRESH_SEEN: {
			const { sectionName, seenList } = action.data;
			return state.set(sectionName, seenList);
		}
		case actions.SOCET_DATA_REFRESH: {
			const { typeID, notificationsList } = action.data;
			const sectionName = getRiskTypeName(typeID);
			return state.set(sectionName, notificationsList);
		}
		case actions.NOTIF_DATA_REFRESH: {
			const { notifIDs, entities } = action.data;
			return state
				.set('notifIDs', notifIDs)
				.set('entities', entities);
		}

		case actions.NEW_NOTIF_LIST_REFRESH: {
			const { newNotifIDs, newNotifEntities } = action.data;
			return state
				.set('newNotifIDs', newNotifIDs)
				.set('newNotifEntities', newNotifEntities);
		}

		case actions.RESET_NOTIF_IDS: {
			return state.set('notifIDs', []);
		}

		case actions.DATA_RESET: {
			const { typeID } = action.data;
			const sectionName = getNotificationTypeName(typeID);
			const target = state.get('page');
			target[sectionName] = 1;
			return state.set(sectionName, [])
				.set('page', target);
		}

		case actions.COUNT_MERGE: {
			const { count } = action.data;
			const target = state.get('count');
			const result = fill(count, target);

			return state.set('count', result);
		}
		case actions.PAGE_MERGE: {
			const { page } = action.data;
			const target = state.get('page');
			const result = fill(page, target);

			return state.set('page', result);
		}
		case actions.MORE_MERGE: {
			const { hasMore } = action.data;
			const target = state.get('hasMore');
			const result = fill(hasMore, target);

			return state.set('hasMore', result);
		}
		case actions.UI_REFRESH: {
			const { UI } = action.data;
			const target = state.get('UI');
			const result = fill(UI, target);
			return state.set('UI', result);
		}
		case actions.RISK_DATA_RESET: {
			const { typeID, tab, closeModal } = action.data;
			if (closeModal) {
				const sectionName = getNotificationTypeName(typeID);
				return state.set(sectionName, []);
			}
			const detectedSectionNames        = detectSectionNames(+tab);
			const { firstReset, secondReset } = detectedSectionNames;
			const targetPage                  = state.get('page');
			const targetHaseMore              = state.get('hasMore');
			const targetUI					= state.get('UI');
			
			return state.set(firstReset, [])
				.set(secondReset, [])
				.set('UI', {
					...targetUI,
					loading: false,
				})
				.set('page', {
					...targetPage,
					[firstReset] : 1,
					[secondReset]: 1,
				})
				.set('hasMore', {
					...targetHaseMore,
					[firstReset] : true,
					[secondReset]: true,
				});
		}
		default:
			return state;
	}
}
