import { validateDescription, validateTitle } from '../../../LoyaltySystem/BonusingSystem/Tabs/TabTitle/validator';
import {
	checkBonusLinesAndDenominations,
	checkValueForZero,
} from '../../../LoyaltySystem/BonusingSystem/Bonuses/modals/Bonus/utils';

const prefix = 'users.userBonus';

const messages = {
	invalidBet            : `${prefix}.invalidBet`,
	invalidDenomination   : `${prefix}.invalidDenomination`,
	invalidLanguage       : `${prefix}.invalidLanguage`,
	invalidLangDescription: `${prefix}.invalidLangDescription`,
	errorBetPerLine       : `${prefix}.errorBetPerLine`,
	errorLines            : `${prefix}.errorLines`,
	atLeastOneDenom       : `${prefix}.atLeastOneDenom`,

};

const keysValueCanBeZero = ['wageringTurnover'];

export function validateFreeSpin(bonusData, limits,  websiteLanguages, lineAndDenominationsRequired, isMassBonus) {
	const presense = [
		{ key: 'typeID',           title: 'Type' },
		{ key: 'casinoGameID',     title: 'Casino Game' },
		{ key: 'spinsTotal',       title: 'Spins Total' },
		{ key: 'wageringTurnover', title: 'Wager Turnover' },
		{ key: 'daysToExpireWallet',     title: 'Days To Expire Wallet' },
	];
	if (!bonusData.forced) {
		presense.push({ key: 'daysToExpireBonus', title: 'Days To Expire Bonus' });
	}

	if (isMassBonus) {
		presense.push({ key: 'name', title: 'Name' });
	}
	const errors = [];
	const excludedFields = [];

	presense.forEach(item => {
		const { key } = item;
		if (!excludedFields.includes(key)) {
			let value = bonusData[key];
			if (!value && keysValueCanBeZero.includes(key)) {
				value = checkValueForZero(value);
			}
			if (!value) {
				errors.push(`users.userBonus.${key}.empty`);
			}
		}
	});

	if (limits.betID === null && !limits.showLineAndDenominationInputs) {
		errors.push(messages.invalidBet);
	}

	checkBonusLinesAndDenominations(bonusData, limits, lineAndDenominationsRequired, errors, messages);

	const validTitle = validateTitle(bonusData.titles, websiteLanguages);
	if (validTitle) {
		errors.push(validTitle);
	}

	const validDescription = validateDescription(bonusData.titles);
	if (validDescription) {
		errors.push(validDescription);
	}

	const maxPayoutPercent = bonusData.maxPayoutPercent;

	if (maxPayoutPercent || (!maxPayoutPercent && typeof maxPayoutPercent === 'number')) {
	// Max Payout
		if (maxPayoutPercent <= 0 || maxPayoutPercent > 100) {
			errors.push('bonuses.modal.maxPayoutPercent.incorrect');
		}
	}

	return errors;
}

export function validateFreeBet(bonusData) {

	const presense = [
		{ key: 'selectedMarket',   title: 'Market' },
		{ key: 'maxBonusAmount',    title: 'Bonus Amount' },
		{ key: 'wageringTurnover',  title: 'Wagering Turnover' },
		{ key: 'daysToExpireBonus', title: 'Days To Expire Bonus' },
	];

	const errors = [];

	presense.forEach(item => {
		const { key } = item;
		let value = bonusData[key];
		if (!value && keysValueCanBeZero.includes(key)) {
			value = checkValueForZero(value);
		}
		if (!value) {
			errors.push(`user.userBonus.freebet.${key}.empty`);
		}
	});

	return errors;
}
