import { takeEvery, put, call, fork, all, select } from 'redux-saga/effects';
import { bonusTemplatesActions } from './actions';
import { bonusTemplatesReducers } from './slice';
import { bonusTemplatesAPI } from '../../../helpers/api/bonusTemplates';
import tableActions from '../../tables/actions';

import { IApiResponse, ISagaActionType } from '../../types';
import {
	EBonusTemplateTypeIDs,
	IBonusTemplate,
	IBonusTemplateResponse,
	IGetStoreTemplates,
	ISaveAction,
} from './types';
import {
	adaptBonusTemplate,
	adaptBonusTemplates,
	getListParams,
	initBaseData,
	prepareBonusMoney,
	prepareFreeSpinTemplate,
} from './utils';
import { RootState } from '../../store';
import { showError, showSuccess } from '../../../helpers/notifications';
import { logger } from '../../../helpers/logger';
import actions from '../../appTabs/actions';
import mainCurrencyActions from '../../settings/currency/actions';
import { deriveTablePagination } from '../../../selectors/tables';
import { TABLE_TYPES } from '../../../constants/tableTypes';
import { LANGUAGES } from '../../../helpers/commonConstants';
import { validateTitle } from '../../../containers/LoyaltySystem/BonusingSystem/Tabs/TabTitle/validator';
import { getHeadersTotalCount } from '../../../helpers/utils';
import { makeRequestGlobalCurrencesReload } from '../../settings/currency/saga';

const messages = {
	successSave  : 'loyalty.bonusTemplates.successSave',
	successUpdate: 'loyalty.bonusTemplates.successUpdate',
};

const tableType = TABLE_TYPES.bonusesTemplates;

function getStoreData(state: RootState): IGetStoreTemplates {
	const { Loyalty, Tables, Settings  } = state;
	const bonusUI = Loyalty.Bonuses.Bonuses.get('UI');
	const websiteID = state.App.get('websiteID');
	const websiteLanguages = state.App.get('websiteAttachedLanguages');
	return {
		baseData         : Loyalty.BonusTemplates.baseData,
		filterData       : Loyalty.BonusTemplates.filterData,
		denominationValue: bonusUI.denominationValue,
		sorting          : Tables.get(tableType).sorting,
		websiteID,
		currencies       : Settings.CurrencyModule.get('entities'),
		//eslint-disable-next-line
		// @ts-ignore
		pagination       : deriveTablePagination( tableType, state ),
		websiteLanguages,
	};
}

function* listReload() {
	yield takeEvery(bonusTemplatesActions.BONUS_TEMPLATES_RELOAD, function* () {
		yield put(bonusTemplatesReducers.setUI({ loading: true }));
		const { filterData, sorting, pagination } = yield select(getStoreData);
		const params = getListParams(filterData, sorting, pagination);
		let adapted = [];
		try {
			const { currencyList, entities } = yield call(makeRequestGlobalCurrencesReload);
			yield put(mainCurrencyActions.currencyListRefresh(currencyList));
			yield put(mainCurrencyActions.currencyRefreshEntities(entities));

			const { data, headers }: IApiResponse<IBonusTemplateResponse[]> = yield call(bonusTemplatesAPI.getTemplates, params);
			const totalCount = getHeadersTotalCount(headers);
			yield put(tableActions.paginationRefresh(tableType, { totalCount }));

			adapted = adaptBonusTemplates(data.data);
		} catch (error) {
			yield put(bonusTemplatesReducers.setUI({ loading: false }));
		} finally {
			yield put(bonusTemplatesReducers.setUI({ loading: false }));
			yield put(bonusTemplatesReducers.setTemplates(adapted));
		}

	});
}

function* getTemplate() {
	yield takeEvery(bonusTemplatesActions.BONUS_TEMPLATE_GET, function* (action: ISagaActionType<number>) {
		const templateID = action.data;
		yield put(bonusTemplatesReducers.setUI({ loading: true }));
		try {
			const { data }: IApiResponse<IBonusTemplateResponse> = yield call(bonusTemplatesAPI.getTemplate, templateID);
			const adapted = adaptBonusTemplate(data.data);
			adapted.count = data.data.count || {
				providers : {},
				categories: {},
			};

			yield put(bonusTemplatesReducers.setBaseData(adapted));
		} catch (error) {
			logger.log(error);
			yield put(bonusTemplatesReducers.setUI({ loading: false }));
		}

		yield put(bonusTemplatesReducers.setUI({ loading: false }));
	});
}


function* archiveTemplate() {
	yield takeEvery(bonusTemplatesActions.BONUS_TEMPLATE_ARCHIVE, function* (action: ISagaActionType<number>) {
		const templateID = action.data;
		yield put(bonusTemplatesReducers.setUI({ loading: true }));
		try {
			yield call(bonusTemplatesAPI.archiveTemplate, templateID);
			yield put(bonusTemplatesActions.listReload());
		} catch (error) {
			yield put(bonusTemplatesReducers.setUI({ loading: false }));
		}

		yield put(bonusTemplatesReducers.setUI({ loading: false }));
	});
}


function* onSave() {
	yield takeEvery(bonusTemplatesActions.BONUS_TEMPLATES_SAVE, function* ({ data }: ISagaActionType<ISaveAction>) {
		const { typeID, editMode } = data;
		const { baseData, denominationValue, websiteID, websiteLanguages }: IGetStoreTemplates = yield select(getStoreData);
		yield put(bonusTemplatesReducers.setUI({ loading: true }));
		let preparedData	= {};

		const clonedBaseData: IBonusTemplate = Object.assign({}, baseData, { websiteID, templateType: typeID });
		try {
			const titleNotValidated = validateTitle(clonedBaseData.titles, websiteLanguages);

			if (titleNotValidated) {
				showError(titleNotValidated);
				yield put(bonusTemplatesReducers.setUI({ loading: false }));
				return;
			}
			if (typeID === EBonusTemplateTypeIDs.MANUAL_BONUS_MONEY) {
				preparedData = prepareBonusMoney(clonedBaseData, editMode);
			} else if (typeID === EBonusTemplateTypeIDs.MANUAL_FREE_SPINS) {
				clonedBaseData.denominationValue = denominationValue;
				preparedData = prepareFreeSpinTemplate(clonedBaseData, editMode);
			}

			if (editMode) {
				const response: IApiResponse<IBonusTemplateResponse> = yield call(bonusTemplatesAPI.updateTemplate, preparedData, baseData.id);
				if (response && response.status === 200) {
					// yield put(bonusTemplatesReducers.setBaseData(initBaseData));
					yield put(bonusTemplatesReducers.setUI({ saveSuccess: true, langID: LANGUAGES.en }));

					const adapted = adaptBonusTemplate(response.data.data);

					clonedBaseData.showRemainingSpinsTotal	= !!adapted.spinsTotal;
					clonedBaseData.remainingSpinsTotal		= adapted.spinsTotal - adapted.usedTotalFreespins;
					clonedBaseData.currencies				= adapted.currencies;
					yield put(bonusTemplatesReducers.setBaseData(clonedBaseData));

					showSuccess(messages.successUpdate);

				}

			} else {
				const res: IApiResponse<IBonusTemplateResponse> = yield call(bonusTemplatesAPI.saveTemplate, preparedData);
				if (res && res.status === 200) {
					yield put(bonusTemplatesReducers.setBaseData(initBaseData));
					yield put(bonusTemplatesReducers.setUI({ saveSuccess: true, langID: LANGUAGES.en }));
					showSuccess(messages.successSave);
				}
				yield put(actions.openTabBonusTemplates());
			}
		} catch (error) {
			logger.log(error);
			yield put(bonusTemplatesReducers.setUI({ loading: false }));
		}

		yield put(bonusTemplatesReducers.setUI({ loading: false }));
	});
}


export default function* bonusTemplatesRootSaga() {
	yield all([
		fork(listReload),
		fork(getTemplate),
		fork(onSave),
		fork(archiveTemplate),
	]);
}