import { ProfitGrade } from '@ceo-betmakers/common-globals';

export const PERIODS = {
	today    : 'today',
	yesterday: 'yesterday',
	thisWeek : 'thisWeek',
	thisMonth: 'thisMonth',
	lastWeek : 'lastWeek',
	lastMonth: 'lastMonth',
};

export const PROFITABILITYGRADE = {
	NEGATIVE                  : ProfitGrade.NEGATIVE,
	PROFITABLE                : ProfitGrade.PROFITABLE,
	UNPROFITABLE              : ProfitGrade.UNPROFITABLE,
	NEUTRAL                   : ProfitGrade.NEUTRAL,
	[ProfitGrade.NEGATIVE]    : 'NEGATIVE',
	[ProfitGrade.PROFITABLE]  : 'PROFITABLE',
	[ProfitGrade.UNPROFITABLE]: 'UNPROFITABLE',
	[ProfitGrade.NEUTRAL]     : 'NEUTRAL',
};

export const PRODUCT_TYPE = {
	SPORT : 1,
	CASINO: 2,
	SYSTEM: 3,
};

export const BONUS_TITLE_MAX_LENGTH = 100;
export const BONUS_DESCRIPTION_MAX_LENGTH = 65535;
