import { RGStatus, RGType } from '@ceo-betmakers/common-globals';

export const RESPONSIBLE_STATUS_IDS_NAMES = {
	[RGStatus.ACTIVE] : 'Active',
	[RGStatus.DELETED]: 'Deleted',
	[RGStatus.EXPIRED]: 'Expired',
};

export const RESPONSIBLE_TYPE_IDS_NAMES =  {
	[RGType.TIME_OUT]      : 'Time Out',
	[RGType.AVAILABLE_TIME]: 'Available Time',
	[RGType.SELF_EXCLUSION]: 'Self exclusion',
	[RGType.DEPOSIT_LIMIT] : 'Deposit Limit',
};
