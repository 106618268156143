import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import actions from './actions';
import tableActions from '../../../tables/actions';

import notifications from '../../../../helpers/notifications';
import { bonusesAPI } from '../../../../helpers/api/bonuses';
import { getHeadersTotalCount } from '../../../../helpers/utils';
import { deriveTablePagination } from '../../../../selectors/tables';

import { getListParams, adaptFreeSpinsList, updateBonusFreeSpinList } from './utils';
import { TABLE_TYPES } from '../../../../constants/tableTypes';
import { logger } from '../../../../helpers/logger';
import { formatDate } from '../../../../helpers/utility';
import { BonusStatus } from '@ceo-betmakers/common-globals';

const messages = {
	errorListLoad: 'CANCEL_FREE_SPIN_ERROR',
};

const tableType   = TABLE_TYPES.bonusesFreeSpins;

function getStoreData(state) {
	const { Loyalty: { BonusingHistory }, Tables, App, Auth } = state;
	const { FreeSpins } = BonusingHistory;

	return {
		list      : FreeSpins.get('list'),
		filter    : FreeSpins.get('filter'),
		sorting   : Tables.get(tableType).sorting,
		pagination: deriveTablePagination(tableType, state),
		websiteID : App.get('websiteID'),
		user      : Auth.get('user'),
	};
}

function* listReload() {

	yield takeEvery(actions.BONUSES_FREE_SPINS_LIST_RELOAD, function* () {
		yield put(actions.uiRefresh({ loading: true }));

		const { filter, sorting, pagination } = yield select(getStoreData);
		const params = getListParams(filter, sorting, pagination);

		let list   = [];
		let totalCount = 0;
		try {
			const res = yield call(bonusesAPI.freeSpinsList, params);
			if (res && res.status === 200) {
				list   = adaptFreeSpinsList(res.data.data, res.headers);
				totalCount = getHeadersTotalCount(res.headers);
				yield put(actions.listRefresh(list));
			}
			yield put(tableActions.paginationRefresh(tableType, { totalCount }));

		} catch (error) {
			notifications.showError(messages.errorListLoad, error);
			logger.log(error);
		}

		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* cancelBonusFreeSpin() {

	yield takeEvery(actions.BONUSES__FREE_SPIN_CANCEL, function* (action) {
		const { websiteID, list, user } = yield select(getStoreData);
		const { id, userID } = action.data.data;

		const params = {
			id,
			user_id   : userID,
			website_id: +websiteID,
		};

		let clonedEntities   = {};
		clonedEntities = updateBonusFreeSpinList({ list, spinID: id, loading: true });
		yield put(actions.listRefresh(clonedEntities));

		try {
			const res = yield call(bonusesAPI.freeSpinCancel, params);
			if (res && res.status === 200) {
				const updateOptions = {
					list,
					spinID    : id,
					loading   : false,
					statusID  : BonusStatus.CANCELED,
					adminID   : user.id,
					cancelDate: formatDate(new Date()),
				};

				clonedEntities = updateBonusFreeSpinList(updateOptions);
				yield put(actions.listRefresh(clonedEntities));
			}

		} catch (error) {
			clonedEntities = updateBonusFreeSpinList({ list, spinID: id, loading: false });
			yield put(actions.listRefresh(clonedEntities));
			notifications.showError(messages.errorListLoad, error);
			logger.log(error);
		}
	});
}

function* filterApply() {
	yield takeEvery(actions.BONUSES_FREE_SPINS_LIST_FILTER_APPLY, function* () {
		yield put(tableActions.paginationRefresh(tableType, { currentPage: 1 }));
		yield put(actions.listReload());
	});
}

export default function* freeSpinsListSaga() {
	yield all([
		fork(listReload),
		fork(filterApply),
		fork(cancelBonusFreeSpin),
	]);
}
