import { createBrowserRouter, RouteObject } from 'react-router-dom';
import { AsyncPrivate, AsyncPublic } from './router';
import { DistributeRoute } from '../components/DistrubuteRoute/DistributeRoute';

export enum Routes {
	root = '/',
	dashboard = '/dashboard',
	partnersWebsite = '/dashboard/partners/websites',
	partnersPartner = '/dashboard/partners/partners',
	partnersWebsiteInner = '/dashboard/partners/websitesInfo/:websiteID',

	massBonusCreate = '/dashboard/loyalty/bonusingSystem/massbonus/:bonusID',

	notFound = '/404',
	serverError = '/500',
	unExpected = '*',
	signup = '/signup',
	signin = '/signin',
	forgotPassword = '/forgotpassword',
	resetPassword = '/resetpassword',
	setPartnerPassword = '/partners/password/:id',
	partnerHash = '/admins/password/:hashId',

	rtmBets = '/dashboard/rtm/bets',
	rtmDeposits = '/dashboard/rtm/deposits',
	rtmWithdrawals = '/dashboard/rtm/withdrawals',
	rtmCasino = '/dashboard/rtm/casino',
	rtmDocuments = '/dashboard/rtm/documents',
	rtmRiskAlerts = '/dashboard/rtm/risk-alerts',

	riskManagementBets = '/dashboard/riskManagement/bets',

	staffPersonnel = '/dashboard/staff/personal',
	staffInner = '/dashboard/personal/info/:userID',
	staffRoles = '/dashboard/staff/roles',

	usersList = '/dashboard/users/list',
	userInner = '/dashboard/users/info/:userID',
	usersGroups = '/dashboard/users/groups',

	bonuses = '/dashboard/loyalty/bonusingSystem/bonuses',
	conditionalBonusCreate = '/dashboard/loyalty/bonusingSystem/bonuses/create/:type',
	conditionalBonusEdit = '/dashboard/loyalty/bonusingSystem/bonuses/edit/:type/:id',

	welcomeBonusCreate = '/dashboard/loyalty/bonusingSystem/welcome/create/:type',
	welcomeBonusEdit = '/dashboard/loyalty/bonusingSystem/welcome/edit/:type/:id',

	bonusTemplates = '/dashboard/loyalty/bonusTemplates',
	bonusDetailsBase = '/dashboard/loyalty/templates',
	bonusTemplateDetails = '/dashboard/loyalty/templates/view/:templateID',
	bonusTemplateCreate = '/dashboard/loyalty/templates/create/:type',
	welcome = '/dashboard/loyalty/bonusingSystem/welcome',
	cashback = '/dashboard/loyalty/bonusingSystem/cashback',
	massBonus = '/dashboard/loyalty/bonusingSystem/massBonus',

	freeSpins = '/dashboard/loyalty/bonusingHistory/freeSpins',
	usersWithCashback = '/dashboard/loyalty/bonusingHistory/usersWithCashback',
	freeBets = '/dashboard/loyalty/bonusingHistory/freeBets',
	
	tournaments = '/dashboard/loyalty/tournaments',

	casinoList = '/dashboard/casino/list',
	casinoGameCategories = '/dashboard/casino/gameCategories',
	casinoCustomProviders = '/dashboard/casino/customProviders',
	casinoGames = '/dashboard/casino/games',
	casinoIntegrators = '/dashboard/casino/integrators',

	sportEvents = '/dashboard/sport/events',
	sportEventsInner = '/dashboard/events/info/:userID',
	sportResults = '/dashboard/sport/results',
	sportResultsInner = '/dashboard/results/info/:userID',
	sportLiveStatisticTypes = '/dashboard/sport/liveStatisticTypes',
	sportTemplates = '/dashboard/sport/markets/templates',
	sportGroups = '/dashboard/sport/markets/groups',
	sportMarketsStatisticTypes = '/dashboard/sport/markets/statisticTypes',
	sportDisplayTypes = '/dashboard/sport/markets/displayTypes',
	sportParticipants = '/dashboard/sport/participants/list',
	sportAges = '/dashboard/sport/participants/ages',
	sportBettingRules = '/dashboard/sport/sportSettings/bettingRules',
	sportScopes = '/dashboard/sport/sportSettings/scopes',
	sportCategories = '/dashboard/sport/categories',
	sportLadders = '/dashboard/sport/sportSettings/ladders',

	settingsEditor = '/dashboard/settings/sidebarEditor',
	settingsSmsGateway = '/dashboard/settings/sms/smsGateways',
	settingsTemplates = '/dashboard/settings/sms/smsTemplates',
	settingsCurrencies = '/dashboard/settings/currencies',
	settingsPayments = '/dashboard/settings/payments',
	// settingsPaymentPayways = '/dashboard/payment/payways/:paymentID',
	settingsQuestions = '/dashboard/settings/security/questions',
	settingsBlockedIPs = '/dashboard/settings/security/blockedIps',
	settingsWhitelist = '/dashboard/settings/security/whitelist',

	cmsHeader = '/dashboard/cms/content/header',
	cmsFooter = '/dashboard/cms/content/footer',
	cmsArticles = '/dashboard/cms/content/articles',
	cmsPriorities = '/dashboard/cms/homeManage/priorities',
	cmsSpecialOffers = '/dashboard/cms/homeManage/specialOffers',
	cmsSpecialOffer = '/dashboard/specialOffers/sports/:sportID',
	cmsUpcoming = '/dashboard/cms/homeManage/upcoming',
	cmsMatchesOfDay = '/dashboard/cms/homeManage/matchesOfDay',
	cmsLandingPageGameGrid = '/dashboard/cms/homeManage/landingPageGameGrid',
	cmsInfoPages = '/dashboard/cms/cmsPages/infoPages',
	cmsInfoPagesInner = '/dashboard/content/page/:pageID',
	cmsNews = '/dashboard/cms/cmsPages/news',
	newsArticlePage = '/dashboard/content/news/article/:articleID',
	cmsPromotions = '/dashboard/cms/cmsPages/promotions',
	cmsPromotionsAddNew = '/dashboard/content/newPromotion/add',
	cmsPromotionsInner = '/dashboard/content/newPromotion/:promotionID',
	cmsHelpCenter = '/dashboard/cms/cmsPages/helpCenter',
	cmsBanners = '/dashboard/cms/banners/list',
	cmsBannersGroup = '/dashboard/cms/banners/groups',
	cmsSportPriorities = '/dashboard/priorities/sport/:sportID',

	reportsByPlayer = '/dashboard/reports/reportByPlayer',
	reportsByGame = '/dashboard/reports/reportByGame',
	reportsByProvider = '/dashboard/reports/reportByProvider',
	reportsByIntegrator = '/dashboard/reports/reportByIntegrator',
	reportsTransactions = '/dashboard/reports/transactions',

	notificationPush = '/dashboard/notificationCenter/pushNotifications',
	notificationMessages = '/dashboard/notificationCenter/messages',
	notificationSMS = '/dashboard/notificationCenter/sms',
	notificationEmails = '/dashboard/notificationCenter/emails/list',
	notificationTemplates = '/dashboard/notificationCenter/emails/templates',
	affiliates = '/dashboard/affiliates',
}

const publicRoutes: RouteObject[] = [
	{
		path   : Routes.unExpected,
		element: <DistributeRoute />,
	},
	{
		path   : Routes.notFound,
		element: <AsyncPublic route={Routes.notFound} />,
	},

	{
		path   : Routes.serverError,
		element: <AsyncPublic route={Routes.serverError} />,
	},
	{
		path   : Routes.signin,
		element: <AsyncPublic route={Routes.signin} />,
	},
	{
		path   : Routes.signup,
		element: <AsyncPublic route={Routes.signup} />,
	},
	{
		path   : Routes.forgotPassword,
		element: <AsyncPublic route={Routes.forgotPassword} />,
	},
	{
		path   : Routes.resetPassword,
		element: <AsyncPublic route={Routes.resetPassword} />,
	},
	{
		path   : Routes.setPartnerPassword,
		element: <AsyncPublic route={Routes.setPartnerPassword} />,
	},
	{
		path   : Routes.partnerHash,
		element: <AsyncPublic route={Routes.partnerHash} />,
	},

];

export const getRoutes = () => {
	const routes = [...privateRoutes, ...publicRoutes];
	return createBrowserRouter(routes);
};

const privateRoutes: RouteObject[] = [
	{
		path   : Routes.dashboard,
		element: <AsyncPrivate route={Routes.dashboard} />,
	},
	{
		path   : Routes.partnersPartner,
		element: <AsyncPrivate route={Routes.partnersPartner} />,
	},
	{
		path   : Routes.partnersWebsite,
		element: <AsyncPrivate route={Routes.partnersWebsite} />,
	},
	{
		path   : Routes.partnersWebsiteInner,
		element: <AsyncPrivate route={Routes.partnersWebsiteInner} />,
	},
	{
		path   : Routes.massBonusCreate,
		element: <AsyncPrivate route={Routes.massBonusCreate} />,
	},
	{
		path   : Routes.rtmBets,
		element: <AsyncPrivate route={Routes.rtmBets} />,
	},
	{
		path   : Routes.rtmDeposits,
		element: <AsyncPrivate route={Routes.rtmDeposits} />,
	},
	{
		path   : Routes.rtmWithdrawals,
		element: <AsyncPrivate route={Routes.rtmWithdrawals} />,
	},
	{
		path   : Routes.rtmCasino,
		element: <AsyncPrivate route={Routes.rtmCasino} />,
	},
	{
		path   : Routes.rtmDocuments,
		element: <AsyncPrivate route={Routes.rtmDocuments} />,
	},
	{
		path   : Routes.rtmRiskAlerts,
		element: <AsyncPrivate route={Routes.rtmRiskAlerts} />,
	},
	{
		path   : Routes.riskManagementBets,
		element: <AsyncPrivate route={Routes.riskManagementBets} />,
	},
	{
		path   : Routes.staffPersonnel,
		element: <AsyncPrivate route={Routes.staffPersonnel} />,
	},
	{
		path   : Routes.staffInner,
		element: <AsyncPrivate route={Routes.staffInner} />,
	},
	{
		path   : Routes.staffRoles,
		element: <AsyncPrivate route={Routes.staffRoles} />,
	},
	{
		path   : Routes.usersList,
		element: <AsyncPrivate route={Routes.usersList} />,
	},
	{
		path   : Routes.userInner,
		element: <AsyncPrivate route={Routes.userInner} />,
	},
	{
		path   : Routes.usersGroups,
		element: <AsyncPrivate route={Routes.usersGroups} />,
	},
	{
		path   : Routes.bonuses,
		element: <AsyncPrivate route={Routes.bonuses} />,
	},
	
	{
		path   : Routes.conditionalBonusCreate,
		element: <AsyncPrivate route={Routes.conditionalBonusCreate} />,
	},
	{
		path   : Routes.conditionalBonusEdit,
		element: <AsyncPrivate route={Routes.conditionalBonusEdit} />,
	},
	{
		path   : Routes.welcomeBonusCreate,
		element: <AsyncPrivate route={Routes.welcomeBonusCreate} />,
	},
	{
		path   : Routes.welcomeBonusEdit,
		element: <AsyncPrivate route={Routes.welcomeBonusEdit} />,
	},
	{
		path   : Routes.bonusTemplates,
		element: <AsyncPrivate route={Routes.bonusTemplates} />,
	},
	{
		path   : Routes.bonusTemplateCreate,
		element: <AsyncPrivate route={Routes.bonusTemplateCreate} />,
	},
	{
		path   : Routes.bonusTemplateDetails,
		element: <AsyncPrivate route={Routes.bonusTemplateDetails} />,
	},
	{
		path   : Routes.freeSpins,
		element: <AsyncPrivate route={Routes.freeSpins} />,
	},
	{
		path   : Routes.freeBets,
		element: <AsyncPrivate route={Routes.freeBets} />,
	},
	{
		path   : Routes.welcome,
		element: <AsyncPrivate route={Routes.welcome} />,
	},
	{
		path   : Routes.cashback,
		element: <AsyncPrivate route={Routes.cashback} />,
	},
	{
		path   : Routes.usersWithCashback,
		element: <AsyncPrivate route={Routes.usersWithCashback} />,
	},
	{
		path   : Routes.freeBets,
		element: <AsyncPrivate route={Routes.freeBets} />,
	},
	{
		path   : Routes.massBonus,
		element: <AsyncPrivate route={Routes.massBonus} />,
	},
	{
		path   : Routes.tournaments,
		element: <AsyncPrivate route={Routes.tournaments} />,
	},
	{
		path   : Routes.casinoList,
		element: <AsyncPrivate route={Routes.casinoList} />,
	},
	{
		path   : Routes.casinoGameCategories,
		element: <AsyncPrivate route={Routes.casinoGameCategories} />,
	},
	{
		path   : Routes.casinoCustomProviders,
		element: <AsyncPrivate route={Routes.casinoCustomProviders} />,
	},
	{
		path   : Routes.casinoGames,
		element: <AsyncPrivate route={Routes.casinoGames} />,
	},
	{
		path   : Routes.casinoIntegrators,
		element: <AsyncPrivate route={Routes.casinoIntegrators} />,
	},
	{
		path   : Routes.sportCategories,
		element: <AsyncPrivate route={Routes.sportCategories} />,
	},
	{
		path   : Routes.sportEvents,
		element: <AsyncPrivate route={Routes.sportEvents} />,
	},
	{
		path   : Routes.sportEventsInner,
		element: <AsyncPrivate route={Routes.sportEventsInner} />,
	},
	{
		path   : Routes.sportResults,
		element: <AsyncPrivate route={Routes.sportResults} />,
	},
	{
		path   : Routes.sportResultsInner,
		element: <AsyncPrivate route={Routes.sportResultsInner} />,
	},
	{
		path   : Routes.sportLiveStatisticTypes,
		element: <AsyncPrivate route={Routes.sportLiveStatisticTypes} />,
	},
	{
		path   : Routes.sportTemplates,
		element: <AsyncPrivate route={Routes.sportTemplates} />,
	},
	{
		path   : Routes.sportGroups,
		element: <AsyncPrivate route={Routes.sportGroups} />,
	},
	{
		path   : Routes.sportMarketsStatisticTypes,
		element: <AsyncPrivate route={Routes.sportMarketsStatisticTypes} />,
	},


	{
		path   : Routes.sportDisplayTypes,
		element: <AsyncPrivate route={Routes.sportDisplayTypes} />,
	},
	{
		path   : Routes.sportParticipants,
		element: <AsyncPrivate route={Routes.sportParticipants} />,
	},
	{
		path   : Routes.sportAges,
		element: <AsyncPrivate route={Routes.sportAges} />,
	},
	{
		path   : Routes.sportBettingRules,
		element: <AsyncPrivate route={Routes.sportBettingRules} />,
	},
	{
		path   : Routes.sportScopes,
		element: <AsyncPrivate route={Routes.sportScopes} />,
	},


	{
		path   : Routes.sportLadders,
		element: <AsyncPrivate route={Routes.sportLadders} />,
	},
	{
		path   : Routes.settingsEditor,
		element: <AsyncPrivate route={Routes.settingsEditor} />,
	},
	{
		path   : Routes.settingsSmsGateway,
		element: <AsyncPrivate route={Routes.settingsSmsGateway} />,
	},
	{
		path   : Routes.settingsTemplates,
		element: <AsyncPrivate route={Routes.settingsTemplates} />,
	},
	{
		path   : Routes.settingsCurrencies,
		element: <AsyncPrivate route={Routes.settingsCurrencies} />,
	},


	{
		path   : Routes.settingsPayments,
		element: <AsyncPrivate route={Routes.settingsPayments} />,
	},
	{
		path   : Routes.settingsQuestions,
		element: <AsyncPrivate route={Routes.settingsQuestions} />,
	},
	{
		path   : Routes.settingsBlockedIPs,
		element: <AsyncPrivate route={Routes.settingsBlockedIPs} />,
	},
	{
		path   : Routes.settingsWhitelist,
		element: <AsyncPrivate route={Routes.settingsWhitelist} />,
	},
	{
		path   : Routes.cmsHeader,
		element: <AsyncPrivate route={Routes.cmsHeader} />,
	},
	{
		path   : Routes.cmsFooter,
		element: <AsyncPrivate route={Routes.cmsFooter} />,
	},
	{
		path   : Routes.cmsArticles,
		element: <AsyncPrivate route={Routes.cmsArticles} />,
	},
	{
		path   : Routes.cmsPriorities,
		element: <AsyncPrivate route={Routes.cmsPriorities} />,
	},
	{
		path   : Routes.cmsSpecialOffers,
		element: <AsyncPrivate route={Routes.cmsSpecialOffers} />,
	},
	{
		path   : Routes.cmsSpecialOffer,
		element: <AsyncPrivate route={Routes.cmsSpecialOffer} />,
	},


	{
		path   : Routes.cmsUpcoming,
		element: <AsyncPrivate route={Routes.cmsUpcoming} />,
	},
	{
		path   : Routes.cmsMatchesOfDay,
		element: <AsyncPrivate route={Routes.cmsMatchesOfDay} />,
	},
	{
		path   : Routes.cmsLandingPageGameGrid,
		element: <AsyncPrivate route={Routes.cmsLandingPageGameGrid} />,
	},
	{
		path   : Routes.cmsInfoPages,
		element: <AsyncPrivate route={Routes.cmsInfoPages} />,
	},
	{
		path   : Routes.cmsInfoPagesInner,
		element: <AsyncPrivate route={Routes.cmsInfoPagesInner} />,
	},
	{
		path   : Routes.cmsBanners,
		element: <AsyncPrivate route={Routes.cmsBanners} />,
	},
	{
		path   : Routes.cmsNews,
		element: <AsyncPrivate route={Routes.cmsNews} />,
	},
	{
		path   : Routes.newsArticlePage,
		element: <AsyncPrivate route={Routes.newsArticlePage} />,
	},
	{
		path   : Routes.cmsPromotions,
		element: <AsyncPrivate route={Routes.cmsPromotions} />,
	},
	{
		path   : Routes.cmsPromotionsInner,
		element: <AsyncPrivate route={Routes.cmsPromotionsInner} />,
	},
	{
		path   : Routes.cmsPromotionsAddNew,
		element: <AsyncPrivate route={Routes.cmsPromotionsAddNew} />,
	},
	{
		path   : Routes.cmsHelpCenter,
		element: <AsyncPrivate route={Routes.cmsHelpCenter} />,
	},

	// ----------------------------------------------------------------
	{
		path   : Routes.cmsBannersGroup,
		element: <AsyncPrivate route={Routes.cmsBannersGroup} />,
	},
	{
		path   : Routes.cmsSportPriorities,
		element: <AsyncPrivate route={Routes.cmsSportPriorities} />,
	},
	{
		path   : Routes.reportsByPlayer,
		element: <AsyncPrivate route={Routes.reportsByPlayer} />,
	},
	{
		path   : Routes.reportsByGame,
		element: <AsyncPrivate route={Routes.reportsByGame} />,
	},
	{
		path   : Routes.reportsByProvider,
		element: <AsyncPrivate route={Routes.reportsByProvider} />,
	},
	{
		path   : Routes.reportsByIntegrator,
		element: <AsyncPrivate route={Routes.reportsByIntegrator} />,
	},
	{
		path   : Routes.reportsTransactions,
		element: <AsyncPrivate route={Routes.reportsTransactions} />,
	},
	{
		path   : Routes.notificationPush,
		element: <AsyncPrivate route={Routes.notificationPush} />,
	},
	{
		path   : Routes.notificationMessages,
		element: <AsyncPrivate route={Routes.notificationMessages} />,
	},
	{
		path   : Routes.notificationSMS,
		element: <AsyncPrivate route={Routes.notificationSMS} />,
	},
	{
		path   : Routes.notificationEmails,
		element: <AsyncPrivate route={Routes.notificationEmails} />,
	},
	{
		path   : Routes.notificationTemplates,
		element: <AsyncPrivate route={Routes.notificationTemplates} />,
	},

	{
		path   : Routes.affiliates,
		element: <AsyncPrivate route={Routes.affiliates} />,
	},

];

const routerData: RouteObject[] = [
	...publicRoutes,
	...privateRoutes,
];


export const routes = createBrowserRouter(routerData);
