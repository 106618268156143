import isEmpty from 'lodash/isEmpty';
import EntityAdapter from '../../../helpers/entityAdapter';
import { CASINO_GAMES_WEIGHT, CHANNEL_TYPES } from '../../../helpers/commonConstants';
import ParamsBuilder from '../../../helpers/paramsBuilder';
import QueryToFilterValidaion from '../../../helpers/queryToFilterValidaion';
import findIndex from 'lodash/findIndex';
import cloneDeep from 'lodash/cloneDeep';

const { isValidID, isValidString, isValidArray } = QueryToFilterValidaion;
export const initFilterTypes = {
	// primary
	providerID      : isValidID,
	customProviderID: isValidID,
	channelID       : isValidID,
	gameCategoryID  : isValidArray,
	gameName        : isValidString,

};
export const defaultValuesGamesFilter = {
	channelID: CHANNEL_TYPES.web,
};
export const fields = {
	id                 : 'id',
	casinoGameID       : 'casino_game_id',
	gameID             : 'game_id',
	websiteID          : 'website_id',
	categoryID         : 'category',
	categories         : 'categories',
	name               : 'name',
	absoluteName       : 'absolute_name',
	imageLgURL         : 'image_lg_url',
	imageMdURL         : 'image_md_url',
	imageSmURL         : 'image_sm_url',
	thumbURL           : 'thumb_url',
	glImageLgURL       : 'gl_image_lg_url',
	glImageMdURL       : 'gl_image_md_url',
	glImageSmURL       : 'gl_image_sm_url',
	glThumbURL         : 'gl_thumb_url',
	posX               : 'position_x',
	posY               : 'position_y',
	weight             : 'weight',
	playCount          : 'play_count',
	statusID           : 'status_id',
	isFreeSpins        : 'free_spins', // boolean;
	freeSpinsCount     : 'free_spins_count', // number[];
	isBonusSpins       : 'bonus_spins', // boolean;
	tags               : 'game_tags', // array;
	currencyIDs        : 'currency_ids', // number[];
	isChanged          : 'isChanged',
	providerID         : 'provider_id',
	customProviderIDs  : 'custom_provider_ids',
	customProviderID   : 'custom_provider_id',
	channelID          : 'channel_id',
	restrictedCountries: 'restricted_countries',
	value              : 'name',
	alias              : 'alias',
	page               : 'page',
};

const gamesListAdapter   = createGamesListAdapter();

// Adapt ------------------------------------------------------------------------------------------

export function adaptGamesList(rawData = [], lastPage) {

	gamesListAdapter.clearExcludes();
	const adaptedData = gamesListAdapter.adaptList(rawData);

	adaptedData.forEach(item => {
		if (!item.weight) {
			// eslint-disable-next-line no-param-reassign
			item.weight = CASINO_GAMES_WEIGHT.small;
		}
	});

	const entities = adaptedData.reduce((acc, game) => {
		game.lastPage = Boolean(lastPage);
		acc[game.id] = game;

		return acc;
	}, {});

	return { entities, adaptedData };
}

// Prepare ----------------------------------------------------------------------------------------

export function prepareGamesList(rawData = []) {
	const gamesList = rawData.filter(item => item.isChanged);
	if (isEmpty(gamesList)) {
		return [];
	}
	gamesListAdapter.clearExcludes();
	gamesListAdapter.addExcludeField('isChanged');
	gamesListAdapter.addExcludeField('weight');
	gamesListAdapter.addExcludeField('posX');
	gamesListAdapter.addExcludeField('posY');
	gamesListAdapter.addExcludeField('isFreeSpins');
	gamesListAdapter.addExcludeField('freeSpinsCount');
	gamesListAdapter.addExcludeField('isBonusSpins');

	gamesListAdapter.addExcludeField('glImageLgURL');
	gamesListAdapter.addExcludeField('glImageMdURL');
	gamesListAdapter.addExcludeField('glImageSmURL');
	gamesListAdapter.addExcludeField('glThumbURL');

	return gamesListAdapter.prepareList(gamesList);
}

// Adapters ---------------------------------------------------------------------------------------

export function createGamesListAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'websiteID', fields.websiteID);
	adapter.addField(rules.id, 'categoryID', fields.categoryID);
	adapter.addField(rules.id, 'statusID', fields.statusID);

	adapter.addField(rules.string, 'gameID', fields.gameID);
	adapter.addField(rules.string, 'name', fields.name);
	adapter.addField(rules.string, 'alias', fields.alias);
	adapter.addField(rules.string, 'absoluteName', fields.absoluteName);
	adapter.addField(rules.string, 'imageLgURL', fields.imageLgURL);
	adapter.addField(rules.string, 'imageMdURL', fields.imageMdURL);
	adapter.addField(rules.string, 'imageSmURL', fields.imageSmURL);
	adapter.addField(rules.string, 'thumbURL', fields.thumbURL);
	adapter.addField(rules.string, 'glImageLgURL', fields.glImageLgURL);
	adapter.addField(rules.string, 'glImageMdURL', fields.glImageMdURL);
	adapter.addField(rules.string, 'glImageSmURL', fields.glImageSmURL);
	adapter.addField(rules.string, 'glThumbURL', fields.glThumbURL);

	adapter.addField(rules.positiveNumber, 'weight', fields.weight);
	adapter.addField(rules.positiveNumber, 'posX', fields.posX);
	adapter.addField(rules.positiveNumber, 'posY', fields.posY);
	adapter.addField(rules.number, 'playCount', fields.playCount);

	adapter.addField(rules.bool, 'isFreeSpins', fields.isFreeSpins);
	adapter.addField(rules.bool, 'isBonusSpins', fields.isBonusSpins);
	adapter.addField(rules.bool, 'isChanged', fields.isChanged);

	adapter.addField(rules.arrayNumber, 'freeSpinsCount', fields.freeSpinsCount);
	adapter.addField(rules.arrayNumber, 'currencyIDs', fields.currencyIDs);
	adapter.addField(rules.arrayNumber, 'customProviderIDs', fields.customProviderIDs);
	adapter.addField(rules.arrayNumber, 'restrictedCountries', fields.restrictedCountries);
	adapter.addField(rules.number, 'providerID', fields.providerID);
	adapter.addField(rules.string, 'value', fields.value);
	adapter.addField(rules.string, 'alias', fields.alias);
	adapter.addField(rules.number, 'page', fields.page);

	return adapter;
}


export function getListParams(filter, sorting, pagination) {

	const builder = new ParamsBuilder();
	const rules = builder.RULES;
	builder.addValue('sort_by', fields[sorting.sortBy]);
	builder.addValue('sort_order', sorting.sortOrder);
	builder.addValue('page', pagination.currentPage);
	builder.addValue('limit', pagination.itemsPerPage);
	builder.addField(rules.isID, 'providerID', fields.providerID);
	builder.addField(rules.isID, 'customProviderID', fields.customProviderID);
	builder.addField(rules.isID, 'channelID', fields.channelID);
	builder.addField(rules.isString, 'gameCategoryID', fields.categories);
	builder.addField(rules.isString, 'gameName', fields.name);

	filter.isFreeSpin && builder.addValue('free_spins', filter.isFreeSpin);
	filter.isBonusSpin && builder.addValue('bonus_spins', filter.isBonusSpin);

	// builder.addRangeField(rules.isDateTimeRange, filter.endDate, [
	//   fields.endDateFrom,
	//   fields.endDateTo,
	// ]);
	//
	// builder.addField(rules.isID, 'id', fields.id);
	// builder.addField(rules.isID, 'statusID', fields.statusID);
	//
	// builder.addField(rules.isBoolean, 'recurring', fields.recurring);
	//
	// builder.addField(rules.isString, 'casinoGameIDs', fields.casinoGameIDs);
	// builder.addField(rules.isString, 'casinoProviderIDs', fields.casinoProviderIDs);
	//
	// builder.addRangeField(rules.isDateTimeRange, filter.startDate, [
	//   fields.startDateFrom,
	//   fields.startDateTo,
	// ]);
	// builder.addRangeField(rules.isDateTimeRange, filter.endDate, [
	//   fields.endDateFrom,
	//   fields.endDateTo,
	// ]);
	// builder.addRangeField(rules.isDateTimeRange, filter.settlementDate, [
	//   fields.settlementDateFrom,
	//   fields.settlementDateTo,
	// ]);
	// builder.addRangeField(rules.isNumberRange, filter.ggrPercent, [
	//   fields.ggrPercentFrom,
	//   fields.ggrPercentTo,
	// ]);

	return builder.biuldParams(filter);
}

export function updateGamesList(gamesList = [], baseData, casinoGameID, isChanged, loading) {
	const clonedGamesList = cloneDeep(gamesList);

	const gameIndex = findIndex(gamesList, { id: casinoGameID });

	if (gameIndex> -1) {
		const item = baseData || clonedGamesList[gameIndex];

		clonedGamesList[gameIndex] = {
			...item,
			isChanged,
			loading,
		};

		return clonedGamesList;
	}

	return clonedGamesList;
}
