export const PARTICIPANT_LOG_MODELS = {
	all        : 'all',
	translation: 'translation',
	names      : 'names',
};

export const VERIFIED_STATUS = {
	all        : 1,
	verified   : 2,
	notVerified: 3,
};