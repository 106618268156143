
export enum EBonusTypes {
    REGISTRATION_FREESPIN = 'registration_freeSpin',
    NEXT_DEPOSIT_FREESPIN = 'next_deposit_freeSpin',
    DEPOSIT_FREESPIN = 'deposit_freeSpin',
    DEPOSIT_BONUS = 'deposit_bonus',
	NEXT_DEPOSIT_BONUS = 'next_deposit_bonus',
	PACK = 'pack',
	NEXT_PACK = 'next_pack',
	WELCOME = 'welcome',
}
export enum EFreeSpinStep {
	MAIN_SETTINGS = 1,
	CURRENCY = 2,
	BETS     = 3,
	ELIGIBILITY = 4,
	CONTENT  = 5,
}

export interface ICurrencyBonuses {
	currencyID              : number;
	maxPayoutAmount         : number;
	maxPayoutPercent        : number;
	denominationKey         : number | string;
	denominationValue       : number | null;
	betPerLine              : number | null;
	lines                   : number | null;
	denom?                  : string;
	denomination            : number;
	freespinMaxPayoutAmount?: number | null;
}
