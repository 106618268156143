import cloneDeep from 'lodash/cloneDeep';
import toInteger from 'lodash/toInteger';

export const historyActionTypeList = [
	{ id: 1, name: 'Create' },
	{ id: 2, name: 'Update' },
	{ id: 3, name: 'Delete' },
];
export const historySourceEnums = {
	FEED          : 1,
	ADMIN         : 2,
	CASINO        : 3,
	PAYMENT_SYSTEM: 4,
	SYSTEM        : 5,
	CASHBACK      : 6,
	SPORT         : 7,
	USER          : 8,
};

export const historySourceTypeList = [
	{ id: 1, name: 'Feed' },
	{ id: 2, name: 'Admin' },
	{ id: 3, name: 'Casino' },
	{ id: 4, name: 'Payment System' },
	{ id: 5, name: 'System' },
	{ id: 6, name: 'Cashback' },
	{ id: 7, name: 'Sport' },
	{ id: 8, name: 'User' },
];

export class HistoryEnums {

	actionTypeList = () => {
		return cloneDeep(historyActionTypeList);
	};

	sourceTypeList = () => {
		return cloneDeep(historySourceTypeList);
	};

	actionType = typeID => {
		let result = null;
		const item = historyActionTypeList.find( item => item.id === toInteger(typeID) );
		if (item) {
			result = item.name;
		}
		return result;
	};

	sourceType = typeID => {
		let result = null;
		const item = historySourceTypeList.find( item => item.id === toInteger(typeID) );
		if (item) {
			result = item.name;
		}
		return result;
	};
}
