import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import actions from './actions';

import { casinoAPI } from '../../../helpers/api/casino';
import notifications from '../../../helpers/notifications';

import { adaptTagsList } from './utils';
import { getStoredDataBaseLangID } from '../../../helpers/utility';
import { isArray } from 'lodash';
import { CasinoExtraSpinType } from '@ceo-betmakers/common-globals';

const messages = {
	errorListLoad: 'casinoTags.error.list.load',
};

function getStoreData(state) {
	const { App } = state;
	const websiteID =  App.get('websiteID');
	return {
		websiteID,
	};
}

function* listReload() {

	yield takeEvery(actions.CASINO_TAGS_LIST_RELOAD, function* (action) {
		const { websiteID } = yield select(getStoreData);
		const params = {
			website_id: websiteID,
			lang_id   : getStoredDataBaseLangID(),
		};
		
		if (isArray(action.data)) {
			params.currency_ids = action.data;
		} else {
			params.currency_id = action.data;
		}
		if (action.typeID) {
			if (action.typeID === CasinoExtraSpinType.FREE_SPIN) {
				params.free_spins = true;
			}
			if (action.typeID === CasinoExtraSpinType.BONUS_SPIN) {
				params.bonus_spins = true;
			}
			
		}
		let entities = {};
		try {
			const res = yield call(casinoAPI.casinoGamesbyTags, params);
			if (res && res.status === 200) {
				entities = adaptTagsList(res.data.data);
			}
		} catch (error) {
			notifications.showError(messages.errorListLoad);
		}

		yield put(actions.listRefresh(entities));
	});
}

export default function* casinoListSaga() {
	yield all([
		fork(listReload),
	]);
}
