import { all, call, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { responsibleGamblingAPI } from '../../../../helpers/api/responsibleGambling';
import actions from './actions';
import { adaptResponsibleGambling } from './utils';
import { logger } from '../../../../helpers/logger';
import { IResponsibleAdapted, IResponsibleEntities, IResponsibleResponse } from './types';
import { showError, showSuccess } from '../../../../helpers/notifications';
import { IApiResponse, ISagaActionType } from '../../../types';
import { responsibleReducer } from './slice';
import { RootState } from '../../../store';
import cloneDeep from 'lodash/cloneDeep';
import { RGStatus } from '@ceo-betmakers/common-globals';

const prefix = 'users.responsible';

const messages = {
	errorListReload: `${prefix}.errorListReload`,
	errorDelete    : `${prefix}.errorDelete`,
	successDelete  : `${prefix}.successDelete`,
};

const getStore = (store: RootState) => {
	const cloned = cloneDeep(store.Users.UserInfo.ResponsibleGambling);
	return {
		list    : cloned.list,
		entities: cloned.entities,
	};
};
function* responsibleGamblingReload() {
	yield takeEvery(actions.DATA_RELOAD, function* (action: ISagaActionType<number>) {
		const userID = action.data;
		yield put(responsibleReducer.uiRefresh({ loading: true }));

		try {
			const params = {
				user_id: userID,
			};
			const res: IApiResponse<IResponsibleResponse[]> = yield call(responsibleGamblingAPI.activeLimitations, params );
			if (res && res.status === 200) {
				const { list, entities } = adaptResponsibleGambling(res.data.data);

				yield put(responsibleReducer.listRefresh(list));
				yield put(responsibleReducer.entitiesRefresh(entities));
			}
		} catch (e) {
			showError(messages.errorListReload);
			logger.log(e, 'error');
		}
		yield put(responsibleReducer.uiRefresh({ loading: false }));

	});
}

function* removeLimitation() {
	yield takeLatest(actions.LIMITATION_REMOVE, function* (action: ISagaActionType<number> ) {
		const { data: limitationID } = action;
		const { entities }: { entities: IResponsibleEntities; list: IResponsibleAdapted[]} = yield select(getStore);
		const item = { ...entities[limitationID], loading: true };

		yield put(responsibleReducer.updateEntities({ data: item, id: limitationID }));
		try {
			const res = yield call(responsibleGamblingAPI.removeLimitations, limitationID, RGStatus.DELETED);
			if (res && res.status === 200) {
				const updatedData: IResponsibleAdapted = { ...entities[limitationID], loading: false, statusID: RGStatus.DELETED };
				yield put(responsibleReducer.updateEntities({ data: updatedData, id: limitationID }));
			}
			showSuccess(messages.successDelete);
		} catch (e) {
			showError(messages.errorDelete);
			logger.log(e, 'error');
		}

	});
}

export default function* userLimitsPaymentsSaga() {
	yield all([
		fork(responsibleGamblingReload),
		fork(removeLimitation),
	]);
}
