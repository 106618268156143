import { Map } from 'immutable';
import uniqBy from 'lodash/uniqBy';
import { createFilter, fill, filtersURL } from '../../../../helpers/utils';
import actions from './actions';
import { BONUS_STATUS, BONUS_TYPES } from '../../../../constants/bonuses';
import { initFilterTypes } from './utils';
import { LANGUAGES } from '../../../../helpers/commonConstants';
import Formatter from '../../../../helpers/formatter';
import { CasinoExtraSpinType, NextDepositWay } from '@ceo-betmakers/common-globals';

const initState = new Map({

	entities   : {},
	bonusList  : [],
	filter     : createFilter(filtersURL.bonuses, initFilterTypes),
	historyLogs: [],
	gameEntity : {},
	
	hasMore: true,

	bonusData: {
		statusID  : BONUS_STATUS.active,
		way       : NextDepositWay.FIRST_WAY,
		typeID    : CasinoExtraSpinType.FREE_SPIN,
		startDate : Formatter.fullDateTime(new Date().setHours(0, 0)),
		endDate   : Formatter.fullDateTime(new Date().setHours(23, 59)),
		titles    : [],
		currencies: [],
		currencyID: [],
		count     : {
			providers : {},
			categories: {},
		},
		countriesAllow: true,
		
		integratorIDs: [],
		providerIDs  : [],
		categoryIDs  : [],
		games        : [],
		gamesIDs     : [],
		gameIDs      : [],
	},

	gamesListInfinite: [],

	/**
     * {
     *    0.01 : [1, 3, 5],
     *    1    : [3, 5, 7.5, 10, 20, 30, 50],
     *    5    : [100, 200, 300, 500, 750, 1000, 2000, 3000, 5000, 7500, 10000],
     * }
     */
	denominations               : {},
	bets                        : [],
	lineAndDenominationsRequired: {
		requiredBetPerLine: false,
		requiredLines     : false,
	},

	UI: {
		bonusID                        : null,
		activeTab                      : '',
		freeSpinType                   : false,
		visible                        : false,
		loading                        : false,
		modalLoad                      : false,
		editMode                       : false,
		closeModal                     : false,
		isChanged                      : false,
		betID                          : null,
		denominationKey                : null,
		denominationValue              : null,
		showLineAndDenominationInputs  : false,
		showLineAndDenominationCheckbox: false,
		isCheckedMinLineAndBet         : false,
		betCurrencyTab                 : null,
		saveSuccess                    : false,
		gameName                       : null,
		bonusType                      : BONUS_TYPES.registrationFreeSpin,
		langID                         : LANGUAGES.en,
	},
});

export default function bonusesReducer(state = initState, action) {

	switch (action.type) {

		case actions.LIST_REFRESH: {
			const { entities } = action.data;
			return state.set('entities', entities);
		}

		case actions.BONUS_LIST_REFRESH: {
			const { bonusList } = action.data;
			return state.set('bonusList', bonusList);
		}

		case actions.FILTER_REFRESH: {
			const { filter } = action.data;
			const target     = state.get('filter');
			const result     = fill(filter, target, true);
			return state.set('filter', result);
		}

		case actions.BONUS_DATA_REFRESH: {
			const { bonusData } = action.data;
			const target        = state.get('bonusData');
			const result        = fill(bonusData, target, true);
			return state.set('bonusData', result);
		}

		case actions.BETS_REFRESH: {
			const { bets } = action.data;
			return state.set('bets', bets);
		}

		case actions.DENOMINATIONS_REFRESH: {
			const { denominations } = action.data;
			return state.set('denominations', denominations);
		}

		case actions.LINE_DENOMINATION_REQUIRED_REFRESH: {
			const { denominationsAndLine } = action.data;
			const target = state.get('lineAndDenominationsRequired');
			const result = fill(denominationsAndLine, target, true);
			return state.set('lineAndDenominationsRequired', result);
		}

		case actions.BONUS_UI_REFRESH: {
			const { UI } = action.data;
			const target = state.get('UI');
			const result = fill(UI, target, true);
			return state.set('UI', result);
		}

		case actions.BONUS_DATA_RESET: {
			const bonusData = initState.get('bonusData');
			const UI        = initState.get('UI');
			return state
				.set('bonusData', bonusData)
				.set('denominations', {})
				.set('bets', [])
				.set('UI', UI);
		}
		
		case actions.HISTORY_LOGS_REFRESH: {
			const { historyLogs } = action.data;
			return state.set('historyLogs', historyLogs);
		}
		
		case actions.GAME_ENTITY_REFRESH: {
			return state.set('gameEntity', action.data);
		}

		case actions.NEW_GAMES_BY_PAGE_REFRESH: {
			const target = state.get('gamesListInfinite');
			const result = target.concat(action.data);
			const uniqueValues = uniqBy(result, 'value');
			const uniqueValues1 = uniqBy(uniqueValues, 'id');
			return state.set('gamesListInfinite', uniqueValues1);
		}

		case actions.NEW_GAMES_BY_PAGE_REFRESH_FILTERED: {
			const uniqueValues = uniqBy(action.data, 'value');
			const uniqueValues1 = uniqBy(uniqueValues, 'id');
			return state.set('gamesListInfinite', uniqueValues1);
		}
		case actions.GAME_LIST_INFINITE_RESET: {
			return state.set('gamesListInfinite', []);
		}

		case actions.GAME_PAGE_REFRESH_EMPTY: {
			return state.set('gamesListInfinite', action.data);
		}

		case actions.CHECKED_GAMES_ID_RESET: {
			const changed = state.get('bonusData');
			changed.gameIDs = [];
			return state.set('bonusData', changed);
		}

		case actions.CASINO_GAMES_ID_RESET: {
			const changed = state.get('bonusData');
			changed.casinoGameID = null;
			return state.set('bonusData', changed);
		}

		case actions.NEW_GAMES_HAS_MORE_REFRESH: {
			return state.set('hasMore', action.data);
		}

		case actions.FILTER_RESET: {
			return state.set('filter', {});
		}


		default:
			return state;
	}
}
