import orderBy from 'lodash/orderBy';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import toInteger from 'lodash/toInteger';
import { CATEGORY_LOG_MODELS } from '../../../../helpers/commonConstants';
import Formatter from '../../../../helpers/formatter';
import { DiffMapper } from '../../../../helpers/diff';
import { HistoryEnums } from '../../results/enums';

const fields = {
	id                : 'id',
	objectBefore      : 'object_before',
	objectAfter       : 'object_after',
	userID            : 'source_id',
	sourceTypeID      : 'source_type',
	sourceIp          : 'source_ip',
	actionTypeID      : 'action_type',
	date              : 'date',
	categoryID        : 'category_id',
	channelID         : 'channel_id',
	websiteID         : 'website_id',
	langID            : 'lang_id',
	leagueID          : 'league_id',
	// array fields
	notes             : 'notes',
	restrictedBetTypes: 'restricted_bet_types',
	liveMarketsID     : 'live_markets_id',
	prematchMarketsID : 'prematch_markets_id',
	restrictedLeagues : 'restricted_leagues',
};

// Adapting ---------------------------------------------------------------------------------------

export function adaptData(rawData = [], logModel = CATEGORY_LOG_MODELS.category) {
	if (!isArray(rawData) || isEmpty(rawData)) {
		return [];
	}

	switch (logModel) {
		case CATEGORY_LOG_MODELS.category:
			return adaptCategory(rawData);

		case CATEGORY_LOG_MODELS.settings:
			return adaptSettings(rawData);

		case CATEGORY_LOG_MODELS.cms:
			return adaptCMS(rawData);

		case CATEGORY_LOG_MODELS.dynamicLimits:
			return adaptDynamicLimits(rawData);

		case CATEGORY_LOG_MODELS.markets:
			return adaptMarkets(rawData);

		case CATEGORY_LOG_MODELS.marketsMargin:
			return adaptMarketsMargin(rawData);

		case CATEGORY_LOG_MODELS.names:
			return adaptNames(rawData);

		case CATEGORY_LOG_MODELS.orders:
			return adaptOrders(rawData);

		case CATEGORY_LOG_MODELS.leagueRestrictions:
			return adaptRestrictions(rawData);

		case CATEGORY_LOG_MODELS.leagueTournaments:
			return adaptTournaments(rawData);

		case CATEGORY_LOG_MODELS.sportRegulations:
			return adaptRegulations(rawData);

		default:
			return [];
	}
}

export function adaptAllData(allData) {
	let result = [];

	result = result.concat(addSectionName(allData.category, 'Main'));
	result = result.concat(addSectionName(allData.settings, 'Settings'));
	result = result.concat(addSectionName(allData.cms, 'Descriptions'));
	result = result.concat(addSectionName(allData.dynamicLimits, 'Dynamic Limits'));
	result = result.concat(addSectionName(allData.markets, 'Markets'));
	result = result.concat(addSectionName(allData.names, 'Names'));
	result = result.concat(addSectionName(allData.orders, 'Orders'));
	result = result.concat(addSectionName(allData.leagueRestrictions, 'League Restrictions'));
	result = result.concat(addSectionName(allData.leagueTournaments, 'League Tournaments'));
	result = result.concat(addSectionName(allData.sportRegulations, 'ResponsibleGambling Regulations'));
	result = result.concat(addSectionName(allData.marketsMargin, 'Markets Margin'));

	result = orderBy(result, ['date'], ['desc']);
	result.forEach((item, index) => {
		item.id = index;
	});

	return result;
}

// Adapters ---------------------------------------------------------------------------------------
function adaptCategory(rawData) {
	const arrayFields = [fields.notes, fields.restrictedBetTypes];

	const diffOptions = {
		onlyChanged: true,
		excludeKeys: [fields.id].concat(arrayFields),
	};

	const dataList = adaptHistoryList(rawData, diffOptions, arrayFields);

	return dataList;
}

function adaptSettings(rawData) {
	const diffOptions = {
		onlyChanged: true,
		excludeKeys: [fields.id],
	};

	const dataList = adaptHistoryList(rawData, diffOptions);

	return dataList;
}

function adaptCMS(rawData) {
	const diffOptions = {
		onlyChanged: true,
		excludeKeys: [fields.id],
	};

	const dataList = adaptHistoryList(rawData, diffOptions);

	return dataList;
}

function adaptDynamicLimits(rawData) {
	const diffOptions = {
		onlyChanged: true,
		excludeKeys: [fields.id, fields.categoryID],
	};

	const dataList = adaptHistoryListOfArrays(rawData, diffOptions);

	return dataList;
}

function adaptMarkets(rawData) {
	const arrayFields = [fields.liveMarketsID, fields.prematchMarketsID];

	const diffOptions = {
		onlyChanged: true,
		excludeKeys: [
			fields.id,
			fields.categoryID,
			fields.channelID,
			fields.websiteID,
		].concat(arrayFields),
	};

	const dataList = adaptHistoryList(rawData, diffOptions, arrayFields);

	return dataList;
}

function adaptMarketsMargin(rawData) {
	const arrayFields = [fields.liveMarketsID, fields.prematchMarketsID];

	const diffOptions = {
		onlyChanged: true,
		excludeKeys: [
			fields.id,
			fields.categoryID,
			fields.channelID,
			fields.websiteID,
		].concat(arrayFields),
	};

	const dataList = adaptHistoryList(rawData, diffOptions, arrayFields);

	return dataList;
}

export function adaptNames(rawData) {
	const diffOptions = {
		onlyChanged: true,
		excludeKeys: [
			fields.id,
			fields.categoryID,
			fields.channelID,
			fields.websiteID,
			fields.langID,
		],
	};

	const dataList = adaptHistoryList(rawData, diffOptions);

	return dataList;
}

function adaptOrders(rawData) {
	const diffOptions = {
		onlyChanged: true,
		excludeKeys: [
			fields.id,
			fields.categoryID,
			fields.channelID,
			fields.websiteID,
			fields.langID,
		],
	};

	const dataList = adaptHistoryList(rawData, diffOptions);

	return dataList;
}

function adaptRestrictions(rawData) {
	const arrayFields = [fields.restrictedLeagues];

	const diffOptions = {
		onlyChanged: true,
		excludeKeys: [fields.id, fields.categoryID].concat(arrayFields),
	};

	const dataList = adaptHistoryList(rawData, diffOptions, arrayFields);

	return dataList;
}

function adaptTournaments(rawData) {
	const diffOptions = {
		onlyChanged: true,
		excludeKeys: [fields.id, fields.leagueID],
	};

	const dataList = adaptHistoryList(rawData, diffOptions);

	return dataList;
}

function adaptRegulations(rawData) {
	const diffOptions = {
		onlyChanged: true,
		excludeKeys: [fields.id, fields.leagueID],
	};

	const dataList = adaptHistoryList(rawData, diffOptions);

	return dataList;
}

// Common -----------------------------------------------------------------------------------------

function adaptHistoryList(rawData, diffOptions, arrayFields = []) {
	const historyEnums = new HistoryEnums();

	const dataList = [];

	const mapper = new DiffMapper();
	mapper.init(diffOptions);

	rawData.forEach((historyItem, historyItemIndex) => {
		let itemDiffList = [];

		const objectBefore = historyItem[fields.objectBefore] || {};
		const objectAfter  = historyItem[fields.objectAfter]  || {};

		// main data
		const diffMap  = mapper.map(objectBefore, objectAfter);
		const diffList = mapper.toList(diffMap);

		itemDiffList = itemDiffList.concat(diffList);

		// arrays
		arrayFields.forEach(fieldName => {
			const diffList = mapper.mapList(
				objectBefore,
				objectAfter,
				fieldName,
				true
			);
			itemDiffList = itemDiffList.concat(diffList);
		});

		if (isEmpty(itemDiffList)) {
			return;
		}

		// adding id for detail records
		itemDiffList.forEach((item, index) => {
			item.id = index;
		});

		// prepare result item
		const userID       = toInteger(historyItem[fields.userID]);
		const sourceIp     = historyItem[fields.sourceIp];
		const sourceTypeID = toInteger(historyItem[fields.sourceTypeID]);
		const actionTypeID = toInteger(historyItem[fields.actionTypeID]);

		const dataItem = {
			userID,
			sourceIp,
			id         : historyItemIndex,
			date       : Formatter.fullDateTime(historyItem[fields.date]),
			sourceType : historyEnums.sourceType(sourceTypeID),
			actionType : historyEnums.actionType(actionTypeID),
			sourceTypeID,
			detailsList: itemDiffList,
		};

		dataList.push(dataItem);
	});

	return dataList;
}

function adaptHistoryListOfArrays(rawData, diffOptions) {
	const historyEnums = new HistoryEnums();
	const dataList = [];

	const mapper = new DiffMapper();
	mapper.init(diffOptions);

	rawData.forEach((historyItem, historyItemIndex) => {
		let itemDiffList = [];

		const objectBefore = historyItem[fields.objectBefore] || {};
		const objectAfter = historyItem[fields.objectAfter] || {};

		const tempObjectBefore = {
			dynamicLimits: objectBefore,
		};
		const tempObjectAfter = {
			dynamicLimits: objectAfter,
		};

		const diffList = mapper.mapObjectsList(
			tempObjectBefore,
			tempObjectAfter,
			'dynamicLimits'
		);
		itemDiffList = itemDiffList.concat(diffList);

		if (isEmpty(itemDiffList)) {
			return;
		}

		// adding id for detail records
		itemDiffList.forEach((item, index) => {
			item.id = index;
		});

		// prepare result item
		const userID = toInteger(historyItem[fields.userID]);
		const sourceTypeID = toInteger(historyItem[fields.sourceTypeID]);
		const actionTypeID = toInteger(historyItem[fields.actionTypeID]);

		const dataItem = {
			id         : historyItemIndex,
			userID,
			date       : Formatter.fullDateTime(historyItem[fields.date]),
			sourceType : historyEnums.sourceType(sourceTypeID),
			actionType : historyEnums.actionType(actionTypeID),
			sourceTypeID,
			detailsList: itemDiffList,
		};

		dataList.push(dataItem);
	});

	return dataList;
}

function addSectionName(rawList, sectionName) {
	if (!isArray(rawList) || isEmpty(rawList)) {
		return [];
	}

	return rawList.map(item => {
		return {
			...item,
			section: sectionName,
		};
	});
}
